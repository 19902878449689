import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_element_loading = _resolveComponent("vue-element-loading")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_vue_element_loading, {
      active: _ctx.getLoadingStatus,
      spinner: "bar-fade-scale",
      color: "#6e9814",
      text: "Please wait...",
      "is-full-screen": ""
    }, null, 8, ["active"]),
    _createVNode(_component_router_view)
  ], 64))
}