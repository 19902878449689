<template>
  <div class="body">
    <HeaderComponent pageTitle="資料請求（入力内容のご確認）" />
    <CatalogIntroductionConfirmOrganism />
    <CatalogInputConfirmOrganism />
    <div class="l-baseCat"><CatalogInquiryOrganism /></div>
    <FooterComponent />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HeaderComponent from "@/components/organisms/global/HeaderComponent.vue";
import CatalogIntroductionConfirmOrganism from "@/components/organisms/catalog/CatalogIntroductionConfirmOrganism.vue";
import CatalogInputConfirmOrganism from "@/components/organisms/catalog/CatalogInputConfirmOrganism.vue";
import CatalogInquiryOrganism from "@/components/organisms/catalog/CatalogInquiryOrganism.vue";
import FooterComponent from "@/components/organisms/global/FooterComponent.vue";

export default defineComponent({
  name: "CatalogConfirmPage",
  components: {
    HeaderComponent,
    CatalogIntroductionConfirmOrganism,
    CatalogInputConfirmOrganism,
    CatalogInquiryOrganism,
    FooterComponent,
  },
});
</script>
<style scoped>
.body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Hiragino Sans", Meiryo, sans-serif;
  font-size: 1.6rem;
  color: #333;
  letter-spacing: 0.08em;
  line-height: 1.8;
  letter-spacing: 0.08em;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background: #fafafa;
  min-height: 100vh;
}
@media screen and (min-width: 501px) {
  .l-baseCat {
    width: 1040px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 500px) {
  .l-baseCat {
    padding: 0 10px;
  }
}
</style>
