const state = {
  isLoading: false,
};
const getters = {
  getLoadingStatus: (state: { isLoading: boolean }): boolean => {
    return state.isLoading;
  },
};
const mutations = {
  setLoadingStatus: (state: { isLoading: boolean }, loadingStatus: boolean) => {
    state.isLoading = loadingStatus;
  },
};
const actions = {};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
