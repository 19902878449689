const state = {
  reserveDateTimeMessage: "" as string,
  insuredPersonGender: "" as string,
  isReserveCompleted: false as boolean,
};
const getters = {
  getReserveDateTimeMessage: (state: {
    reserveDateTimeMessage: string;
  }): string => {
    return state.reserveDateTimeMessage;
  },
  getInsuredPersonGender: (state: { insuredPersonGender: string }): string => {
    return state.insuredPersonGender;
  },
  getIsReserveCompleted: (state: { isReserveCompleted: boolean }): boolean => {
    return state.isReserveCompleted;
  },
};
const mutations = {
  setReserveDateTimeMessage: (
    state: { reserveDateTimeMessage: string },
    reserveDateTimeMessage: string
  ) => {
    state.reserveDateTimeMessage = reserveDateTimeMessage;
  },
  setInsuredPersonGender: (
    state: { insuredPersonGender: string },
    insuredPersonGender: string
  ) => {
    state.insuredPersonGender = insuredPersonGender;
  },
  setIsReserveCompleted: (
    state: { isReserveCompleted: boolean },
    isReserveCompleted: boolean
  ) => {
    state.isReserveCompleted = isReserveCompleted;
  },
};
const actions = {};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
