/**
 * 内部コードを列挙します
 */
export enum InternalCodeEnum {
  // 正常終了
  Successful = "N1",
  // メールアドレス形式エラー
  MailAddressFormatError = "E1",
  // メールアドレス未入力エラー
  MailAddressEmptyError = "E2",
  // メールアドレス文字数制限エラー
  MailAddressCountOver = "E3",
  // データベース接続エラー
  DynamoDbConnectionFaild = "E4",
  // サーバエラー
  InternalServerError = "E5",

  // FamilyNameEmptyError = "E5",
  // FamilyNameFormatError = "E5",
  // FamilyNameCountOverError = "E5",
  // FirstNameEmptyError = "E5",
}
