export class RequestValueGenerater {
  /**
   * チャネル識別子を判別し、返却します。
   *
   * @param isMyPageApply マイページ資料請求フラグ
   * @param isSmartPhoneRequest スマートフォンリクエストフラグ
   * @returns チャネル識別子
   */
  public static convertChannelSikibetusi(
    isMyPageApply: boolean,
    isSmartPhoneRequest: boolean
  ): string {
    // チャネル識別子を判別する
    if (isMyPageApply && isSmartPhoneRequest) {
      // マイページSPHのチャネル識別子を返却する
      return "110";
    } else if (isMyPageApply && !isSmartPhoneRequest) {
      // マイページSPHのチャネル識別子を返却する
      return "101";
    } else if (isSmartPhoneRequest) {
      // SPHのチャネル識別子を返却する
      return "010";
    }
    // どの条件にも当てはまらない場合、PCのチャネル識別子を返却する。
    return "001";
  }
}
