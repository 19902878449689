<template>
  <div class="Intro-area">
    <div class="Intro-area__inner">
      <p class="Intro-area__lead">
        <strong>資料請求ありがとうございました。</strong>
      </p>
      <p class="Intro-area__lead-sp">
        <strong>資料請求<br />ありがとうございました。</strong>
      </p>
    </div>
  </div>
  <div class="Mes-area">
    <p class="Mes-area__txt">
      資料をご請求いただいてから1週間たってもお手元に届かない場合は、コンタクトセンターへご連絡ください。
    </p>
  </div>
  <div class="Conversion">
    <div class="Conversion-inside">
      <div class="Conversion__item">
        <div class="Cv-unit Cv-unit--type01">
          <p>まずは見積りトライ！</p>
          <a
            href="https://www.lifenet-seimei.co.jp/plan/"
            class="Cv-btn Cv-btn--type01"
          >
            <img
              src="../../../assets/btn_conversion01.png"
              alt="無料で10秒見積もり"
              class="u-fiximg"
              width="474"
              height="88"
            />
            <img
              src="../../../assets/btn_conversion01_sp.png"
              alt="無料で10秒見積もり"
              class="u-fiximg-sp"
              width="474"
              height="88"
            />
          </a>
        </div>
      </div>
      <div class="Conversion__item">
        <div class="Cv-unit Cv-unit--type02">
          <p>保険選びに迷った方におすすめ！</p>
          <a
            href="https://www.lifenet-seimei.co.jp/plan/advice/"
            class="Cv-btn Cv-btn--type02"
          >
            <img
              src="../../../assets/btn_conversion02.png"
              alt="ぴったり保険診断"
              class="u-fiximg"
              width="474"
              height="88"
            />
            <img
              src="../../../assets/btn_conversion02_sp.png"
              alt="ぴったり保険診断"
              class="u-fiximg-sp"
              width="474"
              height="88"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
// templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用
import { defineComponent } from "vue";
export default defineComponent({
  name: "CatalogIntroduction",
});
</script>
<style scoped>
.Intro-area {
  background: #f8f5f2;
}
*,
::after,
::before {
  box-sizing: border-box;
}
p {
  margin: 0;
}

.Cv-unit--type01 {
  color: #fd9001;
}
.Cv-btn--type01 {
  background: #fd9001;
}
img {
  vertical-align: middle;
  border-style: none;
}
.Cv-unit--type02 {
  color: #70ad46;
}
.Cv-btn--type02 {
  background: #70ad46;
}

@media screen and (min-width: 501px) {
  strong,
  em {
    font-style: normal;
    font-weight: normal;
  }
  .Intro-area__inner {
    width: 1040px;
    margin: 0 auto;
    padding: 30px 80px;
  }
  .Intro-area__lead {
    color: #3b5e10;
    font-size: 3.2rem;
    line-height: 1;
    text-align: center;
  }
  .Intro-area__lead-sp {
    display: none;
  }

  .Mes-area {
    padding: 40px 0;
  }
  .Mes-area__txt {
    position: relative;
    width: 840px;
    margin: 0 auto;
    padding: 14px 10px 12px 128px;
    border: 1px solid #80b735;
    border-radius: 8px;
    background: #fff;
    color: #588310;
    font-size: 2rem;
  }
  .Mes-area__txt::before {
    display: block;
    position: absolute;
    top: -16px;
    left: 35px;
    width: 60px;
    height: 127px;
    background: url(../../../assets/img_decoration02.png) no-repeat 0 0;
    content: "";
  }
  .Conversion {
    margin-bottom: 60px;
    padding: 30px 0;
    border: solid #dedfde;
    border-width: 1px 0;
    background: #f7f3f7;
    text-align: center;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.2;
  }
  .Conversion-inside {
    display: flex;
    width: 1040px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
  }
  .Conversion__item {
    width: 507px;
  }
  .u-fiximg-sp {
    display: none;
  }

  .Cv-btn {
    display: block;
    margin: 16px auto 0;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -4px rgb(0 16 14 / 31%);
    border-radius: 8px;
    color: #fff !important;
    font-weight: bold;
    text-decoration: none !important;
    text-align: center;
    transition: all 0.3s ease;
  }
}
@media screen and (max-width: 500px) {
  .Intro-area__inner {
    width: 100%;
  }
  .Intro-area__lead {
    display: none;
  }
  .Intro-area__lead-sp {
    font-size: 2.2rem;
    line-height: 1.4;
    color: #6c8939;
    padding: 10px 30px;
    font-weight: bold;
    background: #f8f5f2;
  }

  .Mes-area {
    padding: 10px;
    background: #fafafa;
  }
  .Mes-area__txt {
    position: relative;
    margin: 0 auto;
    padding: 8px 10px 8px 70px;
    border: 1px solid #80b735;
    border-radius: 8px;
    background: #fff;
    color: #588310;
    font-size: 1.1rem;
  }
  .Mes-area__txt::before {
    background: url(../../../assets/img_decoration02.png) no-repeat 0 0;
    display: block;
    position: absolute;
    bottom: 0;
    left: 15px;
    width: 60px;
    height: 77px;
    background-size: contain;
    content: "";
  }
  .Conversion {
    margin-bottom: 30px;
    padding: 10px 15px;
    border: solid #dedfde;
    border-width: 1px 0;
    background: #fefffd;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.2;
  }
  .Conversion-inside {
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
  }
  .Conversion__item {
    margin: 20px 0 30px;
  }
  .u-fiximg {
    display: none;
  }
  .u-fiximg-sp {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .Cv-btn {
    display: block;
    position: relative;
    margin: 8px auto 0;
    padding: 10px 26px 10px 10px;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -4px rgb(0 16 14 / 31%);
    border-radius: 8px;
    color: #fff !important;
    font-weight: bold;
    text-decoration: none !important;
    text-align: center;
  }
  .Cv-btn::before {
    width: 24px;
    height: 24px;
    margin: -12px 0 0 -38px;
    border-radius: 50%;
    background: #fff;
  }
  .Cv-btn::before,
  .Cv-btn::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    content: "";
  }
  .Cv-btn::after {
    width: 0;
    height: 0;
    margin: -6px 0 0 -29px;
    border-style: solid;
    border-width: 6px 0 6px 9px;
    border-color: transparent transparent transparent;
  }
  .Cv-btn--type01::after {
    border-left-color: #fd9001;
  }
  .Cv-btn--type02::after {
    border-left-color: #70ad46;
  }
}
</style>
