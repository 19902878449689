import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3aae4384"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-main" }
const _hoisted_2 = { class: "form-title" }
const _hoisted_3 = { class: "form-input-area" }
const _hoisted_4 = { class: "form-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormComponentSimple = _resolveComponent("FormComponentSimple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.formTitle), 1),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, [
        _createVNode(_component_FormComponentSimple, {
          type: _ctx.type,
          formTitle: _ctx.formTitle,
          modelValue: _ctx.localModelValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
          maxLength: _ctx.maxLength,
          required: true,
          email: _ctx.email
        }, null, 8, ["type", "formTitle", "modelValue", "maxLength", "email"]),
        _createElementVNode("p", null, _toDisplayString(_ctx.sample), 1)
      ])
    ])
  ]))
}