import { InputParam } from "@/interface/catalog/CatalogInterFaces";

const state = {
  inputPageParam: {
    familyName: "",
    firstName: "",
    familyNameKana: "",
    firstNameKana: "",
    insuredPersonGender: "",
    birthArray: {
      birthYear: 0,
      birthMonth: 0,
      birthDay: 0,
    },
    zipcode: "",
    cityName: "",
    blockNumber: "",
    buildingName: "",
    primaryPhone: "",
    email: "",
    maritalStatus: "",
    childNumber: "",
    workingStyle: "",
    channelSikibetusi: "",
    advertiseMedia: "",
    advertiseMaterial: "",
    concern: [],
    mullTriggeredCue: [],
    applicationPlanCue: [],
    wishEmail: [],
    karteUserId: "",
    stateCityName: "",
  } as InputParam,
  reEnterStatus: false as boolean,
  catalogId: "" as string,
  isCatalogCompleted: false as boolean,
  isCatalogConfirmed: false as boolean,
  isMyPageApply: false as boolean,
  contractAge: "" as string,
};
const getters = {
  getInputPageParam: (state: { inputPageParam: InputParam }): InputParam => {
    return state.inputPageParam;
  },
  getReEnterStatus: (state: { reEnterStatus: boolean }): boolean => {
    return state.reEnterStatus;
  },
  getCatalogId: (state: { catalogId: string }): string => {
    return state.catalogId;
  },
  getIsCatalogCompleted: (state: { isCatalogCompleted: boolean }): boolean => {
    return state.isCatalogCompleted;
  },
  getIsCatalogConfirmed: (state: { isCatalogConfirmed: boolean }): boolean => {
    return state.isCatalogConfirmed;
  },
  getIsMyPageApply: (state: { isMyPageApply: boolean }): boolean => {
    return state.isMyPageApply;
  },
  getReturnFromConfirm: (state: { returnFromConfirm: boolean }): boolean => {
    return state.returnFromConfirm;
  },
  getContractAge: (state: { contractAge: string }): string => {
    return state.contractAge;
  },
};
const mutations = {
  setInputPageParam: (
    state: { inputPageParam: InputParam },
    inputPageParam: InputParam
  ) => {
    state.inputPageParam = inputPageParam;
  },
  setReEnterStatus: (
    state: { reEnterStatus: boolean },
    reEnterStatus: boolean
  ) => {
    state.reEnterStatus = reEnterStatus;
  },
  setCatalogId: (state: { catalogId: string }, catalogId: string) => {
    state.catalogId = catalogId;
  },
  setIsCatalogCompleted: (
    state: { isCatalogCompleted: boolean },
    isCatalogCompleted: boolean
  ) => {
    state.isCatalogCompleted = isCatalogCompleted;
  },
  setIsCatalogConfirmed: (
    state: { isCatalogConfirmed: boolean },
    isCatalogConfirmed: boolean
  ) => {
    state.isCatalogConfirmed = isCatalogConfirmed;
  },
  setIsMyPageApply: (
    state: { isMyPageApply: boolean },
    isMyPageApply: boolean
  ) => {
    state.isMyPageApply = isMyPageApply;
  },
  setReturnFromConfirm: (
    state: { returnFromConfirm: boolean },
    returnFromConfirm: boolean
  ) => {
    state.returnFromConfirm = returnFromConfirm;
  },
  setContractAge: (state: { contractAge: string }, contractAge: string) => {
    state.contractAge = contractAge;
  },
};
const actions = {};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
