import ChildPresenceMolecules from "@/components/molecules/catalog/ChildPresenceMolecules.vue"; // @ is an alias to /src
import ConcernCheckboxMolecules from "@/components/molecules/catalog/ConcernCheckboxMolecules.vue"; // @ is an alias to /srcPolicy
import DateOfBirthMolecules from "@/components/molecules/catalog/DateOfBirthMolecules.vue"; // @ is an alias to /src
import ErrorMessageAreaMolecules from "@/components/molecules/catalog/ErrorMessageAreaMolecules.vue"; // @ is an alias to /src
import GenderMolecules from "@/components/molecules/catalog/GenderMolecules.vue"; // @ is an alias to /src
import LongInputMolecules from "@/components/molecules/catalog/LongInputMolecules.vue"; // @ is an alias to /src
import MaritalStatusMolecules from "@/components/molecules/catalog/MaritalStatusMolecules.vue"; // @ is an alias to /src
import MullTriggeredCueCheckboxMolecules from "@/components/molecules/catalog/MullTriggeredCueCheckboxMolecules.vue"; // @ is an alias to /srcPolicy
import NameInputFormMolecules from "@/components/molecules/catalog/NameInputFormMolecules.vue"; // @ is an alias to /src
import PolicyProtectionMolecules from "@/components/molecules/catalog/PolicyProtectionMolecules.vue"; // @ is an alias to /srcPolicy
import WishMailCheckboxMolecules from "@/components/molecules/catalog/WishMailCheckboxMolecules.vue"; // @ is an alias to /srcPolicy
import WorkingStyleMolecules from "@/components/molecules/catalog/WorkingStyleMolecules.vue"; // @ is an alias to /src
import { ApiUrlEnv } from "@/env/ApiUrlEnv";
import {
  AddressList,
  AddressResponseList,
  DataType,
} from "@/interface/catalog/CatalogInputInterFaces";
import { InputParam } from "@/interface/catalog/CatalogInterFaces";
import { Messages } from "@/utils/message/CatalogMessages";
import { MyPageCatalogMessages } from "@/utils/message/MyPageCatalogMessage";
import { CatalogInternalCodeEnum } from "@/utils/message/num/CatalogInternalCodeEnum";
import { Validater } from "@/utils/validater/CatalogValidaters";
import axios from "axios";
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";

const CatalogMessages = new Messages();

const convertMap = {
  ｶﾞ: "ガ",
  ｷﾞ: "ギ",
  ｸﾞ: "グ",
  ｹﾞ: "ゲ",
  ｺﾞ: "ゴ",
  ｻﾞ: "ザ",
  ｼﾞ: "ジ",
  ｽﾞ: "ズ",
  ｾﾞ: "ゼ",
  ｿﾞ: "ゾ",
  ﾀﾞ: "ダ",
  ﾁﾞ: "ヂ",
  ﾂﾞ: "ヅ",
  ﾃﾞ: "デ",
  ﾄﾞ: "ド",
  ﾊﾞ: "バ",
  ﾋﾞ: "ビ",
  ﾌﾞ: "ブ",
  ﾍﾞ: "ベ",
  ﾎﾞ: "ボ",
  ﾊﾟ: "パ",
  ﾋﾟ: "ピ",
  ﾌﾟ: "プ",
  ﾍﾟ: "ペ",
  ﾎﾟ: "ポ",
  ｳﾞ: "ヴ",
  ﾜﾞ: "ヷ",
  ｦﾞ: "ヺ",
  ｱ: "ア",
  ｲ: "イ",
  ｳ: "ウ",
  ｴ: "エ",
  ｵ: "オ",
  ｶ: "カ",
  ｷ: "キ",
  ｸ: "ク",
  ｹ: "ケ",
  ｺ: "コ",
  ｻ: "サ",
  ｼ: "シ",
  ｽ: "ス",
  ｾ: "セ",
  ｿ: "ソ",
  ﾀ: "タ",
  ﾁ: "チ",
  ﾂ: "ツ",
  ﾃ: "テ",
  ﾄ: "ト",
  ﾅ: "ナ",
  ﾆ: "ニ",
  ﾇ: "ヌ",
  ﾈ: "ネ",
  ﾉ: "ノ",
  ﾊ: "ハ",
  ﾋ: "ヒ",
  ﾌ: "フ",
  ﾍ: "ヘ",
  ﾎ: "ホ",
  ﾏ: "マ",
  ﾐ: "ミ",
  ﾑ: "ム",
  ﾒ: "メ",
  ﾓ: "モ",
  ﾔ: "ヤ",
  ﾕ: "ユ",
  ﾖ: "ヨ",
  ﾗ: "ラ",
  ﾘ: "リ",
  ﾙ: "ル",
  ﾚ: "レ",
  ﾛ: "ロ",
  ﾜ: "ワ",
  ｦ: "ヲ",
  ﾝ: "ン",
  ｧ: "ァ",
  ｨ: "ィ",
  ｩ: "ゥ",
  ｪ: "ェ",
  ｫ: "ォ",
  ｯ: "ッ",
  ｬ: "ャ",
  ｭ: "ュ",
  ｮ: "ョ",
  ｰ: "ー",
  "･": "・",
};

export default defineComponent({
  name: "InputMolecules",
  components: {
    NameInputFormMolecules,
    DateOfBirthMolecules,
    LongInputMolecules,
    GenderMolecules,
    MaritalStatusMolecules,
    ChildPresenceMolecules,
    WorkingStyleMolecules,
    ConcernCheckboxMolecules,
    PolicyProtectionMolecules,
    MullTriggeredCueCheckboxMolecules,
    WishMailCheckboxMolecules,
    ErrorMessageAreaMolecules,
  },

  data(): DataType {
    return {
      inputParam: {
        familyName: "",
        firstName: "",
        familyNameKana: "",
        firstNameKana: "",
        insuredPersonGender: "",
        birthArray: {
          birthYear: 0,
          birthMonth: 0,
          birthDay: 0,
        },
        zipcode: "",
        cityName: "",
        blockNumber: "",
        buildingName: "",
        primaryPhone: "",
        email: "",
        maritalStatus: "",
        childNumber: "",
        workingStyle: "",
        channelSikibetusi: "",
        advertiseMedia: "",
        advertiseMaterial: "",
        concern: [],
        mullTriggeredCue: [],
        applicationPlanCue: [],
        wishEmail: ["1"],
        karteUserId: "",
        stateCityName: "",
      },
      monthLastMessage: "",
      validationResultFlg: true,
      validationResult: [
        {
          // バリデーション項目
          inputName: "",
          // エラーコード
          internalCode: "",
          // バリデーション結果
          isSuccess: true,
          // バリデーションメッセージ
          message: "",
        },
      ],
      selectList: [],
      addressList: [],
      validationMessages: [],
      reEnterStatus: false,
      errorTagList: [],
      searchZipCode: "",
      karteUserId: "",
    };
  },
  methods: {
    // VuexからMutationsを呼び出す
    ...mapMutations([
      "setInputPageParam",
      "setReEnterStatus",
      "setLoadingStatus",
      "setIsCatalogConfirmed",
      "setIsMyPageApply",
      "setReturnFromConfirm",
    ]),
    ...mapGetters([
      "getInputPageParam",
      "getReEnterStatus",
      "getIsMyPageApply",
      "getReturnFromConfirm",
    ]),

    /**
     * 確認画面へ遷移します。
     * ・バリデーションを行います
     */
    goToConfirm() {
      // inputパラメータを生成する
      const param: InputParam = this.generateInputParam();

      // バリデーションを行う
      if (!this.validate(param)) {
        return;
      }

      this.validationResultFlg = true;
      this.setInputPageParam(param);
      this.setIsCatalogConfirmed(true);
      // VuexからMutationsを呼び出す
      this.$router.push("/catalog/confirm");
    },

    /**
     * バリデーションチェックを行います
     * ・バリデーション結果によって論理値を返却します
     * ・バリデーションに引っ掛かった画面項目を赤塗にします
     * ・エラーメッセージを表示します
     */
    validate(param: InputParam): boolean {
      // バリデーション実施前に画面の状態を初期化
      this.errorTagList.forEach((e) => {
        document.getElementById(e)!.style.backgroundColor = "#fff";
      });
      this.validationMessages = [];
      this.errorTagList = [];

      // バリデーションを実施
      this.validationResult = Validater.validateCatalog(
        param,
        this.searchZipCode
      );
      // バリデーション結果が0件だった場合
      if (this.validationResult.length === 0) {
        return true;
      }
      // 住所を再検索
      if (
        this.searchZipCode != this.inputParam.zipcode &&
        this.searchZipCode.length === 7 &&
        this.searchZipCode.match(/^[0-9]+$/)
      ) {
        this.inputParam.cityName = "";
        this.selectList = [];
        this.inputParam.zipcode = this.searchZipCode;
        this.reSearchAddress();
      }
      if (
        this.searchZipCode != this.inputParam.zipcode &&
        (this.searchZipCode.length != 7 ||
          !this.searchZipCode.match(/^[0-9]+$/))
      ) {
        this.inputParam.cityName = "";
        this.selectList = [];
      }

      // バリデーションが失敗した場合
      this.validationResult.forEach((result) => {
        document.getElementById(result.inputName)!.style.backgroundColor =
          "#ffd9d9";
        this.errorTagList.push(result.inputName);
        this.validationMessages.push(
          CatalogMessages.generateMessage(result.internalCode)
        );
      });

      this.moveToTop();
      this.validationResultFlg = false;
      return false;
    },

    /**
     * バリデーション時の郵便番号再検索用メソッド
     */
    reSearchAddress() {
      // スマホ判定
      const smartphone = this.deviceDecision();

      axios
        .get(
          this.addressMasterApiUrl + this.searchZipCode + "&sp=" + smartphone
        )
        .then((res) => {
          if (res.data.error != "0") {
            return;
          }
          this.addressList = [];
          this.selectList = [];
          this.inputParam.stateCityName = "";
          if (res.data.count === 0) {
            document.getElementById("addarea3")!.style.backgroundColor =
              "#ffd9d9";
            this.errorTagList.push("addarea3");
            this.validationMessages.push(
              CatalogMessages.generateMessage(
                CatalogInternalCodeEnum.CityNameSearchError
              )
            );
            return;
          }
          // 住所選択プルダウン生成
          this.createAddressSelectList(res);

          if (smartphone === "TRUE") {
            // 都道府県市区町村をテキスト表示
            this.inputParam.stateCityName = res.data.stateAndCity;
            this.stateCityNameDisplay();
          }
        })
        .catch((err) => {
          // ローディング画面を非表示
          this.setLoadingStatus(false);
        });
    },

    /**
     * inputパラメータを生成します。
     */
    generateInputParam(): InputParam {
      // KARTEユーザーID取得
      const karteUserId = <HTMLInputElement>(
        document.getElementById("karte-user-id")
      );
      this.karteUserId = karteUserId.value;

      // 都道府県市区町村が選択されている場合にスマホ向けの値をセットする
      let cityName = "";
      if (this.inputParam.stateCityName && this.inputParam.cityName) {
        cityName = this.inputParam.stateCityName + this.inputParam.cityName;
      } else {
        cityName = this.inputParam.cityName;
      }
      return {
        familyName: this.inputParam.familyName,
        firstName: this.inputParam.firstName,
        familyNameKana: this.inputParam.familyNameKana,
        firstNameKana: this.inputParam.firstNameKana,
        insuredPersonGender: this.inputParam.insuredPersonGender,
        birthArray: this.inputParam.birthArray,
        zipcode: this.inputParam.zipcode,
        cityName: cityName,
        blockNumber: this.inputParam.blockNumber,
        buildingName: this.inputParam.buildingName,
        primaryPhone: this.inputParam.primaryPhone,
        email: this.inputParam.email,
        maritalStatus: this.inputParam.maritalStatus,
        childNumber: this.inputParam.childNumber,
        workingStyle: this.inputParam.workingStyle,
        channelSikibetusi: "",
        advertiseMedia: this.inputParam.advertiseMedia,
        advertiseMaterial: this.inputParam.advertiseMaterial,
        concern: this.inputParam.concern,
        mullTriggeredCue: this.inputParam.mullTriggeredCue,
        applicationPlanCue: this.inputParam.applicationPlanCue,
        wishEmail: this.inputParam.wishEmail,
        karteUserId: this.karteUserId,
        stateCityName: this.inputParam.stateCityName,
      };
    },
    setInputLocalParam() {
      this.inputParam.familyName = this.getInputPageParam().familyName;
      this.inputParam.firstName = this.getInputPageParam().firstName;
      this.inputParam.familyNameKana = this.getInputPageParam().familyNameKana;
      this.inputParam.firstNameKana = this.getInputPageParam().firstNameKana;
      this.inputParam.insuredPersonGender =
        this.getInputPageParam().insuredPersonGender;
      this.inputParam.birthArray = this.getInputPageParam().birthArray;
      this.inputParam.zipcode = this.getInputPageParam().zipcode;
      this.searchZipCode = this.getInputPageParam().zipcode;
      this.getAddressForReEnter();
      this.inputParam.cityName = this.getInputPageParam().cityName;

      if (this.deviceDecision() === "TRUE") {
        this.inputParam.stateCityName = this.getInputPageParam().stateCityName;
        this.inputParam.cityName = this.inputParam.cityName.replace(
          this.inputParam.stateCityName,
          ""
        );
        this.stateCityNameDisplay();
      }
      this.inputParam.blockNumber = this.getInputPageParam().blockNumber;
      this.inputParam.buildingName = this.getInputPageParam().buildingName;
      this.inputParam.primaryPhone = this.getInputPageParam().primaryPhone;
      this.inputParam.email = this.getInputPageParam().email;
      // 入力内容を修正ボタンで戻ってきた時のみ設定
      if (this.getReturnFromConfirm()) {
        this.inputParam.maritalStatus = this.getInputPageParam().maritalStatus;
        this.inputParam.childNumber = this.getInputPageParam().childNumber;
        this.inputParam.workingStyle = this.getInputPageParam().workingStyle;
        this.inputParam.concern = this.getInputPageParam().concern;
        this.inputParam.mullTriggeredCue =
          this.getInputPageParam().mullTriggeredCue;
      }
      this.inputParam.advertiseMedia = this.getInputPageParam().advertiseMedia;
      this.inputParam.advertiseMaterial =
        this.getInputPageParam().advertiseMaterial;
      this.inputParam.applicationPlanCue =
        this.getInputPageParam().applicationPlanCue;
      this.inputParam.wishEmail = this.getInputPageParam().wishEmail;
    },
    confirmSave(event: { returnValue: string }) {
      event.returnValue = "行った変更が保存されない可能性があります。";
    },
    checkContractDate() {
      // 現在日を取得
      const nowDate = new Date();
      // 現在月の月末日を取得
      const monthLastDate = new Date(
        nowDate.getFullYear(),
        nowDate.getMonth() + 1,
        0
      );
      if (nowDate.getDate() === monthLastDate.getDate()) {
        this.monthLastMessage =
          "※月末日に資料請求される場合、契約年齢は翌々月1日時点の年齢を表示しています。";
      }
    },

    /**
     * スクロールイベント
     */
    moveToTop() {
      // 移動速度（1秒で終了）
      const duration = 200;
      // 0.025秒ごとに移動
      const interval = 1;
      // 1回に移動する距離
      const step = -window.scrollY / Math.ceil(duration / interval);
      const timer = setInterval(() => {
        // スクロール位置を移動
        window.scrollBy(0, step);

        if (window.scrollY <= 0) {
          clearInterval(timer);
        }
      }, interval);
    },

    /**
     * 郵便番号から住所情報を取得する
     */
    getAddress() {
      document.getElementById("addarea3")!.style.backgroundColor = "#fff";
      this.validationMessages = [];
      this.errorTagList = this.errorTagList.filter((a) => a !== "addarea3");
      if (!this.isZipCode()) {
        this.zipCodeValidateError();
        return;
      }

      this.validationResultFlg = true;
      this.setLoadingStatus(true);

      // スマホ判定
      const smartphone = this.deviceDecision();

      axios
        .get(
          this.addressMasterApiUrl + this.searchZipCode + "&sp=" + smartphone
        )
        .then((res) => {
          // レスポンスのエラーフラグが0以外の場合、処理を中断する
          if (res.data.error != "0") {
            this.addressSearchError();
            return;
          }
          this.addressList = [];
          this.selectList = [];
          this.inputParam.stateCityName = "";
          if (res.data.count === "0") {
            this.addressSearchError();
            return;
          }
          this.inputParam.cityName = "";
          this.inputParam.zipcode = this.searchZipCode;

          // 住所選択リストを作成
          this.createAddressSelectList(res);
          // ローディング画面を非表示

          if (smartphone === "TRUE") {
            // 都道府県市区町村をテキスト表示
            this.inputParam.stateCityName = res.data.stateAndCity;
            this.stateCityNameDisplay();
          }
          // 取得数が1件だった場合、その項目を選択する
          if (res.data.count === "1") {
            this.inputParam.cityName = this.selectList[0].addressdisp;
          }

          this.cityNameSelectBoxActive();
          // バリデーション結果フラグをTrueにする
          this.validationResultFlg = true;
          this.setLoadingStatus(false);
        })
        .catch((err) => {
          // ローディング画面を非表示
          this.setLoadingStatus(false);
        });
    },

    getAddressForReEnter() {
      document.getElementById("addarea3")!.style.backgroundColor = "#fff";
      this.validationMessages = [];
      this.errorTagList = this.errorTagList.filter((a) => a !== "addarea3");
      if (!this.isZipCode()) {
        this.zipCodeValidateError();
        return;
      }

      this.validationResultFlg = true;
      this.setLoadingStatus(true);

      // スマホ判定
      const smartphone = this.deviceDecision();

      axios
        .get(
          this.addressMasterApiUrl + this.searchZipCode + "&sp=" + smartphone
        )
        .then((res) => {
          // レスポンスのエラーフラグが0以外の場合、処理を中断する
          if (res.data.error != "0") {
            this.addressSearchError();
            return;
          }
          this.addressList = [];
          this.selectList = [];
          this.inputParam.stateCityName = "";
          if (res.data.count === "0") {
            this.addressSearchError();
            return;
          }

          // 住所選択リストを作成
          this.createAddressSelectList(res);
          // ローディング画面を非表示

          if (smartphone === "TRUE") {
            // 都道府県市区町村をテキスト表示
            this.inputParam.stateCityName = res.data.stateAndCity;
            this.stateCityNameDisplay();
          }

          this.cityNameSelectBoxActive();
          // バリデーション結果フラグをTrueにする
          this.validationResultFlg = true;
          this.setLoadingStatus(false);
        })
        .catch((err) => {
          // ローディング画面を非表示
          this.setLoadingStatus(false);
        });
    },

    // スマホ判定
    deviceDecision(): string {
      if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
        return "TRUE";
      }
      return "FALSE";
    },

    // 住所取得のためのパラメータ設定
    setAddressParam(smartphone: string): object {
      if (smartphone === "TRUE") {
        return { code: this.searchZipCode, sp: "TRUE" };
      }
      return { code: this.searchZipCode };
    },

    /**
     * 郵便番号検索時のバリデーションエラー時の挙動
     */
    zipCodeValidateError() {
      document.getElementById("addarea3")!.style.backgroundColor = "#ffd9d9";
      this.errorTagList.push("addarea3");
      this.validationMessages.push("郵便番号は7桁の数値で入力してください。");
      this.inputParam.stateCityName = "";
      this.cityNameSelectBoxNonActive();
      this.moveToTop();
      this.validationResultFlg = false;
    },
    /**
     * 郵便番号検索時の検索エラー時の挙動
     */
    addressSearchError() {
      document.getElementById("addarea3")!.style.backgroundColor = "#ffd9d9";
      this.errorTagList.push("addarea3");
      this.validationMessages.push(
        CatalogMessages.generateMessage(
          CatalogInternalCodeEnum.CityNameSearchError
        )
      );
      this.validationResultFlg = false;
      this.moveToTop();
      this.setLoadingStatus(false);
      this.cityNameSelectBoxNonActive();
    },
    /**
     * 住所取得APIからのレスポンスで住所選択リストを作成します
     *
     * @param addressMasterResponse
     */
    createAddressSelectList(addressMasterResponse: any) {
      // 住所選択プルダウン生成
      const data = addressMasterResponse.data as AddressResponseList;

      this.addressList = data.addresses.sort((a, b) => {
        return a.addresscode < b.addresscode ? -1 : 1;
      });

      this.addressList.forEach((element: AddressList) => {
        this.selectList.push({
          addresscode: element.addresscode,
          addressdisp: element.addressdisp,
        });
      });
    },

    /**
     * 都道府県市区町村のセレクトボックスを表示する
     */
    cityNameSelectBoxActive() {
      // テキストボックスを非表示、プルダウンを表示
      const elementText = document.getElementById("a_04_02_text");
      if (elementText) {
        elementText.style.display = "none";
      }
      const elementSelect = document.getElementById("a_04_02_select");
      if (elementSelect) {
        elementSelect.style.display = "block";
      }
    },

    /**
     * 都道府県市区町村のセレクトボックスを非表示する
     */
    cityNameSelectBoxNonActive() {
      // 都道府県市区町村の選択をリセットする
      this.inputParam.cityName = "";
      // テキストボックスを表示、プルダウンを非表示
      const elementText = document.getElementById("a_04_02_text");
      if (elementText) {
        elementText.style.display = "block";
      }
      const elementSelect = document.getElementById("a_04_02_select");
      if (elementSelect) {
        elementSelect.style.display = "none";
      }
    },
    /**
     * スマホの場合、都道府県市区町村をテキスト表示する
     */
    stateCityNameDisplay() {
      const elementText = document.getElementById("adr_top");
      if (elementText) {
        elementText.style.display = "block";
      }
    },

    /**
     * クエリパラメータ、マイページ資料請求画面の値を設定
     */
    setAdvertiseMaterial() {
      const query = this.$route.query;
      const advertiseMaterial = new Array<string>();
      if (query.cid != undefined) {
        this.inputParam.advertiseMedia = query.cid as string;
        if (query.m != undefined) {
          advertiseMaterial.push("m=" + query.m);
        }
        if (query.t != undefined) {
          advertiseMaterial.push("t=" + query.t);
        }
        if (query.cl != undefined) {
          advertiseMaterial.push("cl=" + query.cl);
        }
        if (query.lp != undefined) {
          advertiseMaterial.push("lp=" + query.lp);
        }
        this.inputParam.advertiseMaterial = advertiseMaterial.join("&");
      }
    },

    setCityNameByAddressCode(addresscode: string) {
      document.getElementById("addarea3")!.style.backgroundColor = "#fff";
      this.validationMessages = [];
      this.errorTagList = this.errorTagList.filter((a) => a !== "addarea3");
      if (!this.isZipCode()) {
        this.zipCodeValidateError();
        this.myPageCatalogApplyPrisetCheck();
        return;
      }

      this.setLoadingStatus(true);

      // スマホ判定
      const smartphone = this.deviceDecision();

      axios
        .get(
          this.addressMasterApiUrl + this.searchZipCode + "&sp=" + smartphone
        )
        .then((res) => {
          // レスポンスのエラーフラグが0以外の場合、処理を中断する
          if (res.data.error != "0") {
            this.setLoadingStatus(false);
            this.myPageCatalogApplyPrisetCheck();
            return;
          }
          this.addressList = [];
          this.selectList = [];
          this.inputParam.stateCityName = "";
          if (res.data.count === "0") {
            this.setLoadingStatus(false);
            this.myPageCatalogApplyPrisetCheck();
            return;
          }
          this.inputParam.cityName = "";
          this.inputParam.zipcode = this.searchZipCode;

          // 住所選択リストを作成
          this.createAddressSelectList(res);
          // ローディング画面を非表示

          if (smartphone === "TRUE") {
            // 都道府県市区町村をテキスト表示
            this.inputParam.stateCityName = res.data.stateAndCity;
            this.stateCityNameDisplay();
          }
          // 取得数が1件だった場合、その項目を選択する
          if (res.data.count === "1") {
            this.inputParam.cityName = this.selectList[0].addressdisp;
          }

          this.cityNameSelectBoxActive();
          // バリデーション結果フラグをTrueにする
          this.validationResultFlg = true;
          const addressDispCount = this.addressList.filter(
            (e) => e.addresscode === addresscode
          );
          // 指定された町字コードのデータが0件ではなかった場合、住所をセット
          this.inputParam.cityName =
            addressDispCount.length === 0
              ? ""
              : addressDispCount[0].addressdisp;
          // プリセット値のチェックを行います。
          this.myPageCatalogApplyPrisetCheck();
          this.setLoadingStatus(false);
        })
        .catch((err) => {
          // ローディング画面を非表示
          this.setLoadingStatus(false);
          this.myPageCatalogApplyPrisetCheck();
        });
    },

    myPageCatalogApplyPrisetCheck() {
      const param: InputParam = this.generateInputParam();

      // バリデーションを実施
      this.validationResult = Validater.validateCatalog(
        param,
        this.searchZipCode
      );

      if (this.validationResult.length != 0) {
        // バリデーションが失敗した場合

        this.validationResult.forEach((result) => {
          const formElement = document.getElementById(
            result.inputName
          ) as HTMLInputElement;
          formElement.style.backgroundColor = "#ffd9d9";
          this.resetErrorForm(result.internalCode);
          this.errorTagList.push(result.inputName);
          this.validationMessages.push(
            MyPageCatalogMessages.generateMessage(result.internalCode)
          );
        });
        const set = new Set(this.validationMessages);
        this.validationMessages = [...set];
        this.validationResultFlg = false;
      }
    },

    /**
     * エラーコードに該当するフォームをリセットする。
     *
     * @param internalCode
     */
    resetErrorForm(internalCode: string) {
      switch (internalCode) {
        case "E1":
        case "E2":
        case "E3":
          this.inputParam.email = "";
          break;
        case "E6":
        case "E7":
        case "E8":
        case "E9":
        case "E10":
        case "E11":
        case "E12":
        case "E40":
          this.inputParam.birthArray.birthYear = 0;
          this.inputParam.birthArray.birthMonth = 0;
          this.inputParam.birthArray.birthDay = 0;
          break;
        case "E13":
        case "E14":
        case "E15":
          this.inputParam.firstName = "";
          break;
        case "E16":
        case "E17":
        case "E18":
          this.inputParam.firstNameKana = "";
          break;
        case "E19":
        case "E20":
        case "E21":
          this.inputParam.familyName = "";
          break;
        case "E22":
        case "E23":
        case "E24":
          this.inputParam.familyNameKana = "";
          break;
        case "E25":
        case "E26":
          this.inputParam.insuredPersonGender = "";
          break;
        case "E27":
        case "E28":
          this.inputParam.cityName = "";
          break;
        case "E29":
        case "E30":
        case "E41":
          this.inputParam.blockNumber = "";
          break;
        case "E31":
        case "E32":
        case "E43":
        case "E44":
          this.inputParam.zipcode = "";
          this.searchZipCode = "";
          break;
        case "E33":
        case "E42":
          this.inputParam.buildingName = "";
          break;
        case "E34":
        case "E35":
        case "E45":
          this.inputParam.primaryPhone = "";
          break;
        default:
          break;
      }
    },

    /**
     * 性別をコード値から画面値に変換します。
     *
     * @param sexCode
     * @returns
     */
    comvertSexValue(sexCode: string): string {
      if (sexCode === "M" || sexCode === "F") {
        return sexCode === "M" ? "1" : "2";
      }
      return "";
    },

    /**
     * マイページ資料請求時のフォームプリセットを行います。
     */
    myPageCatalogApplyDataPreSetForm() {
      const cookie = this.$cookies;
      // マイページ資料請求画面からリダイレクトした場合
      if (cookie.get("myPageCatalogApply") != undefined) {
        this.setIsMyPageApply(true);
        const myPageCatalogApply = new Array(cookie.get("myPageCatalogApply"));
        this.inputParam.familyName = myPageCatalogApply[0].familyName;
        this.inputParam.firstName = myPageCatalogApply[0].firstName;
        this.inputParam.familyNameKana = myPageCatalogApply[0].familyNameKana;
        this.inputParam.firstNameKana = myPageCatalogApply[0].firstNameKana;
        this.inputParam.insuredPersonGender = this.comvertSexValue(
          myPageCatalogApply[0].insuredPersonGender
        );
        this.inputParam.birthArray.birthYear = myPageCatalogApply[0].birthYear;
        this.inputParam.birthArray.birthMonth =
          myPageCatalogApply[0].birthMonth;
        this.inputParam.birthArray.birthDay = myPageCatalogApply[0].birthDay;
        this.inputParam.zipcode = myPageCatalogApply[0].zipcode;
        this.searchZipCode = myPageCatalogApply[0].zipcode;
        this.inputParam.blockNumber = myPageCatalogApply[0].blockNumber;
        this.inputParam.buildingName = myPageCatalogApply[0].buildingName;
        this.inputParam.primaryPhone = myPageCatalogApply[0].primaryPhone;
        this.inputParam.email = myPageCatalogApply[0].email;
        this.setCityNameByAddressCode(myPageCatalogApply[0].addressCode);
        cookie.remove("myPageCatalogApply");
      } else {
        this.setLoadingStatus(true);
        setTimeout(() => {
          this.setLoadingStatus(false);
        }, 1000);
      }
    },

    // 全角数値から半角数値に変換 (郵便番号)
    zipcodeNumberChange() {
      this.searchZipCode = this.searchZipCode.replace(/[！-～]/g, (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      });
    },
    // 全角数値から半角数値に変換 (電話番号)
    primaryPhoneNumberChange() {
      this.inputParam.primaryPhone = this.inputParam.primaryPhone.replace(
        /[！-～]/g,
        (s) => {
          return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
        }
      );
    },
    // 半角カナから全角カナに変換
    familyNameKanaChange() {
      for (const [narrowKana, wideKana] of Object.entries(convertMap)) {
        this.inputParam.familyNameKana = this.inputParam.familyNameKana.replace(
          new RegExp(narrowKana, "g"),
          wideKana
        );
      }
    },
    // 半角カナから全角カナに変換
    firstNameKanaChange() {
      for (const [narrowKana, wideKana] of Object.entries(convertMap)) {
        this.inputParam.firstNameKana = this.inputParam.firstNameKana.replace(
          new RegExp(narrowKana, "g"),
          wideKana
        );
      }
    },
    /**
     * 郵便番号形式かどうかを判定します。
     *
     * @returns boolean
     */
    isZipCode(): boolean {
      return (
        this.searchZipCode != "" ||
        this.searchZipCode.length === 7 ||
        this.searchZipCode.match(/^[0-9]+$/) === null
      );
    },
  },
  created() {
    window.addEventListener("beforeunload", this.confirmSave);
  },
  unmounted() {
    window.removeEventListener("beforeunload", this.confirmSave);
  },
  computed: {
    /**
     * 資料請求情報登録APIのURLを返却します。
     *
     * @returns 資料請求情報登録APIのURL
     */
    addressMasterApiUrl(): string {
      return ApiUrlEnv.apiBaseUrl + "/address/?code=";
    },
  },
  /**
   * コンポーネントがマウントされた時の処理
   * ・再入力フラグがONだった場合の処理
   * ・媒体コードを読み込む処理
   * ・マイページ資料請求の処理
   */
  mounted() {
    // もう1セット申込をするフラグがONだった場合
    if (this.getReEnterStatus()) {
      this.setInputLocalParam();
      this.reEnterStatus = true;
      this.setReEnterStatus();
      this.checkContractDate();
      return;
    }

    // クエリパラメータ、マイページ資料請求画面の値を設定
    this.setAdvertiseMaterial();
    this.myPageCatalogApplyDataPreSetForm();
    this.checkContractDate();
    this.setReturnFromConfirm(false);
  },
});
