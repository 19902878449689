import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fdddec7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Date-box_test" }
const _hoisted_2 = { class: "Date-name_test" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputFormMiniComponentAtoms = _resolveComponent("InputFormMiniComponentAtoms")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.formTitle) + " ", 1),
      _createVNode(_component_InputFormMiniComponentAtoms, {
        type: _ctx.type,
        formTitle: _ctx.formTitle,
        placeHolder: _ctx.placeHolder,
        modelValue: _ctx.localModelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
        maxLength: _ctx.maxLength,
        required: true,
        kanaInput: _ctx.kanaInput
      }, null, 8, ["type", "formTitle", "placeHolder", "modelValue", "maxLength", "kanaInput"])
    ])
  ]))
}