import { createStore } from "vuex";
import loadingStatus from "./modules/loadingStatus";
import catalogStatus from "./modules/catalog/catalogStatus";
import reserveStatus from "./modules/reserve/reserveStatus";
import leadStatus from "./modules/lead/leadStatus";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  modules: {
    loadingStatus,
    catalogStatus,
    reserveStatus,
    leadStatus,
  },
  plugins: [
    createPersistedState({
      // 管理対象のステートを指定。pathsを書かない時は`modules`に書いたモジュールに含まれるステート全て。`[]`の時はどれも保存されない
      paths: [
        "catalogStatus.inputPageParam",
        "catalogStatus.catalogId",
        "reserveStatus.reserveDateTimeMessage",
        "leadStatus.estimateSiteUrl",
      ],

      // ストレージの種類を指定する。デフォルトではローカルストレージ
      storage: window.sessionStorage,
    }),
  ],
});
