<template>
  <div class="l-base">
    <ErrorMessageAreaMolecules
      v-if="!validationResultFlg"
      :errorList="validationMessages"
    />
  </div>
  <div class="l-base">
    <div id="leadInfoForm" class="Form-wrap">
      <div class="Box-flame">
        <table class="Form-tbl">
          <tbody>
            <tr class="Form-tbl__tr">
              <th class="Form-tbl__th">
                <div class="Form-name">
                  メールアドレスまたは<br />携帯電話番号（SMS）<span
                    class="Ico-must"
                    >必須</span
                  >
                </div>
              </th>
              <td id="input_email" class="Form-tbl__td">
                <div class="Date-box">
                  <LongInputMolecules
                    type="text"
                    v-model="inputParam.mailSmsRecipient"
                    formTitle="メールアドレス"
                    :email="true"
                    :maxLength="80"
                    :required="true"
                  />
                  <p class="Ex-txt">
                    （例： lifenet@lifenet-seimei.co.jp, 08023456789）
                  </p>
                </div>
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th class="Form-tbl__th">
                <div class="Form-name">お役立ち情報</div>
              </th>
              <td id="a_08_00" class="Form-tbl__td">
                <p>
                  <span
                    >お客さまの保険検討に役立つ情報などをお届けしてもよろしいでしょうか？</span
                  ><br /><span>（配信はいつでもキャンセルできます）</span>
                </p>
                <SendMailMolecules v-model="inputParam.wishMail" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--/.Box-flame-->

      <div class="Box-flame">
        <div class="Notes">
          <h2 class="Sub-ttl">ご注意ください</h2>
          <ul class="Baselist">
            <li class="Baselist__item List-mark">
              <p>
                メールでお送りするお見積り内容に記載された保険料は、お見積り時点の保険料率に基づき、お見積り条件に入力された契約年齢と性別で計算されています。
              </p>
              <p class="Rule-txt">
                ※契約年齢は「申込日の翌月1日における被保険者の満年齢」で計算しています。
              </p>
            </li>
            <li class="Baselist__item List-mark">
              契約年齢が上がると、ほとんどの場合、保険料は上がります。
            </li>
            <li class="Baselist__item List-mark">
              <span
                >携帯メールで各種お知らせを受信したい場合には、迷惑メール防止機能で受信拒否されないよう、以下ドメインからのメールを受信可能にご設定ください。</span
              ><span>「@lifenet-seimei.co.jp」<br /></span>
              「@mail.lifenet-seimei.co.jp」
            </li>
          </ul>
        </div>
      </div>
      <!--/.Box-flame-->
      <p class="Control-back">
        <a :href="estimateSiteUrl" class="Link-arw">保険料見積りに戻る</a>
      </p>

      <PolicyProtectionMolecules />
      <!--/.Box-flame-->
      <div id="estimate" class="Control-area">
        <button
          type="button"
          id="FormAssist_submit"
          @click="goToComplete()"
          class="Control-area__btn"
        >
          個人情報保護宣言に同意して<span class="Control-area__btn__em"
            >送信する</span
          >
        </button>
      </div>
    </div>
    <input type="hidden" id="karte-user-id" />
    <!--/.Box-flame-->
  </div>
</template>
<!-- // templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用 -->
<script src="./LeadInput.ts" />

<style scoped>
*,
::after,
::before {
  box-sizing: border-box;
}
p {
  margin: 0;
}
b,
strong {
  font-weight: bolder;
}
table {
  border-collapse: collapse;
}
.Form-tbl__th,
.Form-tbl__td {
  color: #2c4318;
}
.Date-box {
  display: flex;
  max-width: 532px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
button,
input {
  overflow: visible;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.Box-flame + .Box-flame {
  margin-top: 20px;
}
.Baselist {
  margin: 0;
  padding: 0;
  list-style: none;
}
.List-mark {
  position: relative;
  display: flex;
  padding-left: calc((1em + 10px));
  flex-direction: column;
}
.Baselist__item {
  margin: 10px 0;
}
.Control-back {
  margin: 20px 0 40px;
}
a {
  text-decoration: underline;
  color: #0044cc;
  background-color: transparent;
}
.Link-arw::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-color: transparent transparent transparent #0044cc;
  content: "";
}
.Control-area {
  position: relative;
  margin: 104px 0 66px;
  text-align: center;
}
.Control-area__btn::before {
  width: 30px;
  height: 30px;
  margin: -15px 0 0 -50px;
  border-radius: 50%;
  background: #fff;
}
.Control-area__btn::after {
  width: 0;
  height: 0;
  margin: -8px 0 0 -38px;
  border-style: solid;
  border-width: 8px 0 8px 11px;
  border-color: transparent transparent transparent #ff9900;
}
.Control-area__btn::before,
.Control-area__btn::after {
  display: block;
  position: absolute;
  top: 50%;
  left: 100%;
  content: "";
}
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd {
  margin: 0;
  font-size: 100%;
}
@media screen and (min-width: 501px) {
  .Form-tbl__tr:not(:first-of-type) {
    border-top: 1px dotted #ccc;
  }
  .policy-sp {
    display: none;
  }

  .l-base {
    width: 1040px;
    margin: 0 auto;
  }
  .Form-wrap {
    width: 840px;
    margin: 0 auto;
  }
  .Form-wrap .Box-flame {
    border-color: #80b735;
    box-shadow: none;
  }
  .Box-flame {
    overflow: hidden;
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    background: #fafafa;
  }
  .Form-tbl {
    width: 100%;
    background: #f4faeb;
  }

  .Form-tbl__th {
    position: relative;
    width: calc(100% - 582px);
    padding: 0 0 0 26px;
    font-weight: normal;
    vertical-align: middle;
    align-items: center;
    letter-spacing: normal;
  }

  .Form-name {
    display: flex;
    padding: 10px 15px 5px 0;
    justify-content: space-between;
    align-items: center;
  }
  .Ico-must {
    display: inline-block;
    width: 40px;
    height: 22px;
    border: 2px solid #f00;
    border-radius: 4px;
    background: #fff;
    color: #f00;
    font-size: 1.2rem;
    text-align: center;
    line-height: 18px;
  }
  .Form-tbl__td {
    width: 582px;
    padding: 5px 24px;
    border-left: 1px solid #80b735;
    background: #fff;
  }

  .Ex-txt {
    color: #666;
  }

  .Rule-txt {
    font-size: 1.3rem;
  }

  .Notes {
    margin: 0 50px 32px;
  }
  .Sub-ttl {
    margin: 30px 0;
    font-size: 2.4rem;
    color: #3b5e10;
    line-height: 1.2;
    letter-spacing: 0.04em;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
  }

  .List-mark {
    position: relative;
    display: flex;
    padding-left: calc((1em + 10px));
    flex-direction: column;
  }

  .List-mark::before {
    position: absolute;
    left: 8px;
    top: -1px;
    vertical-align: middle;
    content: "●";
  }

  .Link-arw {
    display: inline-block;
    padding-left: 11px;
    text-indent: -11px;
  }

  .Control-area::before {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    margin: -73px 0 0 -109px;
    border-width: 46px 109px 0 109px;
    border-color: #ff9900 transparent transparent transparent;
    border-style: solid;
    content: "";
  }
  .Control-area__btn {
    position: relative;
    width: 580px;
    min-height: 121px;
    padding: 20px 0 11px;
    border: none;
    border-radius: 8px;
    background: #ff9900;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -4px rgb(0 16 14 / 31%);
    color: #fff;
    font-size: 2.4rem;
    line-height: 1;
    transition: all 0.3s ease;
  }

  .Control-area__btn__em {
    display: block;
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.6;
  }

  .Block-head__txt {
    margin-top: 32px;
    margin-bottom: 32px;
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  br {
    display: none;
  }
  p span {
    display: inline-block;
  }
  .List-mark span {
    display: inline-block;
  }

  .policy-open {
    font-size: 2.1rem;
    color: #6c8939;
    padding-bottom: 10px;
    border-bottom: 1px solid #6c8939;
  }
  .policy-close {
    font-size: 2.1rem;
    color: #6c8939;
    padding-bottom: 10px;
  }
  .policy {
    display: none;
  }

  .l-base {
    padding: 0 10px;
  }
  form .Box-flame {
    border-color: #80b735;
    box-shadow: none;
  }
  .Box-flame {
    overflow: hidden;
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    background: #fff;
  }
  .Form-tbl {
    width: 100%;
    background: #f4faeb;
    font-size: 1.6rem;
  }

  .Form-tbl__th {
    position: relative;
    padding: 10px;
    font-weight: normal;
    vertical-align: middle;
  }

  .Form-tbl__tr {
    display: flex;
    flex-direction: column;
  }

  .Form-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    text-align: left;
  }
  .Ico-must {
    display: inline-block;
    width: 40px;
    height: 26px;
    margin: 0 0 3px 20px;
    border: 2px solid #f00;
    border-radius: 4px;
    background: #fff;
    color: #f00;
    font-size: 1.2rem;
    text-align: center;
    line-height: 22px;
    white-space: nowrap;
  }
  .Form-tbl__td {
    padding: 10px 20px;
    background: #fff;
  }

  .Ex-txt {
    color: #666;
    font-size: 1.6rem;
  }

  .Rule-txt {
    font-size: 1.4rem;
  }

  .Notes {
    margin: 0 10px;
  }
  .Sub-ttl {
    margin: 20px 0;
    font-size: 1.8rem;
    color: #3b5e10;
    line-height: 1.4;
    letter-spacing: 0.04em;
  }

  .List-mark::before {
    position: absolute;
    left: 8px;
    top: 0.5em;
    vertical-align: middle;
    font-size: 1rem;
    content: "●";
  }

  .Link-arw {
    display: inline-block;
    padding-left: 11px;
    text-indent: -11px;
    color: #0044cc;
  }

  .Control-area::before {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    margin: -45px 0 0 -50px;
    border-width: 30px 50px 0 50px;
    border-color: #ff9900 transparent transparent transparent;
    border-style: solid;
    content: "";
  }
  .Control-area__btn {
    display: block;
    position: relative;
    width: 100%;
    padding: 18px 40px 14px 23px;
    border: none;
    border-radius: 8px;
    background: #ff9900;
    box-shadow: 0 0 0 1px rgba(0, 16, 14, 0.03),
      0 8px 16px -8px rgba(0, 16, 14, 0.31);
    color: #fff !important;
    line-height: 1;
  }

  .Control-area__btn__em {
    display: block;
    font-size: 2.3rem;
    font-weight: bold;
    line-height: 1.6;
  }
}
</style>
