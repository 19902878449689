import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-701b85b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "l-baseCat" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_IntroductionComponent = _resolveComponent("IntroductionComponent")!
  const _component_ReserveInputOrganism = _resolveComponent("ReserveInputOrganism")!
  const _component_CatalogInquiryOrganism = _resolveComponent("CatalogInquiryOrganism")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderComponent, { pageTitle: "電話＆オンラインでの保険相談（無料）予約" }),
    _createVNode(_component_IntroductionComponent, {
      titleHead1: "ご予約希望日、お客さま情報をご入力ください。",
      titleHead2: "ご希望の時間帯に、保険プランナーよりお電話",
      titleHead3: "いたします。",
      listFirstRow1: "重複して登録があった場合は、最後にご予約いただいた希望時間帯に",
      listFirstRow2: "お電話させていただきます。",
      listSecondRow1: "こちらからお電話させていただいた際に、",
      listSecondRow2: "「電話での相談」または「オンラインでの相談」のどちらを希望されるか確認させていただきます。",
      listThirdRow1: "ご予約希望日時は5営業日の範囲でお選びいただけます。",
      listFourthRow1: "他の日程をご希望の場合は、お手数ですが弊社コンタクトセンターまで",
      listFourthRow2: "お電話にてお問い合わせください。"
    }),
    _createVNode(_component_ReserveInputOrganism),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CatalogInquiryOrganism)
    ]),
    _createVNode(_component_FooterComponent)
  ]))
}