import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d66870c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "l-baseCat" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_LeadIntroductionOrganism = _resolveComponent("LeadIntroductionOrganism")!
  const _component_LeadInputOrganism = _resolveComponent("LeadInputOrganism")!
  const _component_CatalogInquiryOrganism = _resolveComponent("CatalogInquiryOrganism")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderComponent, { pageTitle: "見積り内容をメールまたはSMSで送信" }),
    _createVNode(_component_LeadIntroductionOrganism, {
      introAreaLead1: "お見積りの内容を、以下にご入力いただいたメールアドレスもしくは携帯電話番号（SMS）にお送りします。",
      introAreaTxt1: "保障内容のご検討や、ご家族とのご相談の際に、ぜひ、お役立てください。"
    }),
    _createVNode(_component_LeadInputOrganism),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CatalogInquiryOrganism)
    ]),
    _createVNode(_component_FooterComponent)
  ]))
}