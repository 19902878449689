<template>
  <CatalogInputTemplate />
</template>
<script lang="ts">
// Pagesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. (場合によってoganismsに責務を持たせるが)エラーハンドリング用Vuexステータスを操作
// 4. ページ(アプリケーション)全体に及ぶCSSの適用
import { defineComponent, nextTick } from "vue";
import CatalogInputTemplate from "@/components/templates/catalog/CatalogInputTemplate.vue";
import { mapGetters, mapMutations } from "vuex";
import router from "../../../router/index";

export default defineComponent({
  name: "CatalogInputPage",
  components: {
    CatalogInputTemplate,
  },
  methods: {
    ...mapMutations(["setLoadingStatus"]),
  },
  computed: {
    ...mapGetters(["getLoadingStatus"]),
  },
});
</script>
<style scoped></style>
