<template>
  <div class="l-base">
    <ErrorMessageAreaMolecules
      v-if="!validationResultFlg"
      :errorList="validationMessages"
    />
    <div id="confirmCatalogForm">
      <div class="Form-wrap">
        <div class="Box-flame">
          <table class="Form-tbl">
            <tbody>
              <TableDataConfirmMolecules
                tableHead="お名前"
                :tableData="
                  getVuexInputPageParam().familyName +
                  ' ' +
                  getVuexInputPageParam().firstName
                "
              />
              <TableDataConfirmMolecules
                tableHead="お名前（カタカナ）"
                :tableData="
                  getVuexInputPageParam().familyNameKana +
                  ' ' +
                  getVuexInputPageParam().firstNameKana
                "
              />
              <GenderConfirmMolecules
                :codeValue="getVuexInputPageParam().insuredPersonGender"
              />
              <TableDataConfirmMolecules
                tableHead="生年月日"
                :tableData="
                  getVuexInputPageParam().birthArray.birthYear +
                  '年' +
                  getVuexInputPageParam().birthArray.birthMonth +
                  '月' +
                  getVuexInputPageParam().birthArray.birthDay +
                  '日' +
                  contactAgeText
                "
              />
            </tbody>
          </table>
        </div>

        <div class="Box-flame">
          <table class="Form-tbl contact-info-table">
            <tbody>
              <tr class="Form-tbl__tr">
                <th rowspan="4" class="Form-tbl__th Form-tbl__th--confirm">
                  ご住所
                </th>
                <MiddleTableDataConfirmMolecules
                  tableHead="郵便番号"
                  :tableData="getVuexInputPageParam().zipcode"
                />
              </tr>
              <tr class="Form-tbl__tr-pc">
                <MiddleTableDataConfirmMolecules
                  :tableHead="cityNameTableHead"
                  :tableData="getVuexInputPageParam().cityName"
                />
              </tr>
              <tr class="Form-tbl__tr-sp">
                <MiddleTableDataConfirmMolecules
                  :tableHead="cityNameTableHead_sp"
                  :tableData="getVuexInputPageParam().cityName"
                />
              </tr>

              <tr class="Form-tbl__tr">
                <MiddleTableDataConfirmMolecules
                  tableHead="番地"
                  :tableData="getVuexInputPageParam().blockNumber"
                />
              </tr>
              <tr class="Form-tbl__tr-pc">
                <MiddleTableDataConfirmMolecules
                  :tableHead="bilingNameTableHead"
                  :tableData="getVuexInputPageParam().buildingName"
                />
              </tr>
              <tr class="Form-tbl__tr-sp">
                <MiddleTableDataConfirmMolecules
                  :tableHead="bilingNameTableHead_sp"
                  :tableData="getVuexInputPageParam().buildingName"
                />
              </tr>
              <MiddleTwoRowTableDataConfirmMolecules
                tableHead="電話番号"
                :tableData="getVuexInputPageParam().primaryPhone"
              />
              <MiddleTwoRowTableDataConfirmMolecules
                tableHead="メールアドレス"
                :tableData="getVuexInputPageParam().email"
              />
            </tbody>
          </table>
        </div>
        <div class="Box-flame">
          <table class="Form-tbl">
            <tbody>
              <MaritalStatusConfirmMolecules
                :codeValue="getVuexInputPageParam().maritalStatus"
              />
              <ChildPresenceConfirmMolecules
                :codeValue="getVuexInputPageParam().childNumber"
              />
              <WorkingStyleConfirmMolecules
                :codeValue="getVuexInputPageParam().workingStyle"
                :genderCodeValue="getVuexInputPageParam().insuredPersonGender"
              />
              <ConcernCheckboxConfirmMolecules
                :codeValue="getVuexInputPageParam().concern.sort()"
              />
              <MullTriggeredCueCheckboxConfirmMolecules
                :codeValue="getVuexInputPageParam().mullTriggeredCue.sort()"
              />
            </tbody>
          </table>
        </div>
        <div class="Box-flame">
          <table class="Form-tbl">
            <tbody>
              <WishMailCheckboxConfirmMolecules
                :codeValue="getVuexInputPageParam().wishEmail"
              />
            </tbody>
          </table>
        </div>
      </div>
      <div class="Form-wrap">
        <div class="Control-back">
          <button
            type="submit"
            id="correction"
            name="revise_x"
            class="Btn Btn--back"
            @click="inputFix()"
          >
            入力内容を修正
          </button>
        </div>
      </div>

      <div class="Form-wrap">
        <!-- 2017/02/09～2017/03/12 -->
        <div id="transmission" class="Control-area">
          <button
            type="button"
            name="regist"
            id="transmissionbtn"
            class="Control-area__btn"
            @click="catalogApply()"
          >
            <span class="Control-area__btn__em">この内容で資料請求する</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- // templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用 -->
<script src="./CatalogInputConfirm.ts" />

<style scoped>
.Box-flame {
  overflow: hidden;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  background: #fafafa;
}
.Box-flame + .Box-flame {
  margin-top: 20px;
}
.Form-wrap .Box-flame {
  border-color: #80b735;
  box-shadow: none;
}
.Form-tbl {
  width: 100%;
  background: #f4faeb;
}
table {
  border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}

.Btn {
  display: flex;
  position: relative;
  width: 100%;
  border: 2px solid #799b3f;
  border-radius: 8px;
  background: #f1f3ee;
  font-size: 1.8rem;
  font-weight: bold;
  color: #3f5d26 !important;
  text-align: center;
  text-decoration: none !important;
  line-height: 1.2;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
.Btn--back {
  height: 50px;
  padding: 0 0 0 35px;
  box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -9px rgb(0 16 14 / 31%);
}
button {
  cursor: pointer;
}
button,
select {
  text-transform: none;
}
.Btn--back::before {
  left: 0;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 16px;
}
.Btn::before {
  border-radius: 50%;
  background: #3f5d26;
}
.Btn::before,
.Btn::after {
  display: block;
  position: absolute;
  top: 50%;
  content: "";
}
.Btn::after {
  width: 0;
  height: 0;
  border-style: solid;
}
.Btn--back::after {
  left: 0;
  margin: -5px 0 0 22px;
  border-width: 5px 6px 5px 0;
  border-color: transparent #f1f3ee transparent transparent;
}
.Control-area {
  position: relative;
  margin: 104px 0 66px;
  text-align: center;
}
.Control-area::before {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin: -73px 0 0 -109px;
  border-width: 46px 109px 0 109px;
  border-color: #ff9900 transparent transparent transparent;
  border-style: solid;
  content: "";
}
.Control-area__btn {
  padding-top: 18px;
}
.Control-area__btn::before {
  width: 30px;
  height: 30px;
  margin: -15px 0 0 -50px;
  border-radius: 50%;
  background: #fff;
}
.Control-area__btn::before,
.Control-area__btn::after {
  display: block;
  position: absolute;
  top: 50%;
  left: 100%;
  content: "";
}
.Control-area__btn::after {
  width: 0;
  height: 0;
  margin: -8px 0 0 -38px;
  border-style: solid;
  border-width: 8px 0 8px 11px;
  border-color: transparent transparent transparent #ff9900;
}
.Form-tbl__th,
.Form-tbl__td {
  color: #2c4318;
}
@media screen and (min-width: 501px) {
  .Form-tbl__tr-sp {
    display: none;
  }
  .Form-tbl__tr-pc:not(:first-of-type) {
    border-top: 1px dotted #ccc;
  }
  .Form-tbl__th {
    position: relative;
    width: calc(100% - 582px);
    font-weight: normal;
    vertical-align: middle;
    align-items: center;
    letter-spacing: normal;
  }

  .Form-tbl__tr:not(:first-of-type) {
    border-top: 1px dotted #ccc;
  }
  .l-base {
    width: 1040px;
    margin: 0 auto;
  }
  .Form-wrap {
    width: 840px;
    margin: 0 auto;
  }
  .contact-info-table {
    /* border-collapse: separate; */
    border-spacing: 0;
  }
  .contact-info-table tr:first-child > th.Form-tbl__th--confirm {
    border-right: 1px dotted #ccc;
  }
  .contact-info-table tr:nth-child(n + 2) > th,
  .contact-info-table tr:nth-child(n + 2) > td {
    border-top: 1px dotted #ccc;
  }

  .Control-back {
    width: 350px;
    margin: 20px 0 83px;
  }

  .Control-area__btn {
    position: relative;
    width: 580px;
    min-height: 121px;
    padding: 20px 0 11px;
    border: none;
    border-radius: 8px;
    background: #ff9900;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -4px rgb(0 16 14 / 31%);
    color: #fff;
    font-size: 2.4rem;
    line-height: 1;
    transition: all 0.3s ease;
  }

  .Control-area__btn__em {
    display: block;
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.6;
  }
}
@media screen and (max-width: 500px) {
  .Form-tbl__tr-pc {
    display: none;
  }
  .Form-tbl__tr-sp {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .Form-tbl__th--confirm {
    display: none;
  }
  .Form-tbl__tr {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .l-base {
    width: 95%;
    margin: 0 auto;
  }
  .Form-wrap {
    width: 100%;
    margin: 0 auto;
  }

  .Control-back {
    margin: 20px auto 83px auto;
    width: 95%;
  }

  .Control-area__btn {
    display: block;
    position: relative;
    width: 100%;
    padding: 20px 20px 20px 23px;
    border: none;
    border-radius: 8px;
    background: #ff9900;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -8px rgb(0 16 14 / 31%);
    color: #fff;
    font-size: 1.6rem;
    line-height: 1;
  }

  .Control-area__btn__em {
    display: block;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.6;
  }
}
</style>
