export class MyPageCatalogMessages {
  /**
   * 内部コードからメッセージを生成します。
   *
   * @param internalCode
   * @returns
   */
  public static generateMessage(internalCode: string): string {
    switch (internalCode) {
      case "N1":
        return "正常終了。";
      case "E1":
      case "E2":
      case "E3":
        return "メールアドレスをご入力ください。";
      case "E6":
      case "E7":
      case "E8":
      case "E9":
      case "E10":
      case "E11":
      case "E12":
      case "E40":
        return "生年月日をご選択ください。";
      case "E13":
      case "E14":
      case "E15":
        return "お名前の漢字(名)をご入力ください。";
      case "E16":
      case "E17":
      case "E18":
        return "お名前のカタカナ(メイ)をご入力ください。";
      case "E19":
      case "E20":
      case "E21":
        return "お名前の漢字(姓)をご入力ください。";
      case "E22":
      case "E23":
      case "E24":
        return "お名前のカタカナ(セイ)をご入力ください。";
      case "E25":
      case "E26":
        return "性別をご選択ください。";
      case "E27":
      case "E28":
        return "都道府県市区町村をご選択ください。";
      case "E29":
      case "E30":
      case "E41":
        return "番地をご入力ください。";
      case "E31":
      case "E32":
      case "E43":
      case "E44":
        return "郵便番号をご入力後、住所検索を行ってください。";
      case "E33":
      case "E42":
        return "マンション名・部屋番号をご入力ください。";
      case "E34":
      case "E35":
      case "E45":
        return "電話番号をご入力ください。";
      default:
        return "";
    }
  }
}
