import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";
// import axios from "axios";
// import VueElementLoading from "vue-element-loading";

// App.component("VueElementLoading", VueElementLoading);

createApp(App).use(VueCookies).use(store).use(router).mount("#app");
