<template>
  <LeadInputTemplate />
</template>
<script lang="ts">
// Pagesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. (場合によってoganismsに責務を持たせるが)エラーハンドリング用Vuexステータスを操作
// 4. ページ(アプリケーション)全体に及ぶCSSの適用
import { defineComponent } from "vue";
import LeadInputTemplate from "@/components/templates/lead/LeadInputTemplate.vue";
import { mapGetters, mapMutations } from "vuex";
export default defineComponent({
  name: "LeadInput",
  components: {
    LeadInputTemplate,
  },
  methods: {
    ...mapMutations(["setLoadingStatus"]),
  },
  computed: {
    ...mapGetters(["getLoadingStatus"]),
  },
  mounted() {
    if (this.$cookies.get("planSave") === null) {
      this.$router.push("/:pathMatch(.*)*");
    }
    this.setLoadingStatus(true);
    setTimeout(() => {
      this.setLoadingStatus(false);
    }, 1000);
  },
});
</script>
<style scoped></style>
