<template>
  <div class="Intro-area">
    <div class="Intro-area__inner">
      <p class="Intro-area__lead">
        <b>{{ titleHead1 }}<br /></b
        ><strong>{{ titleHead2 }}<br />{{ titleHead3 }}</strong>
      </p>
      <ul class="Intro-list">
        <li class="Intro-list__item">
          {{ listFirstRow1 }}<br />{{ listFirstRow2 }}
        </li>
        <li class="Intro-list__item">
          {{ listSecondRow1 }}<br />{{ listSecondRow2 }}
        </li>
        <li class="Intro-list__item">
          {{ listThirdRow1 }}
        </li>
        <li class="Intro-list__item">
          {{ listFourthRow1 }}<br />{{ listFourthRow2 }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "IntroductionComponent",
  props: {
    titleHead1: { Type: String, default: "" },
    titleHead2: { Type: String, default: "" },
    titleHead3: { Type: String, default: "" },
    listFirstRow1: { Type: String, default: "" },
    listFirstRow2: { Type: String, default: "" },
    listSecondRow1: { Type: String, default: "" },
    listSecondRow2: { Type: String, default: "" },
    listThirdRow1: { Type: String, default: "" },
    listFourthRow1: { Type: String, default: "" },
    listFourthRow2: { Type: String, default: "" },
  },
});
</script>
<style scoped>
*,
::after,
::before {
  box-sizing: border-box;
}
p {
  margin: 0;
}
.Intro-list {
  margin: 0;
  padding-left: 20px;
}
.Intro-list__item {
  margin-top: 10px;
}
::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}
@media screen and (min-width: 501px) {
  .Intro-area {
    background: #f8f5f2;
  }

  .Intro-area__inner {
    background-image: url(../../../assets/img_decoration01.png);
  }
  .Intro-area__inner {
    width: 840px;
    margin: 0 auto;
    padding: 30px 0 30px 290px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: 220px auto;
    font-size: 1.5rem;
  }

  .Intro-area__lead {
    margin-bottom: 40px;
    color: #3b5e10;
    font-size: 2.5rem;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
    line-height: 1.5;
    letter-spacing: -0.01em;
  }
}
@media screen and (max-width: 500px) {
  br {
    display: none;
  }
  .Intro-area__lead strong {
    display: inline-block;
  }

  .Intro-area__inner {
    width: 100%;
    padding: 20px;
    font-size: 1.6rem;
  }

  .Intro-area__lead {
    margin-bottom: 20px;
    color: #3b5e10;
    font-size: 1.8rem;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
    line-height: 1.5;
    letter-spacing: -0.01em;
  }
}
</style>
