<template>
  <div class="body">
    <HeaderComponent pageTitle="見積り内容をメールまたはSMSで送信" />
    <LeadIntroductionOrganism
      introAreaLead1="お見積りの内容を、以下にご入力いただいたメールアドレスもしくは携帯電話番号（SMS）にお送りします。"
      introAreaTxt1="保障内容のご検討や、ご家族とのご相談の際に、ぜひ、お役立てください。"
    />
    <LeadInputOrganism />
    <div class="l-baseCat"><CatalogInquiryOrganism /></div>
    <FooterComponent />
  </div>
</template>
<script lang="ts">
// templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用
import { defineComponent } from "vue";
import HeaderComponent from "@/components/organisms/global/HeaderComponent.vue"; // @ is an alias to /src
import LeadIntroductionOrganism from "@/components/organisms/lead/LeadIntroductionOrganism.vue"; // @ is an alias to /src
import LeadInputOrganism from "@/components/organisms/lead/LeadInputOrganism.vue"; // @ is an alias to /src
import CatalogInquiryOrganism from "@/components/organisms/catalog/CatalogInquiryOrganism.vue"; // @ is an alias to /src
import FooterComponent from "@/components/organisms/global/FooterComponent.vue"; // @ is an alias to /src

export default defineComponent({
  name: "ContactInput",
  components: {
    HeaderComponent,
    LeadIntroductionOrganism,
    LeadInputOrganism,
    CatalogInquiryOrganism,
    FooterComponent,
  },
});
</script>
<style scoped>
.body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Hiragino Sans", Meiryo, sans-serif;
  font-size: 1.6rem;
  color: #333;
  letter-spacing: 0.08em;
  line-height: 1.8;
  letter-spacing: 0.08em;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  min-height: 100vh;
}
@media screen and (min-width: 501px) {
  .l-baseCat {
    width: 1040px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 500px) {
  .l-baseCat {
    padding: 0 10px;
  }
}
</style>
