import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MailDeliveryStopInputPage from "../components/pages/maildelivery/MailDeliveryStopInputPage.vue";
import MailDeliveryStopCompleatePage from "../components/pages/maildelivery/MailDeliveryStopCompleatePage.vue";
import CatalogInputPage from "../components/pages/catalog/CatalogInputPage.vue";
import CatalogConfirmPage from "../components/pages/catalog/CatalogConfirmPage.vue";
import CatalogCompletePage from "../components/pages/catalog/CatalogCompletePage.vue";
import LeadInputPage from "../components/pages/lead/LeadInputPage.vue";
import LeadSMSCompletePage from "../components/pages/lead/LeadSMSCompletePage.vue";
import LeadMailCompletePage from "../components/pages/lead/LeadMailCompletePage.vue";
import ReserveInputPage from "../components/pages/reserve/ReserveInputPage.vue";
import ReserveCompletePage from "../components/pages/reserve/ReserveCompletePage.vue";
import { MeasurementToolExecution } from "@/router/marketing/MeasurementToolExecution";

const routes: Array<RouteRecordRaw> = [
  // メール配信停止画面
  {
    path: "/mail_delivery/",
    name: "MailDeliveryStopInputPage",
    component: MailDeliveryStopInputPage,
    meta: { title: "メール配信停止手続き | ライフネット生命保険" },
  },
  {
    path: "/mail_delivery/completion",
    name: "MailDeliveryStopCompleatePage",
    component: MailDeliveryStopCompleatePage,
    meta: { title: "メール配信停止完了 | ライフネット生命保険" },
  },
  // 資料請求画面
  {
    path: "/catalog/",
    name: "CatalogInputPage",
    component: CatalogInputPage,
    meta: {
      title: "資料請求（お客さま情報の入力） | ライフネット生命保険",
    },
  },
  // 資料請求確認画面
  {
    path: "/catalog/confirm",
    name: "CatalogConfirmPage",
    component: CatalogConfirmPage,
    meta: { title: "資料請求（入力内容のご確認） | ライフネット生命保険" },
  },
  // 資料請求完了画面
  {
    path: "/catalog/complete",
    name: "CatalogCompletePage",
    component: CatalogCompletePage,
    meta: { title: "資料請求（受付完了） | ライフネット生命保険" },
  },
  // 見積メール保存入力画面
  {
    path: "/lead/",
    name: "LeadInputPage",
    component: LeadInputPage,
    meta: { title: "見積り内容をメールまたはSMSで送信 | ライフネット生命保険" },
  },
  // 見積メール保存SMS完了画面
  {
    path: "/lead/complete_sms",
    name: "LeadSMSCompletePage",
    component: LeadSMSCompletePage,
    meta: {
      title: "見積り内容をSMSで送信（送信完了） | ライフネット生命保険",
    },
  },
  // 見積メール保存メール完了画面
  {
    path: "/lead/complete_mail",
    name: "LeadMailCompletePage",
    component: LeadMailCompletePage,
    meta: {
      title: "見積り内容をメールで送信（送信完了） | ライフネット生命保険",
    },
  },
  // 保険相談予約入力画面
  {
    path: "/reserve/",
    name: "ReserveInputPage",
    component: ReserveInputPage,
    meta: {
      title: "電話＆オンラインでの保険相談（無料）予約 | ライフネット生命保険",
    },
  },
  // 保険相談予約完了画面
  {
    path: "/reserve/complete",
    name: "ReserveCompletePage",
    component: ReserveCompletePage,
    meta: {
      title:
        "電話＆オンラインでの保険相談（無料）予約完了 | ライフネット生命保険",
    },
  },
  // 設定外のルーティングは404ページへ飛ばす
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () =>
      (window.location.href =
        "https://www.lifenet-seimei.co.jp/error/404.html"),
    meta: {
      title: "ライフネット生命",
    },
  },
];
// ルーティング時にページ最上部を表示する処理
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0,
    };
  },
});

// 画面コンポーネントが読み込まれる前の処理
router.beforeEach((to, from, next) => {
  // 各入力画面遷移時にエンドポイントの後ろに/を付ける
  if (
    to.path === "/catalog" ||
    to.path === "/lead" ||
    to.path === "/reserve" ||
    to.path === "/mail_delivery"
  ) {
    next({ path: to.path + "/", query: to.query });
  } else {
    next();
  }
});

// 画面コンポーネントが読み込まれた後の処理
router.afterEach((to, from) => {
  // ページタイトルを設定する処理
  document.title = to.meta.title as string;
  // GTMの処理
  window.dataLayer.push({ event: "PageView" });

  // AAの処理
  if (to.path === "/catalog/complete") {
    // 資料請求完了画面のAA計測を実施
    MeasurementToolExecution.executeCatalogCompleteMeasurement();
  } else if (to.path === "/reserve/complete") {
    // 保険相談予約完了画面のAA計測を実施
    MeasurementToolExecution.executeReserveCompleteMeasurement();
  } else if (
    to.path === "/lead/complete_mail" ||
    to.path === "/lead/complete_sms"
  ) {
    // 見積メール保存完了画面のAA計測を実施
    MeasurementToolExecution.executeLeadCompleteMeasurement();
  } else {
    // AAのタグロードを行う
    window.s.view();
  }
});

export default router;
