<template>
  <div class="longInputBox">
    <div class="longInputBox longInputBox--w03">
      <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target!.value)"
        :class="className"
        :type="inputType"
        :placeholder="placeHolder"
        :maxlength="maxLength"
      />
    </div>
  </div>
  <p class="error-text">{{ errorText }}</p>
</template>

<script lang="ts">
// 1. modulesから呼ばれるこれ以上切り分けることのできない最小限の画面項目を定義する
// 2. 基本的には再利用される画面項目に関してをatomsに切り出して、処理や画面の動きを一定化する目的がある
// 3. atomsコンポーネントは外部から再利用されやすく、親コンポーネントを意識しない様に作る
//    例えば入力フォームだが、予めバリデーションやプレースホルダをpropsとして定義しておく等
import { defineComponent } from "vue";

/**
 * data()の返り値を指定
 */
interface DataType {
  // テキストボックスのClass名
  className: string;
  // エラーテキスト
  errorText: string;
  // テキストボックスのタイプ
  inputType: string;
}
export default defineComponent({
  name: "HelloWorld",
  props: {
    // テキストボックスのタイプ
    type: { type: String, default: "" },
    // フォームのタイトル
    formTitle: { type: String, default: "" },
    // テキストボックスのプレースホルダ
    placeHolder: { type: String, default: "" },
    // テキストボックス内の入力値をコンポーネント間でバインディングするための変数
    modelValue: { type: String, default: "" },
    // テキストボックスの最大入力値
    maxLength: { type: Number, default: 1 },
    // true : 必須入力 false : 必須でない入力
    required: { type: Boolean, default: false },
    // メールアドレス形式の入力
    email: { type: Boolean, default: false },
    // 電話番号形式の入力
    telNumber: { type: Boolean, default: false },
    // 郵便番号形式の入力
    postNumber: { type: Boolean, default: false },
  },
  data(): DataType {
    return {
      className: "input-form",
      errorText: "",
      inputType: this.type,
    };
  },
  methods: {
    /**
     * バリデーションメソッド
     * ・propsの情報を元に必要なバリデーションを行う
     * ・バリデーションエラーの場合、エラーメッセージを表示します
     *
     * @return void
     */
    validate() {
      // 必須チェック
      if (!this.modelValue && this.required) {
        this.className = "error-form";
        this.errorText = this.formTitle + " は必須入力です。";
        return;
      }
      // 文字数チェック
      if (this.maxLength < this.modelValue?.length) {
        this.className = "error-form";
        this.errorText =
          this.formTitle +
          " は" +
          this.maxLength +
          "文字以内で入力してください。";
        return;
      }
      // メールアドレス形式
      const pattern =
        /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
      if (this.email && !pattern.test(this.modelValue)) {
        this.className = "error-form";
        this.errorText =
          this.formTitle + " はメールアドレス形式で入力してください。";
        return;
      }
      // 郵便番号チェック
      if (this.postNumber && this.modelValue.length === 7) {
        this.className = "error-form";
        this.errorText = this.formTitle + " は郵便番号形式で入力してください。";
        return;
      }

      // 電話番号(ハイフンなし)チェック
      const telNumber = /^0\d{9,10}$/;
      // 電話番号チェック
      if (this.telNumber && telNumber.test(this.modelValue)) {
        this.className = "error-form";
        this.errorText = this.formTitle + " は電話番号形式で入力してください。";
        return;
      }
      this.className = "input-form";
      this.errorText = "";
    },
  },
  computed: {},
  watch: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.error-text {
  color: red;
  font-size: 11px;
  text-align: left;
}
@media screen and (min-width: 501px) {
  .longInputBox {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .input-form {
    width: 532px;
    min-height: 46px;
    margin: 9px 0;
    padding: 0 18px;
    border: 1px solid #3f5d26;
    border-radius: 4px;
    font-size: inherit;
  }

  .error-form {
    border: 2px solid red;
    background-color: rgb(255, 205, 205);
    width: 532px;
    min-height: 46px;
    margin: 9px 0;
    padding: 0 18px;
    border-radius: 4px;
  }
}

@media screen and (max-width: 500px) {
  .longInputBox {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .input-form {
    width: 100%;
    min-height: 46px;
    margin: 9px 0;
    padding: 0 18px;
    border: 1px solid #3f5d26;
    border-radius: 4px;
    font-size: inherit;
  }

  .error-form {
    border: 2px solid red;
    background-color: rgb(255, 205, 205);
    width: 100%;
    min-height: 46px;
    margin: 9px 0;
    padding: 0 18px;
    border-radius: 4px;
  }
}
</style>
