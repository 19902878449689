import { ValidationResult } from "@/interface/common/CommonValidatersInterface";
import { InputParam } from "@/interface/lead/LeadInputInterFaces";
import { Messages } from "../message/LeadMessages";
import { LeadInternalCodeEnum } from "../message/num/LeadInternalCodeEnum";
import { CommonValidater } from "./common/CommonValidaters";

export class Validater {
  private static LeadMessages = new Messages();
  private static internalErrorCodeType = "lead";

  /**
   * バリデーションチェックを行います。
   * ・バリデーションエラーになった場合falseを返却し、alertメッセージを表示する
   *
   * @return {boolean}
   */
  public static validateLead(leadInput: InputParam): Array<ValidationResult> {
    const notExistCheckResult = this.isExistEmailOrSms(
      leadInput.mailSmsRecipient
    );
    if (notExistCheckResult.length != 0) return notExistCheckResult;
    // メールアドレスのチェック
    if (
      leadInput.mailSmsRecipient.match(
        /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
      )
    ) {
      return CommonValidater.validateEmail(
        leadInput.mailSmsRecipient,
        this.LeadMessages,
        this.internalErrorCodeType
      );
    }
    return this.validateSms(leadInput.mailSmsRecipient);
  }

  public static isExistEmailOrSms(
    mailSmsRecipient: string
  ): Array<ValidationResult> {
    const validationResultList = new Array<ValidationResult>();
    // 必須チェック
    if (!mailSmsRecipient) {
      validationResultList.push({
        inputName: "input_email",
        internalCode: LeadInternalCodeEnum.MailSmsRecipientEmptyError,
        isSuccess: false,
        message: this.LeadMessages.generateMessage(
          LeadInternalCodeEnum.MailSmsRecipientEmptyError
        ),
      });
    }
    return validationResultList;
  }

  /**
   *
   * @param mailSmsRecipient SMS番号
   * @returns SMS番号バリデーション結果
   */
  public static validateSms(mailSmsRecipient: string): Array<ValidationResult> {
    const validationResultList = new Array<ValidationResult>();
    if (
      !mailSmsRecipient.match(/^(070|080|090)\d{8}$/) ||
      mailSmsRecipient.length !== 11
    ) {
      validationResultList.push({
        inputName: "input_email",
        internalCode: LeadInternalCodeEnum.MailSmsRecipientFormatError,
        isSuccess: false,
        message: this.LeadMessages.generateMessage(
          LeadInternalCodeEnum.MailSmsRecipientFormatError
        ),
      });
    }
    return validationResultList;
  }
}
