<template>
  <header class="Header">
    <div class="Header__inner">
      <p class="Header__logo">
        <a href="https://www.lifenet-seimei.co.jp/"
          ><img src="../../../assets/header_logo.png" alt="ライフネット生命"
        /></a>
      </p>
    </div>
    <div class="Header__contact">
      <div class="Header__inner">
        <p class="Header__txt">お電話でのお問い合わせ、ご相談</p>
        <p class="Header__tel">
          <img
            src="../../../assets/ico_tel.svg"
            alt="フリーダイヤル"
            width="42"
            height="27"
          /><span class="Header__num">0120-205566</span
          >（通話無料、携帯・PHSもOK！）
        </p>
        <p>
          受付時間:平日9時～20時、土日祝9時～18時（年末年始除く）<br />日・祝は保険相談のみの受付となります
        </p>
      </div>
    </div>
    <div class="service-name">
      <h1 class="Page-ttl">
        <p>{{ pageTitle }}</p>
      </h1>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HeaderComponent",
  props: {
    // テキストボックスのタイプ
    pageTitle: { pageTitle: String, default: "" },
  },
});
</script>
<style scoped>
.Header__tel {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
}
.Header__num {
  margin: 3px 0 0 10px;
  font-weight: bold;
  font-size: 2.6rem;
  line-height: 1;
}
@media screen and (min-width: 1140px) {
  .Header {
    background: #fff;
    font-size: 1.1rem;
  }
  .Header__inner {
    display: flex;
    width: 1040px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
  }
  .Header__contact {
    height: 56px;
    padding-top: 8px;
    border: 1px solid #dedfde;
    border-width: 1px 0;
    background: #f7f3f7;
    letter-spacing: normal;
  }
  .Header__txt {
    font-size: 1.6rem;
  }
  .Header__logo img {
    width: 295px;
    height: 88px;
  }

  .service-name {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid #93b829;
    padding: 0 20px 0 20px;
  }

  .Page-ttl {
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
    font-size: 3.6rem;
    font-weight: bold;
    color: #3b5e10;
    line-height: 1;
    letter-spacing: normal;
    align-items: center;
    width: 1040px;
  }

  .Page-ttl::before {
    width: 6px;
    height: 100%;
    min-height: 48px;
    margin-right: 24px;
    background: #3b5e10;
    content: "";
  }
}

@media screen and (max-width: 1139px) {
  .Header {
    font-size: 1.1rem;
    width: 100%;
  }
  .Header__inner {
    display: flex;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 20px;
  }
  .Header__contact {
    height: 56px;
    padding-top: 8px;
    border: 1px solid #dedfde;
    border-width: 1px 0;
    background: #f7f3f7;
    letter-spacing: normal;
  }
  .Header__logo img {
    width: 295px;
    height: 88px;
  }
  .Header__txt {
    font-size: 1.6rem;
  }

  .service-name {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid #93b829;
    padding: 0 20px 0 20px;
  }

  .Page-ttl {
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
    font-size: 3.6rem;
    font-weight: bold;
    color: #3b5e10;
    line-height: 1;
    letter-spacing: normal;
    align-items: center;
    width: 1040px;
  }

  .Page-ttl::before {
    width: 6px;
    height: 100%;
    min-height: 48px;
    margin-right: 24px;
    background: #3b5e10;
    content: "";
  }
}

@media screen and (max-width: 500px) {
  .Header {
    background: #fff;
    font-size: 1.1rem;
    width: 100%;
  }
  .Header__inner {
    display: flex;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 10px;
  }
  .Header__contact {
    display: none;
  }
  .Header__logo img {
    width: 170px;
    height: 50px;
  }

  .service-name {
    width: 100%;
    text-align: left;
    background-color: rgb(255, 255, 255);
    border-top: 1px solid #93b829;
    border-bottom: 1px solid #93b829;
    padding: 0 10px 0 10px;
  }

  .Page-ttl {
    position: relative;
    padding: 3px 0 0 16px;
    font-size: 2.4rem;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
    font-weight: bold;
    color: #3b5e10;
    line-height: 1.4;
  }
  .Page-ttl::before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    min-height: 100%;
    background: #3b5e10;
    content: "";
    margin: 0;
  }
}
</style>
