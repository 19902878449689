import { RequestParam } from "@/interface/lead/LeadInputInterFaces";

const state = {
  requestParam: {
    estimateYear: "",
    estimateMonth: "",
    estimateDay: "",
    birthYear: "",
    birthMonth: "",
    birthDay: "",
    contractAge: "",
    sex: "",
    lifeActivator: "",
    insurance: "",
    term: "",
    lifePrice: "",
    treatmentActivator: "",
    tmtTerm: "",
    treatmentPrice: "",
    medical3Activator: "",
    medical3Admission: "",
    medical3Payment: "",
    medical3Course: "",
    medical3Price: "",
    disability3Activator: "",
    disability3Benefit: "",
    disability3Term: "",
    disability3Billable: "",
    disability3Option: "",
    disability3Return: "",
    disability3Price: "",
    cancerActivator: "",
    cancerBenefit: "",
    cancerCourse: "",
    cancerPrice: "",
    totalPrice: "",
    wishMail: "",
    mailSmsRecipient: "",
    siteCatalystId: "",
    karteUserId: "",
  } as RequestParam,
  estimateSiteUrl: "" as string,
  contractKey: "" as string,
};
const getters = {
  getEstimateSiteUrl: (state: { estimateSiteUrl: string }): string => {
    return state.estimateSiteUrl;
  },
  getContractKey: (state: { contractKey: string }): string => {
    return state.contractKey;
  },
  getRequestParam: (state: { requestParam: RequestParam }): RequestParam => {
    return state.requestParam;
  },
};
const mutations = {
  setEstimateSiteUrl: (
    state: { estimateSiteUrl: string },
    estimateSiteUrl: string
  ) => {
    state.estimateSiteUrl = estimateSiteUrl;
  },
  setContractKey: (state: { contractKey: string }, contractKey: string) => {
    state.contractKey = contractKey;
  },
  setRequestParam: (
    state: { requestParam: RequestParam },
    requestParam: RequestParam
  ) => {
    state.requestParam = requestParam;
  },
};
const actions = {};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
