/**
 * 内部コードを列挙します
 */
export enum LeadInternalCodeEnum {
  // 正常終了
  Successful = "N1",
  // メールアドレス形式エラー
  MailSmsRecipientFormatError = "E1",
  // メールアドレス未入力エラー
  MailSmsRecipientEmptyError = "E2",
  // お役立ち情報配信希望有無エラー
  WishEmailInputError = "E3",
  // メソッド形式エラー
  MethodFormatError = "E4",
  // 画面遷移用パラメータ形式エラー
  PrePageInfoFormatError = "E5",
  // 見積り年未入力エラー
  EstimateYearEmptyError = "E6",
  // 見積り年形式エラー
  EstimateYearFormatError = "E7",
  // 見積り年文字数制限エラー
  EstimateYearCountOverError = "E8",
  // 見積り年範囲制限エラー
  EstimateYearOutOfRangeError = "E9",
  // 見積り月未入力エラー
  EstimateMonthEmptyError = "E10",
  // 見積り月形式エラー
  EstimateMonthFormatError = "E11",
  // 見積り月文字数制限エラー
  EstimateMonthCountOverError = "E12",
  // 見積り月範囲制限エラー
  EstimateMonthIsOutOfRangeError = "E13",
  // 見積り日未入力エラー
  EstimateDayEmptyError = "E14",
  // 見積り日形式エラー
  EstimateDayFormatError = "E15",
  // 見積り日字数制限エラー
  EstimateDayCountOverError = "E16",
  // 見積り日範囲制限エラー
  EstimateDayOutOfRangeError = "E17",
  // 誕生年未入力エラー
  BirthYearEmptyError = "E18",
  // 誕生年形式エラー
  BirthYearFormatError = "E19",
  // 誕生年範囲制限エラー
  BirthYearOutOfRangeError = "E20",
  // 誕生月未入力エラー
  BirthMonthEmptyError = "E21",
  // 誕生月形式エラー
  BirthMonthFormatError = "E22",
  // 誕生月範囲制限エラー
  BirthMonthOutOfRangeError = "E23",
  // 誕生日未入力エラー
  BirthDayEmptyError = "E24",
  // 誕生日形式エラー
  BirthDayFormatError = "E25",
  // 誕生日範囲制限エラー
  BirthDayOutOfRangeError = "E26",
  // 契約年齢未入力エラー
  ContractAgeEmptyError = "E27",
  // 契約年齢形式エラー
  ContractAgeFormatError = "E28",
  // 契約年齢範囲制限エラー
  ContractAgeOutOfRangeError = "E29",
  // 性別入力エラー
  SexInputError = "E30",
  // 保険商品選択（定期死亡保険）未入力エラー
  InsuranceEmptyError = "E31",
  // 保険商品選択（定期死亡保険）形式エラー
  InsuranceFormatError = "E32",
  // 保険商品選択（定期死亡保険）文字数制限エラー
  InsuranceCountOverError = "E33",
  // 保険商品選択（定期死亡保険）範囲制限エラー
  InsuranceOutOfRangeError = "E34",
  // 保険期間（定期死亡保険）入力エラー
  TermInputError = "E35",
  // 月額保険料（定期死亡保険）未入力エラー
  LifePriceEmptyError = "E36",
  // 月額保険料（定期死亡保険）形式エラー
  LifePriceFormatError = "E37",
  // 月額保険料（定期死亡保険）範囲制限エラー
  LifePriceOutOfRangeError = "E38",
  // 保険期間（定期療養保険）入力エラー
  TreatmentPriceInputError = "E39",
  // 保険期間（定期療養保険）未入力エラー
  TreatmentPriceEmptyError = "E40",
  // 保険期間（定期療養保険）形式エラー
  TreatmentPriceFormatError = "E41",
  // 保険期間（定期療養保険）範囲制限エラー
  TreatmentPriceOutOfRangeError = "E42",
  // 入院給付金日額（終身医療保険（じぶんへの保険3））入力エラー
  Medical3AdmissionInputError = "E43",
  // 保険料払込期間（終身医療保険（じぶんへの保険3））入力エラー
  Medical3PaymentInputError = "E44",
  // 販売コース（終身医療保険（じぶんへの保険3））入力エラー
  Medical3CourseInputError = "E45",
  // 月額保険料（終身医療保険（じぶんへの保険3））未入力エラー
  Medical3PriceEmptyError = "E46",
  // 月額保険料（終身医療保険（じぶんへの保険3））形式エラー
  Medical3PriceFormatError = "E47",
  // 月額保険料（終身医療保険（じぶんへの保険3））文字数制限エラー
  Medical3PriceCountOverError = "E48",
  // 月額保険料（終身医療保険（じぶんへの保険3））範囲制限エラー
  Medical3PriceOutOfRangeError = "E49",
  // 就業不能給付金月額（就業不能保険（働く人の保険3））未入力エラー
  Disability3BenefitEmptyError = "E50",
  // 就業不能給付金月額（就業不能保険（働く人の保険3））形式エラー
  Disability3BenefitFormatError = "E51",
  // 就業不能給付金月額（就業不能保険（働く人の保険3））文字数制限エラー
  Disability3BenefitCountOverError = "E52",
  // 就業不能給付金月額（就業不能保険（働く人の保険3））範囲制限エラー
  Disability3BenefitOutOfRangeError = "E53",
  // 保険期間（就業不能保険（働く人の保険3））入力エラー
  Disability3TermInputError = "E54",
  // 免責期間（就業不能保険（働く人の保険3））入力エラー
  Disability3BillableInputError = "E55",
  // 初期削減給付有無（就業不能保険（働く人の保険3））入力エラー
  Disability3OptionInputError = "E56",
  // 復帰支援一時金特則有無（就業不能保険（働く人の保険3））入力エラー
  Disability3ReturnInputError = "E57",
  // 月額保険料（就業不能保険（働く人の保険3））未入力エラー
  Disability3PriceEmptyError = "E58",
  // 月額保険料（就業不能保険（働く人の保険3））形式エラー
  Disability3PriceFormatError = "E59",
  // 月額保険料（就業不能保険（働く人の保険3））範囲制限エラー
  Disability3PriceOutOfRangeError = "E60",
  // がん診断一時金給付金額（がん保険）入力エラー
  CancerBenefitInputError = "E61",
  // 販売コース（がん保険）入力エラー
  CancerCourseInputError = "E62",
  // 月額保険料（がん保険）未入力エラー
  CancerPriceEmptyError = "E63",
  // 月額保険料（がん保険）形式エラー
  CancerPriceFormatError = "E64",
  // 月額保険料（がん保険）範囲制限エラー
  CancerPriceOutOfRangeError = "E65",
  // 合計保険料未入力エラー
  TotalPriceEmptyError = "E66",
  // 合計保険料形式エラー
  TotalPriceFormatError = "E67",
  // 合計保険料範囲制限エラー
  TotalPriceOutOfRangeError = "E68",
  // 認知症保険選択時、既存商品選択エラー
  DementiaIsNotOnlyError = "E69",
  // 販売コース（認知症保険）入力エラー
  DementiaCourseInputError = "E70",
  // 認知症診断一時金給付金額入力エラー
  DementiaBenefitInputError = "E71",
  // 月額保険料（認知症保険）未入力エラー
  DementiaPriceEmptyError = "E72",
  // 月額保険料（認知症保険）形式エラー
  DementiaPriceFormatError = "E73",
  // 月額保険料（認知症保険）範囲制限エラー
  DementiaPriceOutOfRangeError = "E74",
  // 見積り画面URL未入力エラー
  EstimateSiteUrlEmptyError = "E75",
  // データベース接続エラー
  DynamoDbConnectionFailed = "E101",
  // サーバエラー
  InternalServerError = "E102",

  // FamilyNameEmptyError = "",
  // FamilyNameFormatError = "",
  // FamilyNameCountOverError = "",
  // FirstNameEmptyError = "",
}
