/**
 * 内部コードを列挙します
 */
export enum CatalogInternalCodeEnum {
  // 正常終了
  Successful = "N1",
  // メールアドレス形式エラー
  MailAddressFormatError = "E1",
  // メールアドレス未入力エラー
  MailAddressEmptyError = "E2",
  // メールアドレス文字数制限エラー
  MailAddressCountOverError = "E3",
  // 保険検討のきっかけ入力値エラー
  MullInputError = "E4",
  // 気になる項目入力値エラー
  ConcernInputError = "E5",
  // 生年月日(年)範囲制限エラー
  BirthYearOutOfRangeError = "E6",
  // 生年月日(年)未入力エラー
  BirthYearEmptyError = "E7",
  // 生年月日(月)範囲制限エラー
  BirthMonthOutOfRangeError = "E8",
  // 生年月日(月)未入力エラー
  BirthMonthEmptyError = "E9",
  // 生年月日(日)範囲制限エラー
  BirthDayOutOfRangeError = "E10",
  // 生年月日(日)未入力エラー
  BirthDayEmptyError = "E11",
  // 生年月日入力値エラー
  BirthDateInputError = "E12",
  // お名前(名)形式エラー
  FirstNameFormatError = "E13",
  // お名前(名)未入力エラー
  FirstNameEmptyError = "E14",
  // お名前(名)制限文字数超過エラー
  FirstNameCountOverError = "E15",
  // お名前(メイ)形式エラー
  FirstNameKanaFormatError = "E16",
  // お名前(メイ)未入力エラー
  FirstNameKanaEmptyError = "E17",
  // お名前(メイ)制限文字数超過エラー
  FirstNameKanaCountOverError = "E18",
  // お名前(姓)形式エラー
  FamilyNameFormatError = "E19",
  // お名前(姓)未入力エラー
  FamilyNameEmptyError = "E20",
  // お名前(姓)制限文字数超過エラー
  FamilyNameCountOverError = "E21",
  // お名前(セイ)形式エラー
  FamilyNameKanaFormatError = "E22",
  // お名前(セイ)未入力エラー
  FamilyNameKanaEmptyError = "E23",
  // お名前(セイ)制限文字数超過エラー
  FamilyNameKanaCountOverError = "E24",
  // 性別未入力エラー
  InsuredPersonGenderEmptyError = "E25",
  // 性別入力値エラー
  InsuredPersonGenderInputError = "E26",
  // 都道府県市区町村未入力エラー
  CityNameEmptyError = "E27",
  // 都道府県市区町村制限文字数超過エラー
  CityNameCountOverError = "E28",
  // 番地未入力エラー
  BlockNumberEmptyError = "E29",
  // 番地制限制限文字数超過エラー
  BlockNumberCountOverError = "E30",
  // 番地形式エラー
  BlockNumberFormatError = "E41",
  // 郵便番号未入力エラー
  ZipcodeEmptyError = "E31",
  // 郵便番号文字数エラー
  ZipcodeCountError = "E32",
  // マンション名・部屋番号制限文字数超過エラー
  BuildingNameCountOverError = "E33",
  // マンション名・部屋番号形式エラー
  BuildingNameFormatError = "E42",
  // 電話番号未入力エラー
  PrimaryPhoneEmptyError = "E34",
  // 電話番号制限文字数超過エラー
  PrimaryPhoneCountOverError = "E35",
  // 18歳未満のお子さまの有無入力値エラー
  ChildNumberInputError = "E36",
  // 就業状況入力値エラー
  WorkingStyleInputError = "E37",
  // お役立ち情報配信希望有無入力値エラー
  WishEmailInputError = "E38",
  // 家族構成入力値エラー
  MaritalStatusInputError = "E39",
  // 年齢制限エラー
  AgeRestrictedError = "E40",
  // 住所未検索エラー
  CityNameCheckError = "E43",
  // 住所検索エラー
  CityNameSearchError = "E44",
  // 0始まりではない電話番号形式エラー
  PrimaryPhoneFormatError = "E45",
  // データベース接続エラー
  DynamoDbConnectionFailed = "E101",
  // サーバエラー
  InternalServerError = "E102",
}
