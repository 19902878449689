import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_CatalogIntroductionCompleteOrganism = _resolveComponent("CatalogIntroductionCompleteOrganism")!
  const _component_CatalogGuidanceCompleteOrganism = _resolveComponent("CatalogGuidanceCompleteOrganism")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderComponent, { pageTitle: "資料請求（受付完了）" }),
    _createVNode(_component_CatalogIntroductionCompleteOrganism),
    _createVNode(_component_CatalogGuidanceCompleteOrganism),
    _createVNode(_component_FooterComponent)
  ], 64))
}