import { AbstractMessages } from "./AbstractMessages";

export class Messages extends AbstractMessages {
  private static readonly N1 = "正常終了。";
  private static readonly E1 = "メールアドレス形式が違います。";
  private static readonly E2 = "メールアドレスが未入力です。";
  private static readonly E3 = "メールアドレスの文字数は80文字以内です。";
  private static readonly E4 = "データベースの項目が取得できませんでした";
  private static readonly E101 =
    "サーバー内部でエラーが発生しました、時間を空けて再度リクエストしてください。";
  private static readonly E102 =
    "サーバー内部でエラーが発生しました、時間を空けて再度リクエストしてください。";
  /**
   * 内部コードからメッセージを生成します。
   *
   * @param internalCode
   * @returns
   */
  generateMessage(internalCode: string): string {
    switch (internalCode) {
      case "N1":
        return Messages.N1;
      case "E1":
        return Messages.E1;
      case "E2":
        return Messages.E2;
      case "E3":
        return Messages.E3;
      case "E4":
        return Messages.E4;
      case "E101":
        return Messages.E101;
      case "E102":
        return Messages.E102;
      default:
        return "";
    }
  }
}
