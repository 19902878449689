<template>
  <body>
    <MailStopInputTemplate />
  </body>
</template>
<script lang="ts">
// Pagesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. (場合によってoganismsに責務を持たせるが)エラーハンドリング用Vuexステータスを操作
// 4. ページ(アプリケーション)全体に及ぶCSSの適用
import { defineComponent } from "vue";
import MailStopInputTemplate from "@/components/templates/maildelivery/MailDeliveryStopInputTemplate.vue"; // @ is an alias to /src
import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  name: "MailStopInputPage",
  components: {
    MailStopInputTemplate,
  },
  methods: {
    ...mapMutations(["setLoadingStatus"]),
  },
  computed: {
    ...mapGetters(["getLoadingStatus"]),
  },
  mounted() {
    this.setLoadingStatus(true);
    setTimeout(() => {
      this.setLoadingStatus(false);
    }, 1000);
  },
});
</script>
<style scoped></style>
