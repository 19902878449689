<template>
  <vue-element-loading
    :active="getLoadingStatus"
    spinner="bar-fade-scale"
    color="#6e9814"
    text="Please wait..."
    is-full-screen
  />
  <router-view />
</template>
<script lang="ts">
// 1. ルーティング処理によるrouter-view(Pagesコンポーネント)の表示
// 2. ページ全体に及ぶCSSの適用
import { defineComponent } from "vue";
import VueElementLoading from "vue-element-loading";
import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  name: "App",
  components: {
    VueElementLoading,
  },
  methods: {
    setViewport() {
      const changeWindowSizeSP = 500;
      const changeWindowSizePCTablet = 1200;
      const changeWindowSizeTablet = 1000;
      const responsiveBrakePointSP = 501;

      let viewportContent =
        "width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0";
      if (window.innerWidth < changeWindowSizeSP) {
        viewportContent = "width=375,user-scalable=no,viewport-fit=cover";
      } else if (
        window.innerWidth < changeWindowSizePCTablet &&
        window.innerWidth >= changeWindowSizeTablet
      ) {
        viewportContent = "width=1200,viewport-fit=cover";
      } else if (
        window.innerWidth < changeWindowSizeTablet &&
        window.innerWidth >= responsiveBrakePointSP
      ) {
        viewportContent = "width=1139,viewport-fit=cover";
      } else if (window.innerWidth >= changeWindowSizePCTablet) {
        viewportContent = "width=1500,viewport-fit=cover";
      }

      document
        .querySelector("meta[name='viewport']")!
        .setAttribute("content", viewportContent);
    },
  },
  created() {
    this.setViewport();
  },
  computed: {
    ...mapGetters(["getLoadingStatus"]),
  },
});
</script>
<style>
*,
::after,
::before {
  box-sizing: border-box;
}
body {
  min-width: 1040px;
  background: #fafafa;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Hiragino Sans", Meiryo, sans-serif;
  font-size: 1.6rem;
  color: #333;
  letter-spacing: 0.08em;
  line-height: 1.8;
  letter-spacing: 0.08em;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  font-size: 62.5%;
}

button {
  cursor: pointer;
}

p {
  margin: 0;
}

@media screen and (max-width: 500px) {
  body {
    min-width: 100%;
    background: #fafafa;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
    font-size: 1.6rem;
    color: #333;
    letter-spacing: 0.08em;
    line-height: 1.8;
    letter-spacing: 0.08em;
    /* -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
    margin: 0;
    padding: 0;
  }
}
</style>
