<template>
  <CatalogConfirmTemplate />
</template>
<script lang="ts">
// Pagesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. (場合によってoganismsに責務を持たせるが)エラーハンドリング用Vuexステータスを操作
// 4. ページ(アプリケーション)全体に及ぶCSSの適用
import { defineComponent } from "vue";
import CatalogConfirmTemplate from "@/components/templates/catalog/CatalogConfirmTemplate.vue";
import { mapGetters, mapMutations } from "vuex";
export default defineComponent({
  name: "CatalogConfirmPage",
  components: {
    CatalogConfirmTemplate,
  },
  methods: {
    ...mapMutations([
      "setLoadingStatus",
      "setIsCatalogConfirmed",
      "setReturnFromConfirm",
    ]),
    ...mapGetters(["getIsCatalogConfirmed"]),

    goToErrorPage() {
      this.$router.push("/:pathMatch(.*)*");
    },
  },
  computed: {
    ...mapGetters(["getLoadingStatus"]),
  },
  mounted() {
    this.setLoadingStatus(true);
    setTimeout(() => {
      this.setLoadingStatus(false);
    }, 1000);

    if (!this.getIsCatalogConfirmed()) {
      this.goToErrorPage();
    }
    this.setIsCatalogConfirmed(false);
  },
});
</script>
<style scoped></style>
