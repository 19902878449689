import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67bfa0f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "l-baseCat" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_CatalogIntroductionOrganism = _resolveComponent("CatalogIntroductionOrganism")!
  const _component_CatalogInputOrganism = _resolveComponent("CatalogInputOrganism")!
  const _component_CatalogInquiryOrganism = _resolveComponent("CatalogInquiryOrganism")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderComponent, { pageTitle: "資料請求" }),
    _createVNode(_component_CatalogIntroductionOrganism, {
      introAreaLead1: "保険選びが楽になる",
      introAreaLead2: "パンフレットをお届けします！",
      threeMinutes: "3分",
      easyInput: "でカンタン入力！資料請求をはじめる",
      blockTitle: "お客さま情報をご入力ください",
      encryptionCommunication: "お客さまの入力情報は、暗号化通信により守られております。"
    }),
    _createVNode(_component_CatalogInputOrganism),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CatalogInquiryOrganism)
    ]),
    _createVNode(_component_FooterComponent)
  ]))
}