<template>
  <div class="body">
    <HeaderComponent pageTitle="メール配信停止" />
    <MailDeliveryStopOganism />
    <div class="footer-copy">
      <small
        >Copyright© 2023 LIFENET INSURANCE COMPANY All rights reserved.
      </small>
    </div>
  </div>
</template>
<script lang="ts">
// templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用
import { defineComponent } from "vue";
import HeaderComponent from "@/components/organisms/global/HeaderComponent.vue"; // @ is an alias to /src
import MailDeliveryStopOganism from "@/components/organisms/maildelivery/MailDeliveryStopOganism.vue"; // @ is an alias to /src

export default defineComponent({
  name: "MailStopInputTemplate",
  components: {
    HeaderComponent,
    MailDeliveryStopOganism,
  },
});
</script>
<style scoped>
.body {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
}
.footer-copy {
  font-size: 1rem;
  letter-spacing: normal;
  margin-bottom: 10px;
  text-align: center;
  margin-top: auto;
}
</style>
