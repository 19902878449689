<template>
  <HeaderComponent pageTitle="資料請求（受付完了）" />
  <CatalogIntroductionCompleteOrganism />
  <CatalogGuidanceCompleteOrganism />
  <FooterComponent />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import HeaderComponent from "@/components/organisms/global/HeaderComponent.vue";
import CatalogIntroductionCompleteOrganism from "@/components/organisms/catalog/CatalogIntroductionCompleteOrganism.vue"; // @ is an alias to /src
import CatalogGuidanceCompleteOrganism from "@/components/organisms/catalog/CatalogGuidanceCompleteOrganism.vue"; // @ is an alias to /src
import FooterComponent from "@/components/organisms/global/FooterComponent.vue";

export default defineComponent({
  name: "CatalogCompleteTemplete",
  components: {
    HeaderComponent,
    CatalogIntroductionCompleteOrganism,
    CatalogGuidanceCompleteOrganism,
    FooterComponent,
  },
});
</script>
