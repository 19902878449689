<template>
  <body>
    <div class="Btn-area">
      <div class="Btn-area__item">
        <div class="Btn-area__btn">
          <a href="https://www.lifenet-seimei.co.jp/" class="Btn Btn--type01"
            >トップページへ戻る</a
          >
        </div>
      </div>
    </div>
    <div class="Conversion">
      <div class="Conversion-inside">
        <div class="Conversion__item">
          <div class="Cv-unit Cv-unit--type01">
            <p>まずは見積りトライ！</p>
            <a
              href="https://www.lifenet-seimei.co.jp/plan/"
              class="Cv-btn Cv-btn--type01"
            >
              <img
                src="../../../assets/btn_conversion01.png"
                alt="無料で10秒見積り"
                class="u-fiximg"
                width="474"
                height="88"
              />
              <img
                src="../../../assets/btn_conversion01_sp.png"
                alt="無料で10秒見積もり"
                class="u-fiximg-sp"
                width="474"
                height="88"
              />
            </a>
          </div>
        </div>
        <div class="Conversion__item">
          <div class="Cv-unit Cv-unit--type02">
            <p>保険選びに迷った方におすすめ！</p>
            <a
              href="https://www.lifenet-seimei.co.jp/plan/advice/"
              class="Cv-btn Cv-btn--type02"
            >
              <img
                src="../../../assets/btn_conversion02.png"
                alt="ぴったり保険診断"
                class="u-fiximg"
                width="474"
                height="88"
              />
              <img
                src="../../../assets/btn_conversion02_sp.png"
                alt="ぴったり保険診断"
                class="u-fiximg-sp"
                width="474"
                height="88"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="l-base">
      <div class="Box-flame">
        <div class="Box-flame__block">
          <h2 class="Block-ttl u-txtc">
            <span>保険にはいつ入れば</span><span>おトクなの？</span>
          </h2>
        </div>
        <div class="Box-flame__block">
          <p class="Txt-lead">
            <strong
              >保険に入るなら「健康」で「保険料が上がる前」がおすすめです！</strong
            >
          </p>
          <dl class="Notes">
            <dt class="Point-ttl">
              <span class="Point-ttl__num">ポイント1</span
              >病気やケガをする前に保険に入りましょう
            </dt>
            <dd class="Img-notes Img-notes--point01">
              <p class="Img-notes__txt">
                人は、病気やケガなどを経験してから、やっと保険の必要性に気づきますが、病気やケガをしてから保険に申し込んでもなかなか加入できません。<br />加入にあたって「健康状態」を問われるのが民間の保険です。<br />病気などによっては、加入が難しい場合があります。健康なうちに保険を準備しておきましょう。
              </p>
            </dd>
            <dt class="Point-ttl">
              <span class="Point-ttl__num">ポイント2</span
              >誕生月の前までに保険に入りましょう
            </dt>
            <dd class="Img-notes Img-notes--point02">
              <p class="Img-notes__txt">
                一般的に生命保険は年齢が上がると、保障内容は同じでも、保険料が高くなります。<br />加入することを決めたら、誕生月より前に手続きを済ませることをおすすめします。
              </p>
              <p class="Img-notes__txt Rule-txt">
                ※ライフネット生命が販売している保険商品の保険料は、いずれも申込日の翌月1日時点の年齢で保険料を計算しています。誕生日が近い方には、見積り画面に保険料が変更になるタイミングを表示していますのでご確認ください。
              </p>
            </dd>
          </dl>
          <div class="Btn-area">
            <p class="Btn-area__lead">
              わかりやすいパンフレットもご用意しております
            </p>
            <div class="Btn-area__btn">
              <a
                href="https://frm.lifenet-seimei.co.jp/catalog/"
                class="Btn Btn--type02"
                ><span class="Btn__em">資料請求する</span></a
              >
            </div>
          </div>
        </div>
      </div>
      <!--/.Box-flame-->
      <div class="bottomSpace">
        <CatalogInquiryOrganism />
      </div>
      <!--/.Box-flame-->
      <div class="Control-back">
        <div class="Btn-area">
          <div class="Btn-area__item">
            <p class="Btn-area__btn">
              <a
                href="https://www.lifenet-seimei.co.jp/"
                class="Btn Btn--type01"
                >トップページへ戻る</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>
<script lang="ts">
// templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用
import { defineComponent } from "vue";
import CatalogInquiryOrganism from "@/components/organisms/catalog/CatalogInquiryOrganism.vue"; // @ is an alias to /src

export default defineComponent({
  name: "ReserveComplete",
  components: { CatalogInquiryOrganism },
});
</script>
<style scoped>
img {
  vertical-align: middle;
  border-style: none;
}
.Cv-unit--type01 {
  color: #fd9001;
}
.Cv-btn--type01 {
  background: #fd9001;
}
.Cv-unit--type02 {
  color: #70ad46;
}
.Cv-btn--type02 {
  background: #70ad46;
}
.Page-ttl,
.Block-ttl {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Hiragino Sans", Meiryo, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd {
  margin: 0;
  font-size: 100%;
}
.Img-notes--point01 {
  background-image: url(../../../assets/img_decoration04.jpg);
}
.Img-notes--point02 {
  background-image: url(../../../assets/img_decoration05.jpg);
}
.Img-notes__txt + .Img-notes__txt {
  margin-top: 1em;
}
@media screen and (min-width: 501px) {
  .Btn-area {
    width: 740px;
    margin: 25px auto 32px;
  }
  .Btn-area__item {
    width: 440px;
    margin: 0 auto;
  }
  .Btn--type01 {
    height: 72px;
    margin: 0 auto;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -8px rgb(0 16 14 / 31%);
    font-size: 2rem;
    font-weight: bold;
  }
  .Btn {
    display: flex;
    position: relative;
    width: 100%;
    padding-right: 35px;
    border: 2px solid #799b3f;
    border-radius: 8px;
    background: #f1f3ee;
    color: #3f5d26 !important;
    text-align: center;
    text-decoration: none !important;
    line-height: 1.2;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }
  .Btn--type01::before {
    width: 20px;
    height: 20px;
    margin: -10px 0 0 -35px;
  }
  .Btn::before {
    border-radius: 50%;
    background: #3f5d26;
  }
  .Btn::before,
  .Btn::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    content: "";
  }
  .Btn--type01::after {
    left: 100%;
    margin: -5px 0 0 -28px;
    border-width: 5px 0 5px 8px;
  }
  .Btn::after {
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent transparent #f1f3ee;
  }

  .Conversion {
    margin-bottom: 60px;
    padding: 30px 0;
    border: solid #dedfde;
    border-width: 1px 0;
    background: #f7f3f7;
    text-align: center;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.2;
  }
  .Conversion-inside {
    display: flex;
    width: 1040px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
  }
  .Conversion__item {
    width: 507px;
  }

  .Cv-btn {
    display: block;
    margin: 16px auto 0;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -4px rgb(0 16 14 / 31%);
    border-radius: 8px;
    color: #fff !important;
    font-weight: bold;
    text-decoration: none !important;
    text-align: center;
    transition: all 0.3s ease;
  }

  .u-fiximg-sp {
    display: none;
  }

  .l-base {
    width: 1040px;
    margin: 0 auto;
  }
  .Box-flame {
    overflow: hidden;
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    background: #fafafa;
    box-shadow: 10px 10px 8px -9px rgb(158 158 158 / 80%);
    margin-bottom: 20px;
  }
  .Box-flame__block {
    margin: 48px 99px;
  }
  .Block-ttl {
    padding-bottom: 32px;
    border-bottom: 1px solid #3b5e10;
    color: #3b5e10;
    font-size: 3.4rem;
    text-align: center;
    line-height: 1.1;
    letter-spacing: normal;
  }

  .Txt-lead {
    margin-bottom: 40px;
    text-align: center;
    font-size: 2.4rem;
    line-height: 1.2;
  }
  .Notes {
    margin: 0 50px 32px;
  }
  .Point-ttl {
    margin: 30px 0 20px;
    font-size: 2rem;
    font-weight: bold;
    color: #588310;
    line-height: 1;
  }
  .Point-ttl__num {
    display: inline-block;
    margin-right: 30px;
    padding: 7px 15px 5px;
    border-radius: 4px;
    background: #588310;
    color: #fff;
    font-size: 1.6rem;
  }

  .Img-notes {
    padding: 20px 0 20px 275px;
    background-size: 230px auto;
    background-repeat: no-repeat;
  }

  .Rule-txt {
    font-size: 1.3rem;
  }
  .Btn-area__lead {
    margin-bottom: 16px;
    text-align: center;
    color: #70ad46;
    font-size: 2rem;
    font-weight: bold;
  }
  .Btn--type02 {
    width: 580px;
    height: 100px;
    padding: 0;
    background: #70ad46;
  }
  .Btn--type02::before {
    width: 30px;
    height: 30px;
    margin: -15px 0 0 -50px;
    background: #fff;
  }
  .Btn--type02 {
    margin: 0 auto;
    border: none;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -4px rgb(0 16 14 / 31%);
    color: #fff !important;
    font-size: 2.4rem;
    line-height: 1;
    transition: all 0.3s ease;
  }

  .Btn__em {
    display: block;
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.6;
  }

  .Btn--type02::after {
    border-left-color: #70ad46;
    left: 100%;
    margin: -8px 0 0 -38px;
    border-width: 8px 0 8px 11px;
  }

  .Control-back {
    margin-top: 60px;
  }
}
@media screen and (max-width: 500px) {
  .bottomSpace {
    margin-top: 20px;
  }
  h2 span {
    display: inline-block;
  }

  .Btn-area {
    margin: 0 10px 20px;
  }

  .Btn--type01 {
    height: 60px;
    margin: 0 auto;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.2;
  }
  .Btn {
    display: flex;
    position: relative;
    width: 100%;
    padding: 0 40px 0 20px;
    border: 2px solid #3f5d26;
    border-radius: 8px;
    background: #f4faeb;
    color: #3f5d26 !important;
    text-align: center;
    text-decoration: none !important;
    justify-content: center;
    align-items: center;
  }
  .Btn--type01::before {
    width: 20px;
    height: 20px;
    margin: -10px 0 0 -35px;
  }
  .Btn::before {
    border-radius: 50%;
    background: #3f5d26;
  }
  .Btn::before,
  .Btn::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    content: "";
  }
  .Btn--type01::after {
    margin: -5px 0 0 -28px;
    border-width: 5px 0 5px 8px;
  }
  .Btn::after {
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent transparent #f1f3ee;
  }

  .Conversion {
    margin-bottom: 30px;
    padding: 10px 15px;
    border: solid #dedfde;
    border-width: 1px 0;
    background: #fafafa;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.2;
  }

  .Conversion__item {
    margin: 20px 0 30px;
  }

  .Cv-btn {
    display: block;
    position: relative;
    margin: 8px auto 0;
    padding: 10px 26px 10px 10px;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -4px rgb(0 16 14 / 31%);
    border-radius: 8px;
    color: #fff !important;
    font-weight: bold;
    text-decoration: none !important;
    text-align: center;
  }
  .Cv-btn::before {
    width: 24px;
    height: 24px;
    margin: -12px 0 0 -38px;
    border-radius: 50%;
    background: #fff;
  }
  .Cv-btn::before,
  .Cv-btn::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    content: "";
  }
  .Cv-btn::after {
    width: 0;
    height: 0;
    margin: -6px 0 0 -29px;
    border-style: solid;
    border-width: 6px 0 6px 9px;
    border-color: transparent transparent transparent;
  }
  .u-fiximg {
    display: none;
  }
  .u-fiximg-sp {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .Cv-btn--type01::after {
    border-left-color: #fd9001;
  }

  .Cv-btn--type02::after {
    border-left-color: #70ad46;
  }
  .l-base {
    padding: 0 10px;
  }
  .Box-flame {
    overflow: hidden;
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    background: #fff;
    box-shadow: 10px 10px 8px -9px rgb(158 158 158 / 80%);
  }
  .Box-flame__block {
    margin: 20px 15px;
  }
  .Block-ttl {
    padding-bottom: 20px;
    border-bottom: 1px solid #3b5e10;
    color: #3b5e10;
    font-size: 2.2rem;
    line-height: 1.4;
    letter-spacing: 0;
  }
  .u-txtc {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Txt-lead {
    margin-bottom: 30px;
    font-size: 2rem;
    line-height: 1.4;
  }
  .Notes {
    margin: 0 10px;
  }
  .Point-ttl {
    margin: 30px 0 20px;
    font-size: 17px;
    font-weight: bold;
    color: #588310;
    line-height: 1.4;
  }
  .Point-ttl__num {
    display: block;
    width: 105px;
    margin-bottom: 20px;
    padding: 7px 15px 5px;
    border-radius: 4px;
    background: #588310;
    color: #fff;
    font-size: 1.6rem;
    text-align: center;
    line-height: 1;
    white-space: nowrap;
  }

  .Img-notes {
    padding-top: 240px;
    background-size: 230px auto;
    background-repeat: no-repeat;
  }

  .Rule-txt {
    font-size: 1.4rem;
  }
  p {
    margin: 0;
  }
  .Btn-area__lead {
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
    color: #70ad46;
    line-height: 1.4;
  }
  .Btn--type02 {
    display: block;
    position: relative;
    width: 100%;
    padding: 18px 40px 14px 23px;
    border: none;
    border-radius: 8px;
    background: #70ad46;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -8px rgb(0 16 14 / 31%);
    color: #fff !important;
    line-height: 1;
  }
  .Btn--type02::before {
    width: 24px;
    height: 24px;
    margin: -12px 0 0 -38px;
    background: #fff;
  }
  .Btn--type02 {
    margin: 0 auto;
    border: none;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -4px rgb(0 16 14 / 31%);
    color: #fff !important;
    font-size: 2.4rem;
    line-height: 1;
    transition: all 0.3s ease;
  }

  .Btn__em {
    display: block;
    font-size: 2.3rem;
    font-weight: bold;
    line-height: 1.6;
  }

  .Btn--type02::after {
    border-left-color: #70ad46;
    left: 100%;
    margin: -6px 0 0 -29px;
    border-width: 6px 0 6px 9px;
  }

  .Control-back {
    margin-top: 40px;
  }
}
</style>
