<template>
  <div class="body">
    <HeaderComponent pageTitle="見積り内容をメールで送信（受付完了）" />
    <LeadIntroductionOrganism
      introAreaLead1="お見積りの内容を、ご入力いただいたメール"
      introAreaLead2="アドレスにお送りします。"
      introAreaTxt1="しばらくしてもメールが届かない場合は、ご入力いただいたメールアドレスが誤っている可能性があります。お手数ですが、再度「保険料見積り」ページから、やり直してください。"
      introAreaTxt2="なお、携帯メールを宛先にされた場合は、迷惑メール防止機能で受信拒否されないよう以下ドメインからのメールを受信可能にご設定ください。"
      introAreaTxt3="「@lifenet-seimei.co.jp」"
      introAreaTxt4="「@mail.lifenet-seimei.co.jp」"
    />
    <LeadCompleteOrganism />
    <FooterComponent />
  </div>
</template>
<script lang="ts">
// Pagesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. (場合によってoganismsに責務を持たせるが)エラーハンドリング用Vuexステータスを操作
// 4. ページ(アプリケーション)全体に及ぶCSSの適用
import { defineComponent } from "vue";
import HeaderComponent from "@/components/organisms/global/HeaderComponent.vue";
import LeadIntroductionOrganism from "@/components/organisms/lead/LeadIntroductionOrganism.vue"; // @ is an alias to /src
import LeadCompleteOrganism from "@/components/organisms/lead/LeadCompleteOrganism.vue";
import FooterComponent from "@/components/organisms/global/FooterComponent.vue";

export default defineComponent({
  name: "LeadComplete",
  components: {
    HeaderComponent,
    LeadIntroductionOrganism,
    LeadCompleteOrganism,
    FooterComponent,
  },
});
</script>
<style scoped>
.body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Hiragino Sans", Meiryo, sans-serif;
  font-size: 1.6rem;
  color: #333;
  letter-spacing: 0.08em;
  line-height: 1.8;
  letter-spacing: 0.08em;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  min-height: 100vh;
}
</style>
