import { AbstractMessages } from "./AbstractMessages";

export class Messages extends AbstractMessages {
  /**
   * 内部コードからメッセージを生成します。
   *
   * @param internalCode
   * @returns
   */
  generateMessage(internalCode: string): string {
    switch (internalCode) {
      case "N1":
        return "正常終了。";
      case "E1":
        return "保険相談予約日時IDが未入力です。";
      case "E2":
        return "保険相談予約日時IDに数字以外の文字が含まれています。";
      case "E3":
        return "お名前(セイ)が未入力です。";
      case "E4":
        return "お名前(セイ)が30文字を超えています。";
      case "E5":
        return "お名前(セイ)にカタカナ以外の文字が含まれています。";
      case "E6":
        return "お名前(メイ)が未入力です。";
      case "E7":
        return "お名前(メイ)が20文字を超えています。";
      case "E8":
        return "お名前(メイ)にカタカナ以外の文字が含まれています。";
      case "E9":
        return "性別が未入力です。";
      case "E10":
        return "電話番号が未入力です。";
      case "E11":
        return "電話番号をご確認ください。";
      case "E12":
        return "電話番号の形式が違います。";
      case "E13":
        return "気になる項目に不正な値が含まれています。";
      case "E14":
        return "画面共有サポート利用フラグに不正な値が含まれています。";
      case "E15":
        return "KARTEユーザIDが255文字を超えています。";
      case "E16":
        return "予約希望日時を選択してください。";
      case "E17":
        return "選択された日時が存在しません。";
      case "E18":
        return "ご希望の予約日で予約が出来ませんでした、別の日をご指定下さい。";
      case "E19":
        return "同様のご予約が存在します、ご予約日をお確かめの上再度お申し込みください。";
      case "E101":
        return "サーバー内部でエラーが発生しました、時間を空けて再度リクエストしてください。";
      case "E102":
        return "サーバー内部でエラーが発生しました、時間を空けて再度リクエストしてください。";
      default:
        return "";
    }
  }
}
