import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-550d4ef8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "l-baseCat" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_CatalogIntroductionConfirmOrganism = _resolveComponent("CatalogIntroductionConfirmOrganism")!
  const _component_CatalogInputConfirmOrganism = _resolveComponent("CatalogInputConfirmOrganism")!
  const _component_CatalogInquiryOrganism = _resolveComponent("CatalogInquiryOrganism")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderComponent, { pageTitle: "資料請求（入力内容のご確認）" }),
    _createVNode(_component_CatalogIntroductionConfirmOrganism),
    _createVNode(_component_CatalogInputConfirmOrganism),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CatalogInquiryOrganism)
    ]),
    _createVNode(_component_FooterComponent)
  ]))
}