<template>
  <div class="mail-stop">
    <div class="form-head">
      <p>
        配信停止したいメールアドレスを入力し、「送信する」ボタンを押してください。<br />
        ※メールアドレスのご入力間違いにお気をつけください。
      </p>
    </div>
    <ErrorMessageAreaModules v-if="isError" :errorMessage="errorMessage" />
    <MailDeliveryStopForm
      type="text"
      formTitle="メールアドレス(半角)"
      placeHolder="メールアドレス(半角)"
      v-model="email"
      :email="true"
      :maxLength="80"
      :required="true"
      sample="（例： lifenet@lifenet-seimei.co.jp）"
    />
    <div class="form-submit">
      <span class="button-background">
        <button class="submit-button" @click="mailDeliveryStop()">
          送信する
        </button>
      </span>
    </div>
  </div>
  <!-- sp画面用 -->
  <div class="mail-stop-sp">
    <div class="form-head">
      <p>
        配信停止したいメールアドレスを入力し、「送信する」ボタンを押してください。<br />
        ※メールアドレスのご入力間違いにお気をつけください。
      </p>
    </div>
    <ErrorMessageAreaModules v-if="isError" :errorMessage="errorMessage" />
    <MailDeliveryStopForm
      type="text"
      formTitle="メールアドレス(半角)"
      placeHolder="メールアドレス(半角)"
      v-model="email"
      :email="true"
      :maxLength="80"
      :required="true"
      sample="（例： lifenet@lifenet-seimei.co.jp）"
    />

    <div class="form-submit">
      <span class="button-background">
        <button class="submit-button" @click="mailDeliveryStop()">
          送信する
        </button>
      </span>
    </div>
  </div>
</template>
<!-- // templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用 -->
<script src="./MailDeliveryStop.ts" />

<style scoped>
@media screen and (min-width: 501px) {
  .mail-stop-sp {
    display: none;
  }
  .mail-stop {
    width: 700px;
    padding: 30px 39px 0px 39px;
    line-height: 1.71;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: #fafafa;
    margin: 0 auto;
  }

  .form-head {
    display: block;
  }
  .form-head h2 {
    margin: 2.5em -16px 0.8em;
    padding-bottom: 0.2em;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: #cccccc;
    line-height: 1.4;
    font-weight: 600;
    width: 760px;
    font-size: 17px;
  }
  .form-head p {
    margin-bottom: 0.7em;
    font-size: 75%;
  }
  .form-submit {
    width: 700px;
    margin-top: 1.6em;
    padding: 9px 0px;
    overflow: hidden;
    _overflow: visible;
    _height: 1px;
    zoom: 1;
    margin-bottom: 3em;
    display: flex;
    justify-content: end;
  }
  .form-submit .submit-button {
    padding: 0;
    margin: 0;
    width: 150px;
    display: block;
    text-align: center;
    font-weight: 550;
    border: none;
    border-radius: 4px;
    background-color: #64910b;
    color: #ffffff;
    border-color: #487007;
    box-shadow: 0 4px 0 0 rgba(20, 20, 20, 0.1);
    position: relative;
  }

  .submit-button::after {
    content: "\03e";
    margin: 2px 0 5px 25px;
    position: absolute;
  }

  .button-background {
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .mail-stop {
    display: none;
  }
  .mail-stop-sp {
    width: 100%;
    padding: 0 10px;
    line-height: 1.71;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: #fafafa;
    margin: 0 auto;
  }

  .form-head {
    display: block;
    padding: 30px 10px 25px 10px;
  }
  .form-head h2 {
    margin: 2.5em -16px 0.8em;
    padding-bottom: 0.2em;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: #cccccc;
    line-height: 1.4;
    font-weight: 600;
    width: 100%;
    font-size: 17px;
  }

  .form-submit {
    position: relative;
    margin: 30px 0 0.8em 65%;
    text-align: center;
  }

  .form-submit .submit-button {
    padding: 0 0 0 10%;
    margin: 0;
    width: 100%;
    min-height: 50px;
    max-height: 50px;
    display: block;
    text-align: center;
    font-weight: 550;
    border: none;
    border-radius: 4px;
    background-color: #64910b;
    color: #ffffff;
    border-color: #487007;
    box-shadow: 0 4px 0 0 rgba(20, 20, 20, 0.1);
  }
  .form-submit .button-background .submit-button::after {
    content: "\03e";
    margin-left: 15%;
  }
  .button-background {
    display: block;
    font-size: 3.4rem;
    font-weight: bold;
    line-height: 1.6;
  }
}
</style>
