import { MeasurementParamGenerater } from "@/services/marketing/MeasurementParamGenerater";
import store from "@/store";
import { InputParam } from "@/interface/catalog/CatalogInterFaces";
import { RequestParam } from "@/interface/lead/LeadInputInterFaces";

/**
 * 計測ツールの実行を行います
 */
export class MeasurementToolExecution {
  /**
   * 資料請求完了画面の計測を実行します
   */
  public static executeCatalogCompleteMeasurement() {
    // 入力画面のインプットパラメータを取得する
    const inputParam: InputParam = store.getters.getInputPageParam;
    // AA発火イベント
    const ignitionEvent: string =
      MeasurementParamGenerater.generateCatalogAAEventByinputChannel(
        inputParam.channelSikibetusi
      );
    // 契約年齢
    const contractAge: string = store.getters.getContractAge;
    // 性別
    const sex: string =
      MeasurementParamGenerater.convertCatalogInsuredPersonGender(
        inputParam.insuredPersonGender
      );
    // 資料請求ID
    const catalogId: string = store.getters.getCatalogId;

    // AA計測用パラメータを設定して計測を実行
    window.s.view(() => {
      window.s.events = ignitionEvent;
      window.s.eVar5 = sex;
      window.s.eVar6 = contractAge;
      window.s.eVar44 = catalogId;
    });
  }

  /**
   * 保険相談予約完了画面の計測を実行します
   */
  public static executeReserveCompleteMeasurement() {
    // 性別
    const sex: string =
      MeasurementParamGenerater.convertCatalogInsuredPersonGender(
        store.getters.getInsuredPersonGender
      );
    // AA計測用パラメータを設定して計測を実行
    window.s.view(() => {
      window.s.events = "event39";
      window.s.eVar5 = sex;
    });
  }

  /**
   * 見積メール保存完了画面の計測を実行します
   */
  public static executeLeadCompleteMeasurement() {
    // 入力画面のインプットパラメータを取得する
    const requestParam: RequestParam = store.getters.getRequestParam;
    // AA発火イベント
    const ignitionEvent = "event34";
    // 契約年齢
    const contractAge: string = requestParam.contractAge;
    // 性別
    const sex: string =
      MeasurementParamGenerater.convertLeadInsuredPersonGender(
        requestParam.sex
      );
    // 見積メール保存ID
    const contractKey: string = store.getters.getContractKey;

    // AA計測用パラメータを設定して計測を実行
    window.s.view(() => {
      window.s.events = ignitionEvent;
      window.s.eVar5 = sex;
      window.s.eVar6 = contractAge;
      window.s.eVar68 = contractKey;
    });
  }
}
