<template>
  <td class="Form-tbl__td Form-tbl__td--confirm">
    {{ tableData }}
  </td>
</template>

<script lang="ts">
// 1. modulesから呼ばれるこれ以上切り分けることのできない最小限の画面項目を定義する
// 2. 基本的には再利用される画面項目に関してをatomsに切り出して、処理や画面の動きを一定化する目的がある
// 3. atomsコンポーネントは外部から再利用されやすく、親コンポーネントを意識しない様に作る
//    例えば入力フォームだが、予めバリデーションやプレースホルダをpropsとして定義しておく等
import { defineComponent } from "vue";
interface DataType {
  inputHashParam: string;
  isError: boolean;
  errorMessage: string;
}

export default defineComponent({
  name: "HelloWorld",
  props: {
    // 確認画面の項目
    tableData: { type: String, default: "" },
  },
  data(): DataType {
    return {
      inputHashParam: "",
      isError: false,
      errorMessage: "",
    };
  },

  methods: {
    /**
     * バリデーションメソッド
     * ・propsの情報を元に必要なバリデーションを行う
     * ・バリデーションエラーの場合、エラーメッセージを表示します
     *
     * @return void
     */
  },
  computed: {},
  watch: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
.Form-tbl__th--confirm,
.Form-tbl__td--confirm {
  line-height: 1.3;
  height: 100%;
  white-space: pre-line;
}
.Form-tbl__th,
.Form-tbl__td {
  color: #2c4318;
}
@media screen and (min-width: 501px) {
  .Form-tbl__td {
    word-break: break-all;
    width: 582px;
    padding: 13px 24px;
    border-left: 1px solid #80b735;
    background: #fff;
  }
}
@media screen and (max-width: 500px) {
  .Form-tbl__td {
    word-break: break-all;
    width: 100%;
    min-height: 48px;
    padding: 13px 24px;
    border-left: #80b735;
    background: #fff;
  }
}
</style>
