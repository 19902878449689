import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57208f09"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-area" }
const _hoisted_2 = ["value", "type", "placeholder", "maxlength"]
const _hoisted_3 = { class: "error-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target!.value))),
      class: _normalizeClass(_ctx.className),
      type: _ctx.inputType,
      placeholder: _ctx.placeHolder,
      maxlength: _ctx.maxLength
    }, null, 42, _hoisted_2),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.errorText), 1)
  ]))
}