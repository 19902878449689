<template>
  <tr class="Form-tbl__tr">
    <th class="Form-tbl__th Form-tbl__th--confirm">今回の保険検討のきっかけ</th>
    <TableDataAtoms :tableData="value" />
  </tr>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";
import TableDataAtoms from "@/components/atoms/TableDataAtoms.vue";
// import { default } from '../../../store/modules/catalog/catalogStatus';

interface DataType {
  readonly value1: string;
  readonly value2: string;
  readonly value3: string;
  readonly value4: string;
  readonly value5: string;
  readonly value6: string;
  readonly value7: string;
  readonly value8: string;
  value: string;
}
export default defineComponent({
  name: "HelloWorld",
  components: {
    TableDataAtoms,
  },
  props: {
    // コード値
    codeValue: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data(): DataType {
    return {
      value1: "現在加入している保険の見直し・満期・更新",
      value2: "健康に対する不安",
      value3: "老後の備え",
      value4: "就職・転職",
      value5: "住宅の購入",
      value6: "結婚",
      value7: "妊娠・出産・お子さまの誕生",
      value8: "お子さまの進学・卒業・独立",
      value: "",
    };
  },
  methods: {
    convertCodeValue() {
      if (this.codeValue.length === 0) {
        return;
      }
      this.codeValue.forEach((code: string) => {
        switch (code) {
          case "1":
            this.value = this.value + this.value1 + "\n";
            break;
          case "2":
            this.value = this.value + this.value2 + "\n";
            break;
          case "3":
            this.value = this.value + this.value3 + "\n";
            break;
          case "4":
            this.value = this.value + this.value4 + "\n";
            break;
          case "5":
            this.value = this.value + this.value5 + "\n";
            break;
          case "6":
            this.value = this.value + this.value6 + "\n";
            break;
          case "7":
            this.value = this.value + this.value7 + "\n";
            break;
          case "8":
            this.value = this.value + this.value8 + "\n";
            break;
        }
      });
    },
  },
  mounted() {
    this.convertCodeValue();
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
th {
  text-align: inherit;
}
button,
input {
  overflow: visible;
}
.Form-tbl__th,
.Form-tbl__td {
  color: #2c4318;
}
@media screen and (min-width: 501px) {
  .Form-tbl__th--confirm,
  .Form-tbl__td--confirm {
    line-height: 1.3;
    height: 46px;
  }
  .Form-tbl__th {
    position: relative;
    width: calc(100% - 582px);
    padding: 0 0 0 26px;
    font-weight: normal;
    vertical-align: middle;
    align-items: center;
    letter-spacing: normal;
  }

  .Form-tbl__tr:not(:first-of-type) {
    border-top: 1px dotted #ccc;
  }
}
@media screen and (max-width: 500px) {
  .Form-tbl__tr {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .Form-tbl__th {
    position: relative;
    width: 100%;
    padding: 10px;
    font-weight: normal;
    vertical-align: middle;
    align-items: center;
    letter-spacing: normal;
  }
  td {
    width: 100%;
  }
}
</style>
