<template>
  <div class="error-block">
    <dl>
      <dt>下記の入力項目をご確認ください</dt>
      <dd>
        <ul>
          <li>{{ errorMessage }}</li>
        </ul>
      </dd>
    </dl>
  </div>
</template>
<script lang="ts">
// 1. atomsコンポーネントをデコレーションして最小限の画面項目の集合を作る
// 2. organismsコンポーネントから渡されるデータを表示/atomsにデータを受け渡す
// 3. modulesが大きくなりすぎないようにコーディングする
//    具体的には、一つの責務を持つコンポーネントの集まりとする
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorMessageAreaMolecules",
  components: {},
  props: {
    errorMessage: { type: String, default: "" },
  },
});
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.error-block {
  margin: 15px 0 15px 0;
  padding-left: 13px;
  border-width: 2px;
  border-style: solid;
  border-color: #ffcccc;
  background-color: #fff4f4;
}
.error-block dl {
  margin-bottom: 0.7em;
  color: #eb0000;
}
.error-block dl dt {
  margin-bottom: 0.5em;
  padding-left: 0;
  background-image: none;
  color: #eb0000;
  font-size: 13px;
  font-weight: 600;
}
.error-block dl dd {
  margin-left: 0;
  margin-bottom: 0;
}
.error-block dd ul {
  margin-top: 0.2em;
  margin-left: -20px;
}
.error-block ul li {
  font-size: 14px;
}
</style>
