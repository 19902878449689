<template>
  <div class="l-base">
    <ErrorMessageAreaMolecules
      v-if="!validationResultFlg"
      :errorList="validationMessages"
    />
    <div class="Block-head">
      <h2 class="Block-ttl">お客さま情報をご入力ください</h2>
      <p class="Block-head">
        <span class="Ico-ssl"
          >お客さまの入力情報は、暗号化通信により守られております。</span
        >
      </p>
    </div>

    <div id="reserveForm" class="Form-wrap">
      <div class="Box-flame">
        <table class="Form-tbl">
          <tbody>
            <tr class="Form-tbl__tr">
              <th class="Form-tbl__th bgg">
                <div class="Form-name">
                  電話＆オンラインでの<br />保険相談の希望時間帯
                  <span class="Ico-must">必須</span>
                </div>
              </th>
              <td id="reserve-date" class="Form-tbl__td">
                <div class="Select-wrap">
                  <select
                    name="reserve_date_id"
                    class="Custom-select"
                    v-model="inputParam.reserveDateId"
                    @change="nowSelectDataChange()"
                  >
                    <option value="">お選びください →</option>
                    <option
                      v-for="select in selectList"
                      :value="select.id"
                      :key="select.id"
                    >
                      {{ select.reserveDateTime + " " + select.reserveMessage }}
                    </option>
                  </select>
                  <div class="Pseudo-custom-select">
                    <p>{{ nowSelected }}</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th class="Form-tbl__th">
                <div class="Form-name bgg">
                  お名前（カタカナ）<span class="Ico-must">必須</span>
                </div>
              </th>
              <td id="input_name_kana" class="Form-tbl__td bgw">
                <div class="Date-box">
                  <NameInputFormMolecules
                    type="text"
                    placeHolder="ライフ"
                    formTitle="セイ"
                    :maxLength="19"
                    :required="true"
                    :kanaInput="true"
                    v-model="inputParam.familyNameKana"
                  />
                  <NameInputFormMolecules
                    type="text"
                    placeHolder="ダイスケ"
                    formTitle="メイ"
                    :maxLength="19"
                    :required="true"
                    :kanaInput="true"
                    v-model="inputParam.firstNameKana"
                  />
                </div>
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th class="Form-tbl__th">
                <div class="Form-name">
                  性別 <span class="Ico-must">必須</span>
                </div>
              </th>
              <td id="b04_00" class="Form-tbl__td">
                <GenderMolecules v-model="inputParam.insuredPersonGender" />
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th class="Form-tbl__th">
                <div class="Form-name bgg">
                  電話番号 <span class="Ico-must">必須</span>
                </div>
              </th>
              <td id="input_primary_phone" class="Form-tbl__td">
                <div class="Date-box">
                  <LongInputMolecules
                    type="text"
                    placeHolder="08023456789"
                    formTitle="電話番号"
                    :maxLength="11"
                    :required="true"
                    v-model="inputParam.primaryPhone"
                  />
                  <p class="Ex-txt">（例：08023456789，0312345678）</p>
                </div>
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th id="label_concern" class="Form-tbl__th">
                <div class="Form-name">気になる項目（複数選択可）</div>
              </th>
              <td id="input_concern" class="Form-tbl__td">
                <ConcernCheckboxMolecules
                  v-model="inputParam.concern"
                  :reEnterStatus="false"
                />
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th id="label_screen_sharing_flg" class="Form-tbl__th">
                <div class="Form-name">画面共有サポート利用</div>
              </th>
              <td id="input_screen_sharing_flg_item" class="Form-tbl__td">
                <ScreenSharingFlgCheckboxMolecules
                  v-model="inputParam.screenSharingFlg"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--/.Box-flame-->
      <PolicyProtectionMolecules />

      <div id="estimate" class="Control-area">
        <button
          type="button"
          id="FormAssist_submit"
          @click="goToComplete()"
          class="Control-area__btn"
        >
          個人情報保護宣言に同意して<span class="Control-area__btn__em"
            >送信する</span
          >
        </button>
      </div>
    </div>
    <input type="hidden" id="karte-user-id" />
    <!--/.Box-flame-->
  </div>
</template>
<!-- // templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用 -->
<script src="./ReserveInput.ts" />

<style scoped>
*,
::after,
::before {
  box-sizing: border-box;
}
.Ico-ssl:before {
  display: inline-block;
  width: 17px;
  height: 19px;
  margin-right: 10px;
  background: url(../../../assets/icon_ssl.gif) no-repeat 0 0;
  vertical-align: text-top;
  content: "";
}

.Form-tbl__th,
.Form-tbl__td {
  color: #2c4318;
}
th {
  text-align: inherit;
}
button,
select {
  text-transform: none;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
label {
  margin: 0;
}

button,
input {
  overflow: visible;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.Ex-txt {
  color: #666;
}
.Form-wrap .Box-flame {
  border-color: #80b735;
  box-shadow: none;
}
.Box-flame + .Box-flame {
  margin-top: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd {
  margin: 0;
  font-size: 100%;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
.Control-area {
  position: relative;
  margin: 104px 0 66px;
  text-align: center;
}
.Control-area__btn__em {
  display: block;
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.6;
}
.Select-wrap {
  position: relative;
  margin: 9px 0;
}
.Select-wrap::before {
  width: 44px;
  height: 100%;
  margin-left: -44px;
  border-radius: 0 4px 4px 0;
  background: #3f5d26;
}
.Select-wrap::before,
.Select-wrap::after {
  position: absolute;
  left: 100%;
  pointer-events: none;
  content: "";
}
.Select-wrap::after {
  top: 50%;
  width: 0;
  height: 0;
  margin: -5px 0 0 -32px;
  border-width: 12px 10px 0 10px;
  border-color: #fff transparent transparent transparent;
  border-style: solid;
}
.Select-wrap::before,
.Select-wrap::after {
  position: absolute;
  left: 100%;
  pointer-events: none;
  content: "";
}
.Control-area::before {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin: -73px 0 0 -109px;
  border-width: 46px 109px 0 109px;
  border-color: #ff9900 transparent transparent transparent;
  border-style: solid;
  content: "";
}
.Control-area__btn::before {
  width: 30px;
  height: 30px;
  margin: -15px 0 0 -50px;
  border-radius: 50%;
  background: #fff;
}

.Control-area__btn::after {
  width: 0;
  height: 0;
  margin: -8px 0 0 -38px;
  border-style: solid;
  border-width: 8px 0 8px 11px;
  border-color: transparent transparent transparent #ff9900;
}
.Control-area__btn::before,
.Control-area__btn::after {
  display: block;
  position: absolute;
  top: 50%;
  left: 100%;
  content: "";
}
table {
  border-collapse: collapse;
}
@media screen and (min-width: 501px) {
  .l-base {
    width: 1040px;
    margin: 0 auto;
  }
  .Block-head {
    margin: 32px 100px;
    text-align: center;
  }
  .Block-ttl {
    display: none;
  }

  .Form-wrap {
    width: 840px;
    margin: 0 auto;
  }
  .Box-flame {
    overflow: hidden;
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    background: #fafafa;
  }
  .Form-tbl {
    width: 100%;
    background: #f4faeb;
  }

  .Form-tbl__th {
    position: relative;
    width: calc(100% - 582px);
    padding: 0 0 0 26px;
    font-weight: normal;
    vertical-align: middle;
    align-items: center;
    letter-spacing: normal;
  }

  .Form-name {
    display: flex;
    padding: 10px 15px 5px 0;
    justify-content: space-between;
    align-items: center;
  }
  .Ico-must {
    display: inline-block;
    width: 40px;
    height: 22px;
    border: 2px solid #f00;
    border-radius: 4px;
    background: #fff;
    color: #f00;
    font-size: 1.2rem;
    text-align: center;
    line-height: 18px;
  }
  .Form-tbl__td {
    width: 582px;
    padding: 5px 24px;
    border-left: 1px solid #80b735;
    background: #fff;
  }

  .Custom-select {
    width: 100%;
    height: 46px;
    padding: 0 42px 0 18px;
    border-radius: 4px;
    border: 1px solid #3f5d26;
  }

  .Pseudo-custom-select {
    display: none;
  }
  .Date-box {
    display: flex;
    max-width: 532px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .Form-tbl__tr:not(:first-of-type) {
    border-top: 1px dotted #ccc;
  }

  .Control-area__btn {
    position: relative;
    width: 580px;
    min-height: 121px;
    padding: 20px 0 11px;
    border: none;
    border-radius: 8px;
    background: #ff9900;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -4px rgb(0 16 14 / 31%);
    color: #fff;
    font-size: 2.4rem;
    line-height: 1;
    transition: all 0.3s ease;
  }
}
@media screen and (max-width: 500px) {
  br {
    display: none;
  }

  .l-base {
    padding: 0 10px;
  }
  .Block-head {
    margin: 20px 10px;
  }
  .Block-ttl {
    padding-bottom: 20px;
    border-bottom: 1px solid #3b5e10;
    color: #3b5e10;
    font-size: 2.2rem;
    line-height: 1.4;
    letter-spacing: 0;
  }

  .Ico-ssl {
    display: block;
    text-indent: -27px;
    padding-left: 27px;
  }

  form .Box-flame {
    border-color: #80b735;
    box-shadow: none;
  }

  .Box-flame {
    overflow: hidden;
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    background: #fff;
  }
  .Form-tbl {
    width: 100%;
    background: #f4faeb;
    font-size: 1.6rem;
  }

  .Form-tbl__th {
    position: relative;
    padding: 10px;
    font-weight: normal;
    vertical-align: middle;
  }

  .Form-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
  }
  .Ico-must {
    display: inline-block;
    width: 40px;
    height: 26px;
    margin: 0 0 3px 20px;
    border: 2px solid #f00;
    border-radius: 4px;
    background: #fff;
    color: #f00;
    font-size: 1.2rem;
    text-align: center;
    line-height: 22px;
    white-space: nowrap;
  }
  .Form-tbl__td {
    padding: 5px 24px;
    word-break: break-all;
    width: 100%;
    background: #fff;
  }

  .Custom-select {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 53px;
    padding: 0 43px 0 5px;
    border-radius: 4px;
    border: 1px solid #3f5d26;
    background-color: #fff;
  }
  .Pseudo-custom-select {
    width: 100%;
    height: 53px;
    padding: 0 43px 0 5px;
    border-radius: 4px;
    border: 1px solid #3f5d26;
    background-color: #fff;
  }
  .Pseudo-custom-select > p {
    display: table-cell;
    vertical-align: middle;
    line-height: 150%;
    height: 51px;
    padding-left: 4px;
    padding-right: 4px;
    color: #000;
  }

  .Date-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .Form-tbl__tr {
    display: flex;
    flex-direction: column;
  }

  .Control-area__btn {
    position: relative;
    width: 100%;
    min-height: 121px;
    padding: 20px 0 11px;
    border: none;
    border-radius: 8px;
    background: #ff9900;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -4px rgb(0 16 14 / 31%);
    color: #fff;
    font-size: 1.7rem;
    line-height: 1;
    transition: all 0.3s ease;
  }
}
</style>
