<template>
  <!-- <div class="Date-box">
    <div class="Date-name"> -->
  <ul class="Date-list">
    <li class="Date-list__item" v-for="option in options" :key="option.value">
      <label class="Custom-check">
        <input
          v-if="checkedProp.includes(option.value)"
          type="checkbox"
          name="input_mullTriggeredCue"
          :value="option.value"
          v-model="checkedProp"
          :title="option.label"
          :radioId="option.radioId"
          @change="$emit('update:modelValue', checkedProp)"
          class="Input-check"
          checked
        />
        <input
          v-else
          type="checkbox"
          name="input_mullTriggeredCue"
          :value="option.value"
          v-model="checkedProp"
          :title="option.label"
          :radioId="option.radioId"
          @change="$emit('update:modelValue', checkedProp)"
          class="Input-check"
        />
        <span class="Custom-check__inner">
          {{ option.label }} <br />
          <!-- <span v-if="message != ''" class="Rule-txt">{{ message }}</span> -->
        </span>
      </label>
    </li>
  </ul>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";
// import CheckboxComponentAtoms from "@/components/atoms/CheckboxComponentAtoms.vue";

export default defineComponent({
  name: "HelloWorld",
  components: {
    // CheckboxComponentAtoms,
  },
  data() {
    return {
      options: [
        {
          label: "現在加入している保険の見直し・満期・更新",
          value: "1",
          radioId: "a_09_01",
        },
        {
          label: "健康に対する不安",
          value: "2",
          radioId: "a_09_02",
        },
        {
          label: "老後の備え",
          value: "3",
          radioId: "a_09_03",
        },
        {
          label: "就職・転職",
          value: "4",
          radioId: "a_09_04",
        },
        {
          label: "住宅の購入",
          value: "5",
          radioId: "a_09_05",
        },
        {
          label: "結婚",
          value: "6",
          radioId: "a_09_06",
        },
        {
          label: "妊娠・出産・お子さまの誕生",
          value: "7",
          radioId: "a_09_07",
        },
        {
          label: "お子さまの進学・卒業・独立",
          value: "8",
          radioId: "a_09_08",
        },
      ],
      checkedProp: [] as string[],
      firstLoadFlg: true,
    };
  },
  props: {
    // テキストボックス内の入力値をコンポーネント間でバインディングするための変数
    modelValue: {
      type: Array as PropType<string[]>,
      required: true,
    },
    reEnterStatus: {
      type: String,
      required: true,
    },
  },
  computed: {},
  updated() {
    if (
      this.checkedProp.length === 0 &&
      this.reEnterStatus &&
      this.firstLoadFlg
    ) {
      this.firstLoadFlg = false;
      this.checkedProp = this.modelValue as string[];
    }
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Rule-txt {
  font-size: 1.3rem;
}
.Input-check:checked + .Custom-check__inner::after {
  width: 11px;
  height: 20px;
  margin: -5px 0 0 10px;
  border: solid #b51c22;
  border-width: 0 4px 4px 0;
  transform: rotate(40deg);
}
.Custom-check {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  display: block;
  position: relative;
  padding-left: 40px;
  line-height: 1.2;
}

.Custom-check__inner::before {
  width: 23px;
  height: 23px;
  margin-top: -3px;
  border: 1px solid #333;
  background: #fff;
}

.Custom-check__inner::before,
.Custom-check__inner::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.Input-check {
  position: absolute;
  left: -9999px;
}
.Custom-check__inner::before {
  width: 23px;
  height: 23px;
  margin-top: -3px;
  border: 1px solid #333;
  background: #fff;
  top: 4px;
}

.Input-check::before,
.Input-check::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.Input-check:checked + .Custom-check__inner::after {
  width: 11px;
  height: 20px;
  margin: -5px 0 0 10px;
  border: solid #b51c22;
  border-width: 0 4px 4px 0;
  transform: rotate(40deg);
}
button,
input {
  overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.Date-list {
  margin: 13px 0 11px;
  padding: 0;
  list-style: none;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
</style>
