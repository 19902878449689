import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-697df4b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Date-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButtonAtoms = _resolveComponent("RadioButtonAtoms")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RadioButtonAtoms, {
      modelValue: _ctx.localModelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
      name: "is_send_mail",
      value: "1",
      title: "はい",
      radioId: "a_08_01"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_RadioButtonAtoms, {
      modelValue: _ctx.localModelValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localModelValue) = $event)),
      name: "is_send_mail",
      value: "0",
      title: "いいえ",
      radioId: "a_08_02"
    }, null, 8, ["modelValue"])
  ]))
}