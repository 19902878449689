import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69a0d9e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Date-box" }
const _hoisted_2 = { class: "Date-box Date-box--w03" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LongInputFormComponentAtoms = _resolveComponent("LongInputFormComponentAtoms")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LongInputFormComponentAtoms, {
        type: _ctx.type,
        formTitle: _ctx.formTitle,
        placeHolder: _ctx.placeHolder,
        modelValue: _ctx.localModelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
        maxLength: _ctx.maxLength,
        required: true
      }, null, 8, ["type", "formTitle", "placeHolder", "modelValue", "maxLength"])
    ])
  ]))
}