import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43d2d5e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "value", "id", "maxlength"]
const _hoisted_2 = ["name", "value", "id", "maxlength"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.modelValue == _ctx.value)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          type: "radio",
          name: _ctx.name,
          value: _ctx.value,
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target!.value))),
          id: _ctx.radioId,
          class: "Input-radio",
          onFocusout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validate())),
          maxlength: _ctx.maxLength,
          checked: ""
        }, null, 40, _hoisted_1))
      : (_openBlock(), _createElementBlock("input", {
          key: 1,
          type: "radio",
          name: _ctx.name,
          value: _ctx.value,
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target!.value))),
          id: _ctx.radioId,
          class: "Input-radio",
          onFocusout: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validate())),
          maxlength: _ctx.maxLength
        }, null, 40, _hoisted_2)),
    _createElementVNode("label", {
      for: _ctx.radioId,
      class: "Input Input--w02 Label-radio"
    }, _toDisplayString(_ctx.title), 9, _hoisted_3)
  ], 64))
}