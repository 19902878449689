import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0666e202"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-area" }
const _hoisted_2 = ["value", "type", "placeholder", "maxlength"]
const _hoisted_3 = ["value", "type", "placeholder", "maxlength"]
const _hoisted_4 = { class: "error-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.kanaInput)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          value: _ctx.modelValue,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target!.value))),
          class: _normalizeClass(_ctx.className),
          type: _ctx.inputType,
          placeholder: _ctx.placeHolder,
          maxlength: _ctx.maxLength
        }, null, 42, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.kanaInput)
      ? (_openBlock(), _createElementBlock("input", {
          key: 1,
          value: _ctx.modelValue,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target!.value))),
          class: _normalizeClass(_ctx.className),
          type: _ctx.inputType,
          placeholder: _ctx.placeHolder,
          onFocusout: _cache[2] || (_cache[2] = ($event: any) => (_ctx.kanaChange(), _ctx.$emit('update:modelValue', _ctx.kanaValue))),
          maxlength: _ctx.maxLength
        }, null, 42, _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.errorText), 1)
  ]))
}