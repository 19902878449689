import { ApiUrlEnv } from "@/env/ApiUrlEnv";
import { Messages } from "@/utils/message/CatalogMessages";
import { InternalCodeEnum } from "@/utils/message/num/InternalCodeEnum";
import axios from "axios";
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  name: "CatalogIntroduction",
  data() {
    return {
      isError: false,
      errorMessage: "",
    };
  },
  methods: {
    ...mapMutations([
      "setLoadingStatus",
      "setReEnterStatus",
      "setCatalogId",
      "setIsCatalogCompleted",
      "setReturnFromConfirm",
    ]),
    ...mapGetters(["getInputPageParam", "getCatalogId"]),

    pdfDownloadTime() {
      const CatalogMessages = new Messages();
      const param = {
        catalogId: this.getCatalogId(),
      };
      const header = { "x-api-key": this.pdfDownloadApplyApiKey };
      axios
        .post(this.pdfDownloadApiUrl, param, { headers: header })
        .then((res) => {
          // エラーがあった場合は表示
          if (res.data.internalCode != "N1") {
            const message = CatalogMessages.generateMessage(
              res.data.internalCode
            );
          }
          // ローディング画面を非表示
          this.setLoadingStatus(false);
          this.setIsCatalogCompleted(true);
          this.$router.push("/catalog/complete");
        })
        .catch((err) => {
          // ローディング画面を非表示
          this.setLoadingStatus(false);
          this.setIsCatalogCompleted(false);
          this.isError = true;
          this.errorMessage = CatalogMessages.generateMessage(
            InternalCodeEnum.InternalServerError
          );
        });
    },
    applyAgain() {
      this.setReEnterStatus(true);
      this.setReturnFromConfirm(false);
      this.$router.push("/catalog");
    },
  },
  computed: {
    /**
     * PDFダウンロードAPIのURLを返却します。
     *
     * @returns PDFダウンロードAPIのURL
     */
    pdfDownloadApiUrl(): string {
      return ApiUrlEnv.apiBaseUrl + "/pdf/download";
    },

    /**
     * 資料請求情報登録APIのAPIKEYを返却します。
     *
     * @returns 資料請求情報登録APIのAPIKEY
     */
    pdfDownloadApplyApiKey(): string {
      return ApiUrlEnv.apiKey;
    },
  },
});
