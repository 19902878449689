import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d599f6b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Form-tbl__tr" }
const _hoisted_2 = {
  colspan: "2",
  class: "Form-tbl__th Form-tbl__th--confirm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableDataAtoms = _resolveComponent("TableDataAtoms")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("th", _hoisted_2, _toDisplayString(_ctx.tableHead), 1),
    _createVNode(_component_TableDataAtoms, { tableData: _ctx.tableData }, null, 8, ["tableData"])
  ]))
}