import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15f475d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Date-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButtonAtoms = _resolveComponent("RadioButtonAtoms")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createBlock(_component_RadioButtonAtoms, {
        key: option.value,
        modelValue: _ctx.localModelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
        name: "workingStyle-radio",
        value: option.value,
        title: option.label,
        radioId: option.radioId
      }, null, 8, ["modelValue", "value", "title", "radioId"]))
    }), 128))
  ]))
}