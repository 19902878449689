import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fdedba4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_LeadIntroductionOrganism = _resolveComponent("LeadIntroductionOrganism")!
  const _component_LeadCompleteOrganism = _resolveComponent("LeadCompleteOrganism")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderComponent, { pageTitle: "見積り内容をSMSで送信（受付完了）" }),
    _createVNode(_component_LeadIntroductionOrganism, {
      introAreaLead1: "お見積りの内容を、ご入力いただいた携帯電話番号（SMS）にお送りします。",
      introAreaTxt1: "しばらくしてもSMSが届かない場合は、ご入力いただいた携帯電話番号が誤っている可能性があります。お手数ですが、再度「保険料見積り」ページから、やり直してください。"
    }),
    _createVNode(_component_LeadCompleteOrganism),
    _createVNode(_component_FooterComponent)
  ]))
}