import ErrorMessageAreaModules from "@/components/molecules/maildelivery/ErrorMessageAreaMolecules.vue"; // @ is an alias to /src
import MailDeliveryStopForm from "@/components/molecules/maildelivery/MailDeliveryStopFormMolecules.vue"; // @ is an alias to /src
import { ApiUrlEnv } from "@/env/ApiUrlEnv";
import {
  DataType,
  RequestParam,
  ValidationResult,
} from "@/interface/maildelivery/MailDeliveryStopInterFaces";
import { Messages } from "@/utils/message/MaildeliveryMessages";
import { MaildeliveryInternalCodeEnum } from "@/utils/message/num/MaildeliveryInternalCodeEnum";
import { Validater } from "@/utils/validater/MaildeliveryValidaters";
import axios from "axios";
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  name: "MailDeliveryStopOganism",
  components: {
    MailDeliveryStopForm,
    ErrorMessageAreaModules,
  },
  data(): DataType {
    return {
      email: "",
      isError: false,
      errorMessage: "",
      validationResult: [
        {
          // バリデーション項目
          inputName: "",
          // エラーコード
          internalCode: "",
          // バリデーション結果
          isSuccess: true,
          // バリデーションメッセージ
          message: "",
        },
      ],
    };
  },
  methods: {
    // VuexからMutationsを呼び出す
    ...mapMutations(["setLoadingStatus"]),

    /**
     * メール配信停止を行うメソッド
     * ・バリデーションを行います
     * ・メール配信停止APIへのリクエストを行います。
     *
     * @return void
     */
    mailDeliveryStop() {
      // エラーかどうかを確認する
      if (!this.validation()) {
        this.isError = true;
        return;
      }
      this.isError = false;

      // メール配信停止APIへのリクエストを行う
      this.mailDeliveryStopRequest();
    },

    /**
     * メール配信停止APIへのリクエストを行う
     *
     * @return void
     */
    mailDeliveryStopRequest() {
      const MailDeliveryMessages = new Messages();
      // ローディング画面を表示
      this.setLoadingStatus(true);
      // JSON形式でのリクエストデータを作成する
      const params: RequestParam = {
        mailAddress: this.email,
      };

      const header = { "x-api-key": this.mailDeliveryApiKey };
      axios
        .post(this.mailDeliveryApiUrl, params, { headers: header })
        .then((res) => {
          // エラーがあった場合は表示
          if (res.data.internalCode != "N1") {
            this.errorMessage = MailDeliveryMessages.generateMessage(
              res.data.internalCode
            );
            this.isError = true;
            this.setLoadingStatus(false);
            return;
          }
          // ローディング画面を非表示
          this.isError = false;
          this.setLoadingStatus(false);
          this.$router.push("/mail_delivery/completion");
        })
        .catch((err) => {
          // ローディング画面を非表示
          this.setLoadingStatus(false);
          if (!this.isError) {
            this.isError = true;
            this.errorMessage = MailDeliveryMessages.generateMessage(
              MaildeliveryInternalCodeEnum.InternalServerError
            );
          }
        });
    },

    /**
     * Validationクラスを使用してバリデーションを行う
     *
     * @return false：エラーあり、true：エラーなし
     */
    validation(): boolean {
      const MailDeliveryMessages = new Messages();
      // バリデーションを行う
      const validationResult: ValidationResult = Validater.validateMaildelivery(
        this.email
      );

      // 結果を判定する
      if (!validationResult.isSuccess) {
        // エラーメッセージを取得し、格納する
        this.errorMessage = MailDeliveryMessages.generateMessage(
          validationResult.internalCode
        );
        return false;
      }
      return true;
    },
  },
  computed: {
    // Gettersの呼び出し
    ...mapGetters(["getLoadingStatus"]),
    /**
     * メール配信停止APIのURLを返却します。
     *
     * @returns メール配信停止APIのURL
     */
    mailDeliveryApiUrl(): string {
      return ApiUrlEnv.apiBaseUrl + "/mail-delivery/stop";
    },
    /**
     * メール配信停止API用のAPIKEYを返却します。
     *
     * @returns メール配信停止API用のAPIKEY
     */
    mailDeliveryApiKey(): string {
      return ApiUrlEnv.apiKey;
    },
  },
});
