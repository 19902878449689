<template>
  <div class="Intro-area">
    <div class="Intro-area__inner">
      <p class="Intro-area__lead">
        <strong>{{ introAreaLead1 }}<br />{{ introAreaLead2 }}</strong>
      </p>
      <p class="Intro-area__txt">
        <span>{{ introAreaTxt1 }}</span
        ><br /><span>{{ introAreaTxt2 }}</span>
        <span>{{ introAreaTxt3 }}<br /></span><span>{{ introAreaTxt4 }}</span>
      </p>
    </div>
  </div>
</template>
<!-- // templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用 -->
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LeadIntroduction",
  props: {
    // テキストボックスのタイプ
    introAreaLead1: { type: String, default: "" },
    introAreaLead2: { type: String, default: "" },
    introAreaTxt1: { type: String, default: "" },
    introAreaTxt2: { type: String, default: "" },
    introAreaTxt3: { type: String, default: "" },
    introAreaTxt4: { type: String, default: "" },
  },
});
</script>

<style scoped>
*,
::after,
::before {
  box-sizing: border-box;
}
.Intro-area__txt {
  margin-bottom: 20px;
}
@media screen and (min-width: 501px) {
  .Intro-area {
    margin-bottom: 32px;
    background: #f8f5f2;
  }

  .Intro-area__inner {
    width: 840px;
    margin: 0 auto;
    padding: 30px 0 30px 290px;
    background: url(../../../assets/img_decoration03.png) no-repeat 0 50%;
    background-size: 220px auto;
    font-size: 1.5rem;
  }
  .Intro-area__lead {
    margin-bottom: 40px;
    color: #3b5e10;
    font-size: 2.5rem;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
    line-height: 1.5;
    letter-spacing: -0.01em;
  }
}
@media screen and (max-width: 500px) {
  br {
    display: none;
  }
  .Intro-area__txt span {
    display: inline-block;
  }

  .Intro-area__inner {
    padding: 20px;
  }
  .Intro-area__lead {
    margin-bottom: 20px;
    color: #3b5e10;
    font-size: 1.8rem;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
  }
}
</style>
