<template>
  <HeaderComponent pageTitle="メール配信停止完了" />
  <!-- PC画面 -->
  <div class="mail-stop">
    <div class="mail-stop-main">
      <div class="compleate-message-area">
        <p class="compleate-message">
          メールの配信停止を受け付けました。ご利用ありがとうございました。
        </p>
        <p class="compleate-caution">
          ※配信停止されるまで、しばらくお時間がかかる場合があります。ご了承ください。
        </p>
        <p class="compleate-caution">
          ※配信停止後もメールが届く場合には、コンタクトセンターまでご連絡ください。
        </p>
      </div>
      <div class="form-submit">
        <a href="http://www.lifenet-seimei.co.jp/">
          <button class="submit-button">
            <span class="button-background">ライフネット生命トップへ</span>
          </button>
        </a>
      </div>
    </div>
  </div>
  <!-- SP画面 -->
  <div class="mail-stop-sp">
    <div class="mail-stop-main">
      <div class="compleate-message-area">
        <!-- TODO 文言修正予定 -->
        <p class="compleate-message">
          メールの配信停止を受け付けました。ご利用ありがとうございました。
        </p>
        <p class="compleate-caution">
          ※配信停止されるまで、しばらくお時間がかかる場合があります。ご了承ください。
        </p>
        <p class="compleate-caution">
          ※配信停止後もメールが届く場合には、コンタクトセンターまでご連絡ください。
        </p>
      </div>
      <div class="form-submit">
        <a href="http://www.lifenet-seimei.co.jp/">
          <button class="submit-button">
            <span class="button-background">ライフネット生命トップへ</span>
          </button>
        </a>
      </div>
    </div>
  </div>
  <div class="footer-copy">
    <small>
      Copyright© 2023 LIFENET INSURANCE COMPANY All rights reserved.
    </small>
  </div>
</template>
<script lang="ts">
// templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用
// 5. 特に処理の存在しないかつ他での利用が見込まれない、メール配信停止完了画面等のページについてはTemplates1枚に全てのHTMLを書いてよい。
import { defineComponent } from "vue";
import HeaderComponent from "@/components/organisms/global/HeaderComponent.vue"; // @ is an alias to /src

export default defineComponent({
  name: "MailStopCompleateTemplate",
  components: { HeaderComponent },
});
</script>

<style scoped>
.footer-copy {
  font-size: 1rem;
  letter-spacing: normal;
  margin-bottom: 10px;
  text-align: center;
  margin-top: auto;
}
@media screen and (min-width: 501px) {
  .mail-stop-sp {
    display: none;
  }
  .mail-stop {
    width: 990px;
    padding: 40px 39px 30px 39px;
    line-height: 1.71;
    background: #fff;
    margin: 0 auto;
  }

  p {
    font-size: 75%;
  }
  .compleate-message {
    margin-bottom: 40px;
    font-size: 13px;
  }
  .compleate-caution {
    font-size: 13px;
  }
  .form-submit {
    margin-top: 25px;
    padding: 9px 8px;
    overflow: hidden;
    zoom: 1;
    margin-bottom: 20px;
    display: flex;
    justify-content: end;
    width: 100%;
  }
  .form-submit .submit-button {
    position: relative;
    width: 100%;
    min-height: 70px;
    padding: 20px 45px 20px 10px;
    border: none;
    border-radius: 8px;
    background: #ff9900;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -4px rgb(0 16 14 / 31%);
    color: #fff;
    font-size: 1.7rem;
    line-height: 1;
    transition: all 0.3s ease;
  }
  .submit-button::before {
    width: 30px;
    height: 30px;
    margin: -15px 0 0 -50px;
    border-radius: 50%;
    background: #fff;
  }
  .submit-button::before,
  .submit-button::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    content: "";
  }
  .submit-button::after {
    width: 0;
    height: 0;
    margin: -8px 0 0 -38px;
    border-style: solid;
    border-width: 8px 0 8px 11px;
    border-color: transparent transparent transparent #ff9900;
  }
  .form-submit a {
    text-decoration: none;
  }
  .button-background {
    display: block;
    font-size: 2.1rem;
    font-weight: bold;
    line-height: 1.6;
    padding-right: 10px;
    margin-left: 8px;
  }
  .compleate-message-area {
    width: 746px;
  }
  .mail-stop-main {
    padding: 0 80px 0 80px;
  }
}
@media screen and (max-width: 500px) {
  .mail-stop {
    display: none;
  }
  .mail-stop-sp {
    width: 100%;
    padding: 0px 10px;
    line-height: 1.71;
    background: #fff;
    margin: 0 auto;
  }

  .compleate-message {
    margin-bottom: 40px;
    font-size: 16px;
  }
  .compleate-caution {
    font-size: 16px;
  }
  .form-submit {
    overflow: hidden;
    zoom: 1;
    justify-content: end;
    width: 100%;
    text-align: center;
  }
  .form-submit .submit-button {
    position: relative;
    width: 85%;
    min-height: 70px;
    padding: 20px 40px 20px 0px;
    margin-bottom: 50px;
    border: none;
    border-radius: 8px;
    background: #ff9900;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -4px rgb(0 16 14 / 31%);
    color: #fff;
    font-size: 1.7rem;
    line-height: 1;
    transition: all 0.3s ease;
  }
  .submit-button::before {
    width: 30px;
    height: 30px;
    margin: -15px 0 0 -50px;
    border-radius: 50%;
    background: #fff;
  }
  .submit-button::before,
  .submit-button::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    content: "";
  }
  .submit-button::after {
    width: 0;
    height: 0;
    margin: -8px 0 0 -38px;
    border-style: solid;
    border-width: 8px 0 8px 11px;
    border-color: transparent transparent transparent #ff9900;
  }
  .form-submit a {
    text-decoration: none;
  }
  .button-background {
    display: block;
    font-size: 1.85rem;
    font-weight: bold;
    line-height: 1.6;
  }
  .compleate-message-area {
    width: 100%;
    padding: 30px 10px;
  }
}
</style>
