<template>
  <div class="Birth-time">
    <div class="Select-wrap Select-wrap-years">
      <select
        name="birth_year"
        id="birthy"
        class="Custom-select Custom-select--year"
        v-model="localModelValue.birthYear"
        @change="generateDayList(), generateMonthList()"
      >
        <option label="" value="" select="selected"></option>
        <option
          v-for="(birthday, index) in birthdayList"
          :key="index"
          :label="birthday.label"
          :value="birthday.value"
        >
          {{ birthday.label }}
        </option>
      </select>
      <span class="Birth-time__txt-sp--year">年</span>
    </div>
    <span class="Birth-time__txt">年</span>
    <div class="birth-flex">
      <div class="Birth-time__item">
        <div class="Select-wrap Birth-time__date">
          <select
            name="birth_month"
            id="birthm"
            class="Custom-select Custom-select--month"
            style="visibility: visible"
            v-model="localModelValue.birthMonth"
            @change="generateDayList()"
          >
            <option value="" select="selected"></option>
            <option
              v-for="(month, index) in monthList"
              :key="index"
              :value="month"
            >
              {{ month }}
            </option>
          </select>
        </div>
        <span class="Birth-time__txt">月</span>
        <span class="Birth-time__txt-sp">月</span>
      </div>
      <div class="Birth-time__item">
        <div class="Select-wrap Birth-time__date">
          <select
            name="birth_day"
            id="birthd"
            class="Custom-select Custom-select--day"
            style="visibility: visible"
            v-model="localModelValue.birthDay"
          >
            <option value="">&nbsp;</option>
            <option v-for="(day, index) in dayList" :key="index" :value="day">
              {{ day }}
            </option>
          </select>
        </div>
        <span class="Birth-time__txt">日</span>
        <span class="Birth-time__txt-sp">日</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";
import { mapGetters } from "vuex";
import { ContractDateCalculator } from "@/services/catalog/ContractDateCalculator";

/**
 *
 */
interface BirthDayModelValue {
  birthYear: number;
  birthMonth: number;
  birthDay: number;
}
/**
 * 誕生年の定義
 */
interface BirthDay {
  value: number;
  label: string;
}
/**
 * data()の定義
 */
interface DataType {
  birthdayList: Array<BirthDay>;
  monthList: Array<number>;
  dayList: Array<number>;
  year: number;
  month: number;
  day: number;
}
export default defineComponent({
  name: "HelloWorld",

  props: {
    // テキストボックス内の入力値をコンポーネント間でバインディングするための変数
    modelValue: {
      type: Array as unknown as PropType<BirthDayModelValue>,
      default: new Array<BirthDayModelValue>(),
    },
  },

  data(): DataType {
    return {
      year: 0,
      month: 0,
      day: 0,
      // 誕生日リスト
      birthdayList: [],
      monthList: [],
      dayList: [],
    };
  },
  methods: {
    ...mapGetters(["getReEnterStatus"]),

    /**
     * 日のセレクトボックスの中身を作成します。
     */
    generateDayList() {
      // 誕生月が選択されていなかった場合、処理を中断する。
      if (!this.modelValue.birthMonth) {
        return;
      }
      // セレクトボックスの日付リストを空にする
      this.dayList = [];
      // 現在日付を取得
      const currentDate = new Date();
      // 契約基準日を追加
      const contractDate: Date =
        ContractDateCalculator.calculationContactDate(currentDate);
      // 現在選択されている月を取得
      const currentSelectMonth: number = this.modelValue.birthMonth - 1;
      // 現在選択されている年月日を定義
      let selectBirthDate: Date = new Date();
      if (this.modelValue.birthMonth && !this.modelValue.birthYear) {
        selectBirthDate = new Date(2003, currentSelectMonth, 1);
      } else {
        selectBirthDate = new Date(
          this.modelValue.birthYear,
          currentSelectMonth,
          1
        );
      }

      // 最大契約日の算出
      // 資料請求機能は本日時点で18歳以下は申し込めないので、現在年 - 18する事で算出
      const maximumContractYear: number = currentDate.getFullYear() - 18;
      // 資料請求機能は本日時点で18歳以下は申し込めないので、現在月を数値で取得
      const maximumContractMonth: number = currentDate.getMonth() + 1;
      // 資料請求機能は本日時点で18歳以下は申し込めないので、現在日を数値で取得
      const maximumContractDay: number = currentDate.getDate();

      while (selectBirthDate.getMonth() === currentSelectMonth) {
        // 配列に追加していく
        if (
          this.localModelValue.birthYear === maximumContractYear &&
          this.localModelValue.birthMonth === maximumContractMonth &&
          selectBirthDate.getDate() === maximumContractDay
        ) {
          this.dayList.push(selectBirthDate.getDate());
          break;
        }
        if (
          this.localModelValue.birthYear === contractDate.getFullYear() - 71 &&
          this.localModelValue.birthMonth === contractDate.getMonth() + 1 &&
          selectBirthDate.getDate() === 1
        ) {
          selectBirthDate.setDate(selectBirthDate.getDate() + 1);
          continue;
        }
        this.dayList.push(selectBirthDate.getDate());
        selectBirthDate.setDate(selectBirthDate.getDate() + 1);
      }

      if (!this.dayList.includes(this.localModelValue.birthDay)) {
        this.localModelValue.birthDay = 0;
      }
    },
    /**
     * 月のセレクトボックスの中身を作成します。
     */
    generateMonthList() {
      if (!this.modelValue.birthYear) {
        this.initializeDateSelectBox();
        return;
      }
      this.monthList = [];

      const currentDate = new Date();
      // 契約日付を算出
      const contractDate =
        ContractDateCalculator.calculationContactDate(currentDate);

      // 最大契約年の算出
      // 資料請求機能は本日時点で18歳以下は申し込めないので、現在年 - 18する事で算出
      const minimumContractYear = currentDate.getFullYear() - 18;
      // 最小契約年の算出
      // 資料請求機能は契約基準日時点で70歳以上は申し込めないので、現在年 - 71する事で算出
      const maximumContractYear = contractDate.getFullYear() - 71;
      if (minimumContractYear === this.localModelValue.birthYear) {
        for (let i = 0; i <= currentDate.getMonth(); i++) {
          this.monthList.push(i + 1);
        }
      } else if (maximumContractYear === this.localModelValue.birthYear) {
        for (let i = contractDate.getMonth() + 1; i <= 12; i++) {
          this.monthList.push(i);
        }
      } else {
        this.monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      }

      // 選択中の
      if (!this.monthList.includes(this.localModelValue.birthMonth)) {
        this.localModelValue.birthMonth = 0;
      }
    },

    /**
     * セレクトボックスの初期化を行います。
     */
    initializeDateSelectBox() {
      const currentDate = new Date();
      const contractDate =
        ContractDateCalculator.calculationContactDate(currentDate);
      const maxmumContractDate = new Date(
        contractDate.getFullYear() - 17,
        contractDate.getMonth(),
        1
      );
      this.birthdayList = [];
      for (let i = 0; i < 54; i++) {
        let japaneseCalenderName = "";
        const westernCalendar = maxmumContractDate.getFullYear() - (54 - i);
        if (westernCalendar > 2018) {
          if (westernCalendar - 1988 === 1) {
            japaneseCalenderName = "（令和元年）";
          } else {
            japaneseCalenderName = "（令和" + (westernCalendar - 2018) + "）";
          }
        } else if (westernCalendar > 1988) {
          if (westernCalendar - 1988 === 1) {
            japaneseCalenderName = "（平成元年）";
          } else {
            japaneseCalenderName = "（平成" + (westernCalendar - 1988) + "）";
          }
        } else {
          japaneseCalenderName = "（昭和" + (westernCalendar - 1925) + "）";
        }
        this.birthdayList.push({
          value: westernCalendar,
          label: westernCalendar + japaneseCalenderName,
        });
      }

      // 月日の初期値を設定する
      if (
        (!this.getReEnterStatus() || this.getReEnterStatus() == undefined) &&
        !this.localModelValue.birthMonth
      ) {
        this.monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        this.dayList = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
        ];
      } else if (
        !this.getReEnterStatus() ||
        this.getReEnterStatus() == undefined
      ) {
        this.monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      }
    },
  },
  computed: {
    /**
     * 親コンポーネントに値を渡す為のメソッド
     */
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue: string) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
  mounted() {
    this.initializeDateSelectBox();
  },
  updated() {
    if (
      this.modelValue.birthMonth &&
      this.modelValue.birthYear &&
      this.modelValue.birthDay &&
      this.dayList.length === 0
    ) {
      this.generateMonthList();
      this.generateDayList();
    }
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button,
select {
  text-transform: none;
}
.Select-wrap::before,
.Select-wrap::after {
  position: absolute;
  left: 100%;
  pointer-events: none;
  content: "";
}
@media screen and (min-width: 501px) {
  .birth-flex {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .Birth-time {
    display: flex;
    align-items: center;
  }
  .Select-wrap {
    position: relative;
    margin: 9px 0;
  }
  .Select-wrap::before {
    width: 44px;
    height: 100%;
    margin-left: -44px;
    border-radius: 0 4px 4px 0;
    background: #3f5d26;
  }
  .Custom-select {
    width: 100%;
    height: 46px;
    padding: 0 42px 0 18px;
    border-radius: 4px;
    border: 1px solid #3f5d26;
    font-size: inherit;
  }
  .Custom-select--year {
    width: 210px;
  }
  .Select-wrap::after {
    top: 50%;
    width: 0;
    height: 0;
    margin: -5px 0 0 -32px;
    border-width: 12px 10px 0 10px;
    border-color: #fff transparent transparent transparent;
    border-style: solid;
  }

  .Birth-time__txt {
    display: inline-block;
    margin: 0 9px 0 8px;
  }
  .Birth-time__txt-sp {
    display: none;
  }
  .Birth-time__txt-sp--year {
    display: none;
  }
  .Birth-time__item {
    display: flex;
    width: 50%;
    align-items: center;
  }
  .Birth-time__date {
    width: 107px;
  }
}
@media screen and (max-width: 500px) {
  .birth-flex {
    display: flex;
    width: 100%;
  }
  .Birth-time {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .Select-wrap {
    position: relative;
    margin: 9px 0;
    width: 100%;
  }
  .Select-wrap::before {
    width: 53px;
    height: 100%;
    margin-left: -53px;
    border-radius: 0 4px 4px 0;
    background: #3f5d26;
  }
  .Select-wrap-years::before {
    width: 53px;
    height: 100%;
    margin-left: -87px;
    border-radius: 0 4px 4px 0;
    background: #3f5d26;
  }
  .Custom-select--year {
    width: 87%;
  }
  .Custom-select--month {
    width: 100%;
  }
  .Custom-select--day {
    width: 100%;
  }
  .Custom-select {
    height: 53px;
    padding: 0 43px 0 5px;
    border-radius: 4px;
    border: 1px solid #3f5d26;
    background-color: #fff;
    color: #000;
  }

  .Select-wrap::after {
    top: 50%;
    width: 0;
    height: 0;
    margin: -5px 0 0 -36px;
    border-width: 12px 10px 0 10px;
    border-color: #fff transparent transparent transparent;
    border-style: solid;
  }
  .Select-wrap-years::after {
    top: 50%;
    width: 0;
    height: 0;
    margin: -5px 0 0 -72px;
    border-width: 12px 10px 0 10px;
    border-color: #fff transparent transparent transparent;
    border-style: solid;
  }

  .Birth-time__txt {
    display: none;
  }
  .Birth-time__txt-sp {
    display: inline-block;
    margin: 0 9px 0 8px;
  }
  .Birth-time__txt-sp--year {
    display: inline-block;
    margin: 0 0 0 13px;
  }
  .Birth-time__item {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .Birth-time__date {
    width: 100%;
  }
}
</style>
