<template>
  <div class="Date-box">
    <div class="Date-box Date-box--w03">
      <LongInputFormComponentAtoms
        :type="type"
        :formTitle="formTitle"
        :placeHolder="placeHolder"
        v-model="localModelValue"
        :maxLength="maxLength"
        :required="true"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import LongInputFormComponentAtoms from "@/components/atoms/LongInputFormComponentAtoms.vue";

export default defineComponent({
  name: "HelloWorld",
  components: {
    LongInputFormComponentAtoms,
  },
  props: {
    // テキストボックスのタイプ
    type: { type: String, default: "" },
    // フォームのタイトル
    formTitle: { type: String, default: "" },
    // テキストボックスのプレースホルダ
    placeHolder: { type: String, default: "" },
    // テキストボックス内の入力値をコンポーネント間でバインディングするための変数
    modelValue: { type: String, default: "" },
    // テキストボックスの最大入力値
    maxLength: { type: Number, default: 1 },
    // 入力値のサンプル
    sample: { type: String, default: "" },
    // true : 必須入力 false : 必須でない入力
    required: { type: Boolean, default: false },
    // メールアドレス形式の入力
    email: { type: Boolean, default: false },
  },
  computed: {
    /**
     * 親コンポーネントに値を渡す為のメソッド
     */
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue: string) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Date-box {
  width: 100%;
}
</style>
