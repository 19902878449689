<template>
  <div class="body">
    <HeaderComponent pageTitle="電話＆オンラインでの保険相談（無料）予約完了" />
    <IntroductionCompleteComponent
      titleHead1="ご予約ありがとうございます"
      titleHead2="保険相談のご予約を承りました。"
      textRow1=" このたびは、保険相談のご予約をいただき、誠にありがとうございました。"
      textRow2="ご予約いただいた"
      textRow3="に担当の保険プランナーよりお電話いたします。"
      textRow4="当日の混雑状況によっては、お電話の時間が多少前後する可能性もございますのでご了承ください。"
      textRow5="なおご予約内容の変更は、お手数をおかけしますがコンタクトセンターにてお電話でお申しつけください。"
      :reserveDate="reserveDateTimeMessage"
    />
    <ReserveCompleteOrganism />
    <FooterComponent />
  </div>
</template>
<script lang="ts">
// templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用
import { defineComponent } from "vue";
import HeaderComponent from "@/components/organisms/global/HeaderComponent.vue"; // @ is an alias to /src
import IntroductionCompleteComponent from "@/components/organisms/global/IntroductionCompleteComponent.vue"; // @ is an alias to /src
import ReserveCompleteOrganism from "@/components/organisms/reserve/ReserveCompleteOrganism.vue"; // @ is an alias to /src
import FooterComponent from "@/components/organisms/global/FooterComponent.vue"; // @ is an alias to /src
import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  name: "ReserveComplete",
  components: {
    HeaderComponent,
    IntroductionCompleteComponent,
    ReserveCompleteOrganism,
    FooterComponent,
  },
  data() {
    return {
      reserveDateTimeMessage: "",
    };
  },
  methods: {
    ...mapGetters(["getReserveDateTimeMessage"]),
  },
  mounted() {
    this.reserveDateTimeMessage = this.getReserveDateTimeMessage();
  },
});
</script>
<style scoped>
.body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Hiragino Sans", Meiryo, sans-serif;
  font-size: 1.6rem;
  color: #333;
  letter-spacing: 0.08em;
  line-height: 1.8;
  letter-spacing: 0.08em;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  min-height: 100vh;
}
</style>
