<template>
  <div class="Date-box_test">
    <label class="Date-name_test">
      {{ formTitle }}
      <InputFormMiniComponentAtoms
        :type="type"
        :formTitle="formTitle"
        :placeHolder="placeHolder"
        v-model="localModelValue"
        :maxLength="maxLength"
        :required="true"
        :kanaInput="kanaInput"
      />
    </label>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import InputFormMiniComponentAtoms from "@/components/atoms/InputFormMiniComponentAtoms.vue";

export default defineComponent({
  name: "HelloWorld",
  components: {
    InputFormMiniComponentAtoms,
  },
  props: {
    // テキストボックスのタイプ
    type: { type: String, default: "" },
    // フォームのタイトル
    formTitle: { type: String, default: "" },
    // テキストボックスのプレースホルダ
    placeHolder: { type: String, default: "" },
    // テキストボックス内の入力値をコンポーネント間でバインディングするための変数
    modelValue: { type: String, default: "" },
    // テキストボックスの最大入力値
    maxLength: { type: Number, default: 1 },
    // 入力値のサンプル
    sample: { type: String, default: "" },
    // true : 必須入力 false : 必須でない入力
    required: { type: Boolean, default: false },
    // メールアドレス形式の入力
    email: { type: Boolean, default: false },
    // 電話番号形式の入力
    telNumber: { type: Boolean, default: false },
    // 郵便番号形式の入力
    postNumber: { type: Boolean, default: false },
    //カタカナへの変換
    kanaInput: { type: Boolean, default: false },
  },
  computed: {
    /**
     * 親コンポーネントに値を渡す為のメソッド
     */
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue: string) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (min-width: 501px) {
  .Date-box_test {
    display: flex;
    max-width: 532px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .Date-name_test {
    display: flex;
    width: 254px;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 500px) {
  .Date-box_test {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .Date-name_test {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
  }
}
</style>
