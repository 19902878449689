/**
 * 内部コードを列挙します
 */
export enum MaildeliveryInternalCodeEnum {
  // 正常終了
  Successful = "N1",
  // メールアドレス形式エラー
  MailAddressFormatError = "E1",
  // メールアドレス未入力エラー
  MailAddressEmptyError = "E2",
  // メールアドレス制限文字数超過エラー
  MailAddressCountOverError = "E3",
  // データベース情報取得エラー
  DynamoDbGetItemFailed = "E4",
  // データベース接続エラー
  DynamoDbConnectionFailed = "E101",
  // サーバエラー
  InternalServerError = "E102",

  // FamilyNameEmptyError = "",
  // FamilyNameFormatError = "",
  // FamilyNameCountOverError = "",
  // FirstNameEmptyError = "",
}
