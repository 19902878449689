<template>
  <footer id="lnrub-footer-area" class="Footer">
    <div class="Footer__inner">
      <div class="Footer__logo">
        <p>
          <img
            src="../../../assets/footer_logo.png"
            alt="人生に、大切なことを、わかりやすく。 ライフネット生命保険株式会社"
          />
        </p>
        <p class="Footer-copy">
          <small
            >Copyright© {{ new Date().getFullYear() }} LIFENET INSURANCE COMPANY
            All rights reserved.</small
          >
        </p>
      </div>
      <ul id="lnrub-footer-utility" class="Footer-help">
        <li class="Footer-help__item">
          <a href="https://www.lifenet-seimei.co.jp/" class="Link-arw"
            >トップページ</a
          >
        </li>
        <li class="Footer-help__item">
          <a href="https://www.lifenet-seimei.co.jp/usage/" class="Link-arw"
            >利用規約</a
          >
        </li>
        <li class="Footer-help__item">
          <a
            href="https://www.lifenet-seimei.co.jp/policy/security/"
            class="Link-arw"
            >情報セキュリティ</a
          >
        </li>
        <li class="Footer-help__item">
          <a
            href="https://www.lifenet-seimei.co.jp/policy/invitepolicy/"
            class="Link-arw"
            >勧誘方針</a
          >
        </li>
        <li class="Footer-help__item">
          <a
            href="https://www.lifenet-seimei.co.jp/policy/privacy/"
            class="Link-arw"
            >個人情報保護宣言</a
          >
        </li>
        <li class="Footer-help__item">
          <a
            href="https://www.lifenet-seimei.co.jp/requirements/"
            class="Link-arw"
            >推奨環境</a
          >
        </li>
        <li class="Footer-help__item">
          <a href="https://www.lifenet-seimei.co.jp/sitemap/" class="Link-arw"
            >サイトマップ</a
          >
        </li>
      </ul>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterComponent",
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*,
::after,
::before {
  box-sizing: border-box;
}
img {
  vertical-align: middle;
  border-style: none;
}
.Link-arw {
  display: inline-block;
  padding-left: 11px;
  text-indent: -11px;
  color: #0044cc;
}
.Link-arw::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-color: transparent transparent transparent #0044cc;
  content: "";
}

@media screen and (min-width: 501px) {
  .Footer-help__item:nth-of-type(2),
  .Footer-help__item:nth-of-type(6) {
    width: 105px;
  }
  .Footer {
    margin-top: 60px;
    padding: 30px 0;
    background: #fff;
  }
  .Footer-help {
    display: flex;
    margin-right: -40px;
    flex-wrap: wrap;
    list-style: none;
  }
  .Footer-help__item {
    width: 160px;
    margin: 5px 0;
    padding-right: 20px;
    font-size: 1.4rem;
  }
  .Footer__inner {
    display: flex;
    width: 940px;
    margin: 0 auto;
    align-items: center;
  }

  .Footer-copy {
    font-size: 1rem;
    letter-spacing: normal;
  }
  .Footer__logo img {
    width: 350px;
  }
}
@media screen and (max-width: 500px) {
  .Footer {
    overflow: hidden;
    margin-top: 30px;
    background: #fff;
  }
  .Footer-help {
    display: flex;
    width: 250px;
    margin: 0 auto;
    padding: 10px 0;
    flex-wrap: wrap;
    list-style: none;
  }
  .Footer-help__item {
    width: 50%;
    margin: 5px 0;
    font-size: 1.4rem;
    white-space: nowrap;
  }
  .Footer-help__item:nth-of-type(even) {
    padding-left: 35px;
    width: 110px;
  }
  .Footer__inner {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
  }

  .Footer-copy {
    font-size: 1rem;
    letter-spacing: normal;
    margin-bottom: 10px;
  }
  .Footer__logo {
    border-top: 1px solid #afd83a;
    text-align: center;
    width: 100%;
  }
  .Footer__logo img {
    width: 258px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
