import { AbstractMessages } from "./AbstractMessages";

export class Messages extends AbstractMessages {
  /**
   * 内部コードからメッセージを生成します。
   *
   * @param internalCode
   * @returns
   */
  generateMessage(internalCode: string): string {
    switch (internalCode) {
      case "N1":
        return "正常終了。";
      case "E1":
        return "メールアドレスまたは携帯電話番号をご確認ください。";
      case "E2":
        return "メールアドレスまたは携帯電話番号を入力してください。";
      case "E3":
        return "お役立ち情報を受け取るの入力が不正です。";
      case "E4":
        return "メソッドに異常値が入力されました。";
      case "E5":
        return "画面遷移用パラメータに異常値が入力されました。";
      case "E6":
        return "見積り年が未入力です。";
      case "E7":
        return "見積り年に数値以外の文字が含まれています。";
      case "E8":
        return "見積り年は4文字以内で入力してください。";
      case "E9":
        return "見積り年は1900～2999の範囲で指定してください。";
      case "E10":
        return "見積り月が未入力です。";
      case "E11":
        return "見積り月に数値以外の文字が含まれています。";
      case "E12":
        return "見積り月は2文字以内で入力してください。";
      case "E13":
        return "見積り月は1～12の範囲で指定してください。";
      case "E14":
        return "見積り日が未入力です。";
      case "E15":
        return "見積り日に数値以外の文字が含まれています。";
      case "E16":
        return "見積り日は2文字以内で入力してください。";
      case "E17":
        return "見積り日は1～31の範囲で指定してください。";
      case "E18":
        return "誕生年が未入力です。";
      case "E19":
        return "誕生年に数値以外の文字が含まれています。";
      case "E20":
        return "誕生年は1900～2999の範囲で指定してください。";
      case "E21":
        return "誕生月が未入力です。";
      case "E22":
        return "誕生月に数値以外の文字が含まれています。";
      case "E23":
        return "誕生月は1～12の範囲で指定してください。";
      case "E24":
        return "誕生日が未入力です。";
      case "E25":
        return "誕生日に数値以外の文字が含まれています。";
      case "E26":
        return "誕生日は1～31の範囲で指定してください。";
      case "E27":
        return "契約年齢が未入力です。";
      case "E28":
        return "契約年齢に数値以外の文字が含まれています。";
      case "E29":
        return "契約年齢は18～70の範囲で指定してください。";
      case "E30":
        return "性別の値が不正です。";
      case "E31":
        return "保険商品選択（定期死亡保険）が未入力です。";
      case "E32":
        return "保険商品選択（定期死亡保険）に数値以外の文字が含まれています。";
      case "E33":
        return "険商品選択（定期死亡保険）は9文字以内で入力してください。";
      case "E34":
        return "険商品選択（定期死亡保険）は300～10000の範囲で指定してください。";
      case "E35":
        return "保険期間（定期死亡保険）が選択可能期間外です。";
      case "E36":
        return "月額保険料（定期死亡保険）が未入力です。";
      case "E37":
        return "月額保険料（定期死亡保険）に数値以外の文字が含まれています。";
      case "E38":
        return "月額保険料（定期死亡保険）は0～9999999999の範囲で指定してください。";
      case "E39":
        return "保険期間（定期療養保険）が選択可能期間外です。";
      case "E40":
        return "保険期間（定期療養保険）が未入力です。";
      case "E41":
        return "保険期間（定期療養保険）に数値以外の文字が含まれています。";
      case "E42":
        return "保険期間（定期療養保険）は0～9999999999の範囲で指定してください。";
      case "E43":
        return "入院給付金日額（終身医療保険（じぶんへの保険3））が不正です。";
      case "E44":
        return "保険料払込期間（終身医療保険（じぶんへの保険3））が不正です。";
      case "E45":
        return "販売コース（終身医療保険（じぶんへの保険3））が不正です。";
      case "E46":
        return "月額保険料（終身医療保険（じぶんへの保険3））が未入力です。";
      case "E47":
        return "月額保険料（終身医療保険（じぶんへの保険3））に数値以外の文字が含まれています。";
      case "E48":
        return "月額保険料（終身医療保険（じぶんへの保険3））は9文字以内で入力してください。";
      case "E49":
        return "月額保険料（終身医療保険（じぶんへの保険3））は300～10000の範囲で指定してください。";
      case "E50":
        return "就業不能給付金月額（就業不能保険(働く人の保険3）) が未入力です。";
      case "E51":
        return "就業不能給付金月額（就業不能保険(働く人の保険3）) に数値以外の文字が含まれています。";
      case "E52":
        return "就業不能給付金月額（就業不能保険(働く人の保険3）) は9文字以内で入力してください。";
      case "E53":
        return "就業不能給付金月額（就業不能保険(働く人の保険3）) は10～50の範囲で指定してください。";
      case "E54":
        return "保険期間（就業不能保険(働く人の保険3）)が不正です。";
      case "E55":
        return "免責期間（就業不能保険(働く人の保険3）)が不正です。";
      case "E56":
        return "初期削減給付有無（就業不能保険(働く人の保険3）)が不正です。";
      case "E57":
        return "復帰支援一時金特則有無（就業不能保険(働く人の保険3）)が不正です。";
      case "E58":
        return "月額保険料（就業不能保険(働く人の保険3）)が未入力です。";
      case "E59":
        return "月額保険料（就業不能保険(働く人の保険3）)に数値以外の文字が含まれています。";
      case "E60":
        return "月額保険料（就業不能保険(働く人の保険3）)は0～9999999999の範囲で指定してください。";
      case "E61":
        return "がん診断一時金給付金額（がん保険）が不正です。";
      case "E62":
        return "販売コース（がん保険）が不正です。";
      case "E63":
        return "月額保険料（がん保険）が未入力です。";
      case "E64":
        return "月額保険料（がん保険）に数値以外の文字が含まれています。";
      case "E65":
        return "月額保険料（がん保険）は0～9999999999の範囲で指定してください。";
      case "E66":
        return "合計保険料が未入力です。";
      case "E67":
        return "合計保険料に数値以外の文字が含まれています。";
      case "E68":
        return "合計保険料は0～9999999999の範囲で指定してください。";
      case "E69":
        return "認知症保険のみを選択してください。";
      case "E70":
        return "販売コース（認知症保険）が不正です。";
      case "E71":
        return "認知症診断一時金給付金額が不正です。";
      case "E72":
        return "月額保険料（認知症保険）が未入力です。";
      case "E73":
        return "月額保険料（認知症保険）に数値以外の文字が含まれています。";
      case "E74":
        return "月額保険料（認知症保険）は0～9999999999の範囲で指定してください。";
      case "E75":
        return "見積り画面URLが未入力です。";
      case "E101":
        return "サーバー内部でエラーが発生しました、時間を空けて再度リクエストしてください。";
      case "E102":
        return "サーバー内部でエラーが発生しました、時間を空けて再度リクエストしてください。";
      default:
        return "";
    }
  }
}
