import { ValidationResult } from "@/interface/common/CommonValidatersInterface";
import { RequestParam } from "@/interface/reserve/ReserveInputInterFaces";
import { Messages } from "../message/ReserveMessages";
import { ReserveInternalCodeEnum } from "../message/num/ReserveInternalCodeEnum";
import { CommonValidater } from "./common/CommonValidaters";

export class Validater {
  private static internalErrorCodeType = "reserve";
  private static ReserveMessages = new Messages();
  /**
   * バリデーションチェックを行います。
   * ・バリデーションエラーになった場合falseを返却し、alertメッセージを表示する
   *
   * @return {boolean}
   */

  public static validateReserve(
    reserveInput: RequestParam
  ): Array<ValidationResult> {
    return this.validateReserveDateId(reserveInput.reserveDateId)
      .concat(
        CommonValidater.validateKanaName(
          reserveInput.familyNameKana,
          reserveInput.firstNameKana,
          this.ReserveMessages,
          this.internalErrorCodeType
        )
      )
      .concat(this.validateGender(reserveInput.gender))
      .concat(
        CommonValidater.validatePhoneNumber(
          reserveInput.primaryPhone,
          this.ReserveMessages,
          this.internalErrorCodeType
        )
      )
      .concat(this.validateConcernItems(reserveInput.concern))
      .concat(this.validateScreenSharingFlg(reserveInput.screenSharingFlg))
      .concat(this.validateKrateUser(reserveInput.karteUserId));
  }

  /**
   *
   * @param reserveDateId 予約日
   * @returns 予約日バリデーション結果
   */
  public static validateReserveDateId(
    reserveDateId: string
  ): Array<ValidationResult> {
    const validationResultList: Array<ValidationResult> =
      new Array<ValidationResult>();
    if (reserveDateId == "") {
      validationResultList.push({
        inputName: "reserve-date",
        internalCode: ReserveInternalCodeEnum.GetEntityFailed,
        isSuccess: false,
        message: this.ReserveMessages.generateMessage(
          ReserveInternalCodeEnum.GetEntityFailed
        ),
      });
    } else {
      if (reserveDateId.length > 255) {
        validationResultList.push({
          inputName: "reserve-date",
          internalCode: ReserveInternalCodeEnum.DataIsLogicalDeleted,
          isSuccess: false,
          message: this.ReserveMessages.generateMessage(
            ReserveInternalCodeEnum.DataIsLogicalDeleted
          ),
        });
      }
    }
    return validationResultList;
  }

  /**
   * 性別のバリデーションチェックを行います
   *
   * @param gender
   * @returns バリデーション結果
   */
  public static validateGender(gender: number): Array<ValidationResult> {
    // 返却用Arrayクラスを定義
    const validationResultList: Array<ValidationResult> =
      new Array<ValidationResult>();
    // 性別が未入力若しくは異常値である事をチェックする
    if (!gender || (gender != 1 && gender != 2)) {
      // バリデーション結果にエラー内容を追加する
      validationResultList.push({
        inputName: "b04_00",
        internalCode: ReserveInternalCodeEnum.GenderIsInvalidError,
        isSuccess: false,
        message: this.ReserveMessages.generateMessage(
          ReserveInternalCodeEnum.GenderIsInvalidError
        ),
      });
    }
    return validationResultList;
  }

  /**
   *
   * @param concern 気になる項目のリスト
   * @returns 気になる項目のバリデーション結果
   */
  public static validateConcernItems(
    concern: number[]
  ): Array<ValidationResult> {
    const validationResultList: Array<ValidationResult> =
      new Array<ValidationResult>();
    // 気になる項目
    for (let i = 0; i < concern.length; i++) {
      if (
        concern[i] == 1 ||
        concern[i] == 2 ||
        concern[i] == 3 ||
        concern[i] == 4 ||
        concern[i] == 5 ||
        concern[i] == 99
      ) {
        // 正常
      } else {
        validationResultList.push({
          inputName: "input_concern",
          internalCode: ReserveInternalCodeEnum.ConcernIsInvalidError,
          isSuccess: false,
          message: this.ReserveMessages.generateMessage(
            ReserveInternalCodeEnum.ConcernIsInvalidError
          ),
        });
      }
    }
    return validationResultList;
  }

  /**
   *
   * @param screenSharingFlg 画面共有サポート利用フラグ
   * @returns 画面共有サポート利用フラグバリデーション結果
   */
  public static validateScreenSharingFlg(
    screenSharingFlg: number
  ): Array<ValidationResult> {
    const validationResultList: Array<ValidationResult> =
      new Array<ValidationResult>();
    // 画面共有サポート利用
    if (screenSharingFlg) {
      if (screenSharingFlg === 0 || screenSharingFlg === 1) {
        // 正常
      } else {
        validationResultList.push({
          inputName: "input_screen_sharing_flg_item",
          internalCode: ReserveInternalCodeEnum.ScreenSharingFlgIsInvalidError,
          isSuccess: false,
          message: this.ReserveMessages.generateMessage(
            ReserveInternalCodeEnum.ScreenSharingFlgIsInvalidError
          ),
        });
      }
    }
    return validationResultList;
  }

  /**
   *
   * @param karteUserId KrateUserId
   * @returns KrateUserIdバリエーション結果
   */
  public static validateKrateUser(
    karteUserId: string
  ): Array<ValidationResult> {
    const validationResultList: Array<ValidationResult> =
      new Array<ValidationResult>();
    // KARTEユーザー
    if (karteUserId.length >= 255) {
      validationResultList.push({
        inputName: "karteUserId",
        internalCode: ReserveInternalCodeEnum.KarteUserIdCountOverError,
        isSuccess: false,
        message: this.ReserveMessages.generateMessage(
          ReserveInternalCodeEnum.KarteUserIdCountOverError
        ),
      });
    }
    return validationResultList;
  }
}
