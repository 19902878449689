<template>
  <body>
    <MailStopCompleateTemplate />
  </body>
</template>
<script lang="ts">
// Pagesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. (場合によってoganismsに責務を持たせるが)エラーハンドリング用Vuexステータスを操作
// 4. ページ(アプリケーション)全体に及ぶCSSの適用
import { defineComponent } from "vue";
import MailStopCompleateTemplate from "@/components/templates/maildelivery/MailDeliveryStopCompleateTemplate.vue"; // @ is an alias to /src
import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  name: "MailStopInputPage",
  components: {
    MailStopCompleateTemplate,
  },
  methods: {
    ...mapMutations(["setLoadingStatus"]),
  },
  computed: {
    ...mapGetters(["getLoadingStatus"]),
  },
  mounted() {
    this.setLoadingStatus(true);
    setTimeout(() => {
      this.setLoadingStatus(false);
    }, 1000);
  },
});
</script>
<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Hiragino Sans", Meiryo, sans-serif;
  font-size: 1.6rem;
  color: #333;
  letter-spacing: 0.08em;
  line-height: 1.8;
  letter-spacing: 0.08em;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
</style>
