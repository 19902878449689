import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bdeb6f82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_LeadIntroductionOrganism = _resolveComponent("LeadIntroductionOrganism")!
  const _component_LeadCompleteOrganism = _resolveComponent("LeadCompleteOrganism")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderComponent, { pageTitle: "見積り内容をメールで送信（受付完了）" }),
    _createVNode(_component_LeadIntroductionOrganism, {
      introAreaLead1: "お見積りの内容を、ご入力いただいたメール",
      introAreaLead2: "アドレスにお送りします。",
      introAreaTxt1: "しばらくしてもメールが届かない場合は、ご入力いただいたメールアドレスが誤っている可能性があります。お手数ですが、再度「保険料見積り」ページから、やり直してください。",
      introAreaTxt2: "なお、携帯メールを宛先にされた場合は、迷惑メール防止機能で受信拒否されないよう以下ドメインからのメールを受信可能にご設定ください。",
      introAreaTxt3: "「@lifenet-seimei.co.jp」",
      introAreaTxt4: "「@mail.lifenet-seimei.co.jp」"
    }),
    _createVNode(_component_LeadCompleteOrganism),
    _createVNode(_component_FooterComponent)
  ]))
}