<template>
  <!-- 資料請求画面「保険選びが～」～「守られております」 -->
  <div class="Intro-area">
    <div class="Intro-area__inner">
      <p class="Intro-area__lead">
        <strong>{{ introAreaLead1 + introAreaLead2 }}</strong>
      </p>
    </div>
  </div>
  <div class="Intro-area-sp">
    <p class="Intro-area_txt">{{ introAreaLead1 }}<br />{{ introAreaLead2 }}</p>
  </div>
  <p class="Start-band">
    <strong class="Start-band__em">{{ threeMinutes }}</strong>
    {{ easyInput }}
  </p>
  <div class="l-base">
    <!-- +block-header -->
    <div class="Block-head">
      <h2 class="Block-ttl">{{ blockTitle }}</h2>
      <!--/error-block-->
      <p class="Block-head__txt">
        <span class="Ico-ssl">{{ encryptionCommunication }}</span>
      </p>
    </div>
  </div>
</template>
<script lang="ts">
// templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用
import { defineComponent } from "vue";
export default defineComponent({
  name: "CatalogIntroduction",
  props: {
    introAreaLead1: { introAreaLead1: String, default: "" },
    introAreaLead2: { introAreaLead2: String, default: "" },
    threeMinutes: { threeMinutes: String, default: "" },
    easyInput: { easyInput: String, default: "" },
    blockTitle: { blockTitle: String, default: "" },
    encryptionCommunication: { encryptionCommunication: String, default: "" },
  },
});
</script>
<style scoped>
.Intro-area__lead {
  color: #3b5e10;
  font-size: 3.2rem;
  line-height: 1;
  text-align: center;
}
p {
  margin: 0;
}
.Start-band::after {
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin: -1px 0 0 -25px;
  border-width: 22px 25px 0 25px;
  border-color: #80b735 transparent transparent transparent;
  border-style: solid;
  content: "";
}
.Ico-ssl:before {
  display: inline-block;
  width: 17px;
  height: 19px;
  margin-right: 10px;
  background: url(../../../assets/icon_ssl.gif) no-repeat 0 0;
  vertical-align: text-top;
  content: "";
}
@media screen and (min-width: 501px) {
  .l-base {
    width: 1040px;
    margin: 0 auto;
    background-color: #fafafa;
  }
  .Intro-area {
    background: #f8f5f2;
  }
  .Intro-area-sp {
    display: none;
  }
  .Intro-area__inner {
    width: 1040px;
    margin: 0 auto;
    padding: 30px 80px;
  }

  strong,
  em {
    font-style: normal;
    font-weight: normal;
  }
  .Start-band {
    display: flex;
    position: relative;
    height: 60px;
    margin-bottom: 77px;
    background: #80b735;
    color: #fff;
    font-size: 2.8rem;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .Start-band__em {
    color: #ff0;
    font-style: normal;
    font-weight: normal;
  }
  .Block-head {
    margin: 32px 100px;
  }
  .Block-ttl {
    padding-bottom: 32px;
    border-bottom: 1px solid #3b5e10;
    color: #3b5e10;
    font-size: 3.4rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.1;
    letter-spacing: normal;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
  }

  .Block-head__txt {
    margin-top: 32px;
    text-align: center;
  }
}

@media screen and (max-width: 501px) {
  .l-base {
    width: 100%;
    margin: 0 auto;
    background-color: #fafafa;
  }
  .Intro-area {
    display: none;
  }
  .Intro-area-sp {
    background: #f8f5f2;
    width: 100%;
  }
  .Intro-area__inner {
    width: 100%;
    margin: 0 auto;
    padding: 30px 80px;
  }

  .Intro-area_txt {
    font-size: 2.2rem;
    line-height: 1.4;
    color: #6c8939;
    padding: 10px 30px;
    font-weight: bold;
    background: #f8f5f2;
  }

  .Start-band {
    display: flex;
    position: relative;
    margin-bottom: 40px;
    background: #80b735;
    color: #fff;
    font-size: 4.2vw;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .Start-band__em {
    margin-right: 3px;
    color: #ff0;
    font-size: 6.8vw;
    font-weight: 400;
  }
  .Block-head {
    padding: 0 10px 0 10px;
  }
  .Block-ttl {
    padding-bottom: 20px;
    border-bottom: 1px solid #3b5e10;
    color: #3b5e10;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0;
  }

  .Block-head__txt {
    margin: 20px 0;
  }
  .Ico-ssl {
    display: block;
    text-indent: -27px;
    padding-left: 27px;
    width: 95%;
    margin: 0 auto;
    font-size: 15px;
  }
}
</style>
