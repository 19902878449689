<template>
  <div class="Intro-area">
    <div class="Intro-area__inner">
      <p class="Intro-area__lead">
        <strong>{{ titleHead1 }}<br />{{ titleHead2 }}</strong>
      </p>
      <p class="Intro-area__txt">
        {{ textRow1 }}<br />{{ textRow2 }}<br /><strong class="Intro-area__em"
          >&nbsp;{{ reserveDate }}</strong
        ><br />{{ textRow3 }}<br />{{ textRow4 }}<br />{{ textRow5 }}
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "IntroductionComponent",
  props: {
    titleHead1: { Type: String, default: "" },
    titleHead2: { Type: String, default: "" },
    textRow1: { Type: String, default: "" },
    textRow2: { Type: String, default: "" },
    textRow3: { Type: String, default: "" },
    textRow4: { Type: String, default: "" },
    textRow5: { Type: String, default: "" },
    reserveDate: { type: String, default: "" },
  },
});
</script>
<style scoped>
*,
::after,
::before {
  box-sizing: border-box;
}
p {
  margin: 0;
}
b,
strong {
  font-weight: bolder;
}
.Intro-area__txt {
  margin-bottom: 20px;
}
.Intro-area__em {
  font-size: 2.4rem;
  color: #db0000;
  line-height: 1.4;
}
@media screen and (min-width: 501px) {
  .Intro-area {
    background: #f8f5f2;
  }

  .Intro-area__inner {
    background-image: url(../../../assets/img_decoration03.png);
  }
  .Intro-area__inner {
    width: 840px;
    margin: 0 auto;
    padding: 30px 0 30px 290px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: 220px auto;
    font-size: 1.5rem;
  }
  .Intro-area__lead {
    margin-bottom: 40px;
    color: #3b5e10;
    font-size: 2.5rem;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
    line-height: 1.5;
    letter-spacing: -0.01em;
  }
}
@media screen and (max-width: 500px) {
  .Intro-area__inner {
    padding: 20px;
  }
  .Intro-area__lead {
    margin-bottom: 20px;
    color: #3b5e10;
    font-size: 1.8rem;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
  }
}
</style>
