<template>
  <input
    v-if="modelValue == value"
    type="radio"
    :name="name"
    :value="value"
    @change="$emit('update:modelValue', $event.target!.value)"
    :id="radioId"
    class="Input-radio"
    @focusout="validate()"
    :maxlength="maxLength"
    checked
  />
  <input
    v-else
    type="radio"
    :name="name"
    :value="value"
    @change="$emit('update:modelValue', $event.target!.value)"
    :id="radioId"
    class="Input-radio"
    @focusout="validate()"
    :maxlength="maxLength"
  />
  <label :for="radioId" class="Input Input--w02 Label-radio">
    {{ title }}
  </label>
</template>

<script lang="ts">
// 1. modulesから呼ばれるこれ以上切り分けることのできない最小限の画面項目を定義する
// 2. 基本的には再利用される画面項目に関してをatomsに切り出して、処理や画面の動きを一定化する目的がある
// 3. atomsコンポーネントは外部から再利用されやすく、親コンポーネントを意識しない様に作る
//    例えば入力フォームだが、予めバリデーションやプレースホルダをpropsとして定義しておく等
import { defineComponent } from "vue";
// import { default } from '../../store/modules/catalog/catalogStatus';

/**
 * data()の返り値を指定
 */
interface DataType {
  // テキストボックスのClass名
  className: string;
  // エラーテキスト
  errorText: string;
  // テキストボックスのタイプ
  inputType: string;
}
export default defineComponent({
  name: "MaritalStatus",
  props: {
    // テキストボックスのタイプ
    type: { type: String, default: "" },
    // フォームのタイトル
    title: { type: String, default: "" },
    // テキストボックスのプレースホルダ
    placeHolder: { type: String, default: "" },
    // テキストボックス内の入力値をコンポーネント間でバインディングするための変数
    modelValue: { type: String, default: "" },
    // テキストボックスの最大入力値
    maxLength: { type: Number, default: 1 },
    // true : 必須入力 false : 必須でない入力
    required: { type: Boolean, default: false },
    // メールアドレス形式の入力
    email: { type: Boolean, default: false },
    // 電話番号形式の入力
    telNumber: { type: Boolean, default: false },
    // 郵便番号形式の入力
    postNumber: { type: Boolean, default: false },
    value: { type: String, required: true, default: "" },
    name: { type: String, required: true },
    radioId: { type: String, required: true },
  },
  data(): DataType {
    return {
      className: "Input-radio_test",
      errorText: "",
      inputType: this.type,
    };
  },
  methods: {
    /**
     * バリデーションメソッド
     * ・propsの情報を元に必要なバリデーションを行う
     * ・バリデーションエラーの場合、エラーメッセージを表示します
     *
     * @return void
     */
    validate() {
      // 必須チェック
      if (!this.modelValue && this.required) {
        this.className = "error-form";
        this.errorText = this.title + " は必須入力です。";
        return;
      }
    },
  },
  computed: {},
  watch: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Input-radio:checked + .Label-radio::after {
  display: block;
  left: 22px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
}
.Input-radio {
  display: flex;
  left: -8px;
  width: 24px;
  height: 24px;
  position: relative;
}
.Input-radio:checked + .Label-radio {
  padding: 9px 13px 0 54px;
  border: 2px solid #3f5d26;
  background: #f4faeb;
}
.Input-radio:checked + .Label-radio::before {
  border: 2px solid #3f5d26;
  background: #3f5d26;
}

.Input {
  width: 100%;
  min-height: 46px;
  margin: 9px 0;
  padding: 0 18px;
  border: 1px solid #3f5d26;
  border-radius: 4px;
}
button,
input {
  overflow: visible;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.Input-radio {
  position: absolute;
  left: -9999px;
}
.Label-radio {
  display: block;
  position: relative;
  padding: 10px 10px 0 55px;
  cursor: pointer;
  font-size: 1.5rem;
  letter-spacing: normal;
}
.Label-radio::before {
  left: 16px;
  width: 24px;
  height: 24px;
  margin-top: -12px;
}
.Label-radio::before {
  display: block;
  position: absolute;
  top: 50%;
  border: 1px solid #3f5d26;
  border-radius: 50%;
  content: "";
}
.Label-radio::after {
  display: block;
  position: absolute;
  top: 50%;
  border-radius: 50%;
  content: "";
  background: #f4faeb;
}

@media screen and (min-width: 501px) {
  .Input--w02 {
    width: 261px;
    background: #fff;
  }
}
@media screen and (max-width: 500px) {
  .Input--w02 {
    width: 100%;
    background: #fff;
  }
}
</style>
