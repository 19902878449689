export class MeasurementParamGenerater {
  /**
   * 性別をコード値から変換します。
   *
   * @param InsuredPersonGender 性別コード値
   * @returns 男性 or 女性
   */
  public static convertCatalogInsuredPersonGender(
    InsuredPersonGenderCodeValue: string
  ): string {
    return InsuredPersonGenderCodeValue === "1" ? "男性" : "女性";
  }

  /**
   * 性別をコード値から変換します。
   *
   * @param InsuredPersonGender 性別コード値
   * @returns 男性 or 女性
   */
  public static convertLeadInsuredPersonGender(
    InsuredPersonGenderCodeValue: string
  ): string {
    return InsuredPersonGenderCodeValue === "male" ? "男性" : "女性";
  }

  /**
   * チャネル識別子からAAイベントを作成します
   *
   * @param channelSikibetusi チャネル識別子
   * @returns AA用イベント
   */
  public static generateCatalogAAEventByinputChannel(
    channelSikibetusi: string
  ): string {
    // チャネル識別子を元にAAの発火イベントを作成します。
    if (channelSikibetusi === "110" || channelSikibetusi === "010") {
      // SPH用のイベントを返却する
      return "event8";
    }
    // PC用のイベントを返却する
    return "event4";
  }
}
