<template>
  <div class="errArea first-errArea" id="guide">
    <img
      src="../../../assets/img_error_mark_w.jpg"
      width="24"
      height="24"
    /><span id="guide_img">下記の入力項目をご確認ください。<br /></span>
    <ul id="guide_list">
      <li v-for="(error, index) in errorList" :key="index">
        {{ error }}
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
// templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用
import { PropType, defineComponent } from "vue";

/**
 * バリデーションの結果
 */
interface ValidationResult {
  // バリデーション項目
  inputName: string;
  // エラーコード
  internalCode: string;
  // バリデーション結果
  isSuccess: boolean;
  // バリデーションメッセージ
  message: string;
}

export default defineComponent({
  name: "ErrorMessageAreaMolecules",
  props: {
    // コード値
    errorList: {
      type: Object as () => Array<string>,
      required: true,
    },
  },
});
</script>
<style scoped>
@media screen and (min-width: 501px) {
  div.first-errArea {
    width: 850px;
    margin: 0 auto;
    margin-top: 40px;
  }
  div.errArea {
    padding: 0 15px;
    margin-top: 15px;
    height: 100%;
    font-size: 90%;
    font-weight: bold;
    color: #ff6565;
  }
  div.errArea img {
    position: relative;
    left: 0px;
    vertical-align: top;
    padding-right: 10px;
    box-sizing: content-box;
  }
}
@media screen and (max-width: 500px) {
  div.first-errArea {
    width: 95%;
    padding-top: 20px;
    margin-top: 20px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  #guide_img {
    font-weight: bold;
    margin-left: 16px;
  }
  div.errArea {
    background-color: #ffd9d9;
    text-align: left;
    color: #f00;
    padding: 10px;
    margin-top: 25px;
  }
  div.errArea img {
    display: none;
  }
}
</style>
