<template>
  <tr class="Form-tbl__tr">
    <th class="Form-tbl__th Form-tbl__th--confirm">{{ tableHead }}</th>
    <TableDataAtoms :tableData="tableData" />
  </tr>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TableDataAtoms from "@/components/atoms/TableDataAtoms.vue";

export default defineComponent({
  name: "HelloWorld",
  components: {
    TableDataAtoms,
  },
  props: {
    // フォームのタイトル
    tableHead: { type: String, default: "" },
    // 確認画面の項目
    tableData: { type: String, default: "" },
  },
  data() {
    return {
      inputHashParam: "",
      isError: false,
      errorMessage: "",
    };
  },

  computed: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

th {
  text-align: inherit;
}
button,
input {
  overflow: visible;
}
.Form-tbl__th,
.Form-tbl__td {
  color: #2c4318;
}

@media screen and (min-width: 501px) {
  .Form-tbl__tr:not(:first-of-type) {
    border-top: 1px dotted #ccc;
  }
  .Form-tbl__th--confirm,
  .Form-tbl__td--confirm {
    line-height: 1.3;
    height: 46px;
  }
  .Form-tbl__th {
    white-space: pre-line;
    position: relative;
    width: calc(100% - 582px);
    padding: 0 0 0 26px;
    font-weight: normal;
    vertical-align: middle;
    align-items: center;
    letter-spacing: normal;
  }
}
@media screen and (max-width: 500px) {
  .Form-tbl__tr {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .Form-tbl__th {
    position: relative;
    width: 100%;
    padding: 10px;
    font-weight: normal;
    vertical-align: middle;
    align-items: center;
    letter-spacing: normal;
  }
  td {
    width: 100%;
  }
}
</style>
