<template>
  <div class="Date-box">
    <RadioButtonAtoms
      v-for="option in options"
      :key="option.value"
      v-model="localModelValue"
      name="label_child_number"
      :value="option.value"
      :title="option.label"
      :radioId="option.radioId"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import RadioButtonAtoms from "@/components/atoms/RadioButtonAtoms.vue";

export default defineComponent({
  name: "HelloWorld",
  components: {
    RadioButtonAtoms,
  },
  data() {
    return {
      childPresenceForm: {
        radio: "",
      },
      options: [
        {
          label: "あり",
          value: "1",
          radioId: "b06_01",
        },
        {
          label: "なし",
          value: "0",
          radioId: "b06_02",
        },
      ],
    };
  },
  props: {
    // フォームのタイトル
    formTitle: { type: String, default: "" },
    // テキストボックス内の入力値をコンポーネント間でバインディングするための変数
    modelValue: { type: String, default: "" },
  },
  computed: {
    /**
     * 親コンポーネントに値を渡す為のメソッド
     */
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue: string) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Date-box {
  display: flex;
  max-width: 532px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
button,
input {
  overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
</style>
