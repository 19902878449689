<template>
  <div class="body">
    <HeaderComponent pageTitle="電話＆オンラインでの保険相談（無料）予約" />
    <IntroductionComponent
      titleHead1="ご予約希望日、お客さま情報をご入力ください。"
      titleHead2="ご希望の時間帯に、保険プランナーよりお電話"
      titleHead3="いたします。"
      listFirstRow1="重複して登録があった場合は、最後にご予約いただいた希望時間帯に"
      listFirstRow2="お電話させていただきます。"
      listSecondRow1="こちらからお電話させていただいた際に、"
      listSecondRow2="「電話での相談」または「オンラインでの相談」のどちらを希望されるか確認させていただきます。"
      listThirdRow1="ご予約希望日時は5営業日の範囲でお選びいただけます。"
      listFourthRow1="他の日程をご希望の場合は、お手数ですが弊社コンタクトセンターまで"
      listFourthRow2="お電話にてお問い合わせください。"
    />
    <ReserveInputOrganism />
    <div class="l-baseCat"><CatalogInquiryOrganism /></div>
    <FooterComponent />
  </div>
</template>
<script lang="ts">
// templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用
import { defineComponent } from "vue";
import HeaderComponent from "@/components/organisms/global/HeaderComponent.vue"; // @ is an alias to /src
import IntroductionComponent from "@/components/organisms/global/IntroductionComponent.vue"; // @ is an alias to /src
import ReserveInputOrganism from "@/components/organisms/reserve/ReserveInputOrganism.vue"; // @ is an alias to /src
import CatalogInquiryOrganism from "@/components/organisms/catalog/CatalogInquiryOrganism.vue"; // @ is an alias to /src
import FooterComponent from "@/components/organisms/global/FooterComponent.vue"; // @ is an alias to /src

export default defineComponent({
  name: "ReserveInputTemplate　",
  components: {
    HeaderComponent,
    IntroductionComponent,
    ReserveInputOrganism,
    CatalogInquiryOrganism,
    FooterComponent,
  },
});
</script>
<style scoped>
.body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Hiragino Sans", Meiryo, sans-serif;
  font-size: 1.6rem;
  color: #333;
  letter-spacing: 0.08em;
  line-height: 1.8;
  letter-spacing: 0.08em;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  min-height: 100vh;
}
@media screen and (min-width: 501px) {
  .l-baseCat {
    width: 1040px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 500px) {
  .l-baseCat {
    padding: 0 10px;
  }
}
</style>
