import ChildPresenceConfirmMolecules from "@/components/molecules/catalog/ChildPresenceConfirmMolecules.vue"; // @ is an alias to /src
import ConcernCheckboxConfirmMolecules from "@/components/molecules/catalog/ConcernCheckboxConfirmMolecules.vue"; // @ is an alias to /src
import ErrorMessageAreaMolecules from "@/components/molecules/catalog/ErrorMessageAreaMolecules.vue"; // @ is an alias to /src
import GenderConfirmMolecules from "@/components/molecules/catalog/GenderConfirmMolecules.vue"; // @ is an alias to /src
import MaritalStatusConfirmMolecules from "@/components/molecules/catalog/MaritalStatusConfirmMolecules.vue"; // @ is an alias to /src
import MiddleTableDataConfirmMolecules from "@/components/molecules/catalog/MiddleTableDataConfirmMolecules.vue"; // @ is an alias to /src
import MiddleTwoRowTableDataConfirmMolecules from "@/components/molecules/catalog/MiddleTwoRowTableDataConfirmMolecules.vue"; // @ is an alias to /src
import MullTriggeredCueCheckboxConfirmMolecules from "@/components/molecules/catalog/MullTriggeredCueCheckboxConfirmMolecules.vue"; // @ is an alias to /src
import TableDataConfirmMolecules from "@/components/molecules/catalog/TableDataConfirmMolecules.vue"; // @ is an alias to /src
import WishMailCheckboxConfirmMolecules from "@/components/molecules/catalog/WishMailCheckboxConfirmMolecules.vue"; // @ is an alias to /src
import WorkingStyleConfirmMolecules from "@/components/molecules/catalog/WorkingStyleConfirmMolecules.vue"; // @ is an alias to /src
import { ApiUrlEnv } from "@/env/ApiUrlEnv";
import {
  DataType,
  RequestParam,
  ValidationResult,
} from "@/interface/catalog/CatalogInputConfirmInterFaces";
import { InputParam } from "@/interface/catalog/CatalogInterFaces";
import { ContractDateCalculator } from "@/services/catalog/ContractDateCalculator";
import { ContractAgeCalculator } from "@/services/global/ContractAgeCalculator";
import { Messages } from "@/utils/message/CatalogMessages";
import { CatalogInternalCodeEnum } from "@/utils/message/num/CatalogInternalCodeEnum";
import { Validater } from "@/utils/validater/CatalogValidaters";
import axios from "axios";
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
import { RequestValueGenerater } from "@/services/catalog/RequestValueGenerater";

export default defineComponent({
  name: "CatalogInputConfirmOganism",
  components: {
    TableDataConfirmMolecules,
    GenderConfirmMolecules,
    MiddleTableDataConfirmMolecules,
    MiddleTwoRowTableDataConfirmMolecules,
    MaritalStatusConfirmMolecules,
    ChildPresenceConfirmMolecules,
    WorkingStyleConfirmMolecules,
    ConcernCheckboxConfirmMolecules,
    MullTriggeredCueCheckboxConfirmMolecules,
    WishMailCheckboxConfirmMolecules,
    ErrorMessageAreaMolecules,
  },
  data(): DataType {
    return {
      inputParam: {
        familyName: "",
        firstName: "",
        familyNameKana: "",
        firstNameKana: "",
        insuredPersonGender: "",
        birthArray: {
          birthYear: 0,
          birthMonth: 0,
          birthDay: 0,
        },
        zipcode: "",
        cityName: "",
        blockNumber: "",
        buildingName: "",
        primaryPhone: "",
        email: "",
        maritalStatus: "",
        childNumber: "",
        workingStyle: "",
        channelSikibetusi: "",
        advertiseMedia: "",
        advertiseMaterial: "",
        concern: [],
        mullTriggeredCue: [],
        applicationPlanCue: [],
        wishEmail: [],
        karteUserId: "",
        stateCityName: "",
      },
      inputHashParam: "",
      isError: false,
      errorMessage: "",
      cityNameTableHead: "都道府県\n市区町村",
      bilingNameTableHead: "マンション名\n部屋番号",
      cityNameTableHead_sp: "都道府県市区町村",
      bilingNameTableHead_sp: "マンション名・部屋番号",
      contactAgeText: "",
      isMyPageCatalogApply: false,
      validationResultFlg: true,
      validationMessages: [],
      internalCodeList: [],
    };
  },
  methods: {
    // VuexからMutationsを呼び出す
    ...mapMutations([
      "setLoadingStatus",
      "setReEnterStatus",
      "setCatalogId",
      "setIsCatalogConfirmed",
      "setIsCatalogCompleted",
      "setIsMyPageApply",
      "setReturnFromConfirm",
      "setContractAge",
    ]),
    ...mapGetters(["getInputPageParam", "getIsMyPageApply"]),

    inputFix() {
      this.setReEnterStatus(true);
      this.setReturnFromConfirm(true);
      this.$router.push("/catalog");
    },

    getVuexInputPageParam(): InputParam {
      return this.getInputPageParam();
    },

    /**
     * 確認メッセージを表示し、APIリクエストを実行する
     */
    catalogApply() {
      // バリデーションを行う
      if (!this.validate()) {
        return;
      }
      this.validationResultFlg = true;

      // メール配信停止APIへのリクエストを行う
      this.catalogApplyRequest();
    },

    /**
     * バリデーションチェックを行います
     * ・バリデーション結果によって論理値を返却します
     * ・エラーメッセージを表示します
     */
    validate(): boolean {
      const CatalogMessages = new Messages();
      // 送信前のバリデーションチェック
      const validationResult: Array<ValidationResult> =
        Validater.validateCatalog(this.inputParam, this.inputParam.zipcode);
      if (validationResult.length != 0) {
        this.validationMessages = [];
        validationResult.forEach((result) => {
          this.validationMessages.push(
            CatalogMessages.generateMessage(result.internalCode)
          );
        });
        this.validationResultFlg = false;
        this.moveToTop();
        return false;
      }
      return true;
    },

    /**
     * スクロールイベント
     */
    moveToTop() {
      // 移動速度（1秒で終了）
      const duration = 200;
      // 0.025秒ごとに移動
      const interval = 1;
      // 1回に移動する距離
      const step = -window.scrollY / Math.ceil(duration / interval);
      const timer = setInterval(() => {
        // スクロール位置を移動
        window.scrollBy(0, step);

        if (window.scrollY <= 0) {
          clearInterval(timer);
        }
      }, interval);
    },

    /**
     * 資料請求APIリクエストを実行します
     */
    catalogApplyRequest() {
      const CatalogMessages = new Messages();
      // ローディング画面を表示
      this.setLoadingStatus(true);
      // スマートフォン登録フラグを作成する
      const isSmartPhoneRequest: boolean = navigator.userAgent.match(
        /iPhone|Android.+Mobile/
      )
        ? true
        : false;
      // チャネル識別子を作成
      this.inputParam.channelSikibetusi =
        RequestValueGenerater.convertChannelSikibetusi(
          this.getIsMyPageApply(),
          isSmartPhoneRequest
        );

      // JSON形式でのリクエストデータを作成する
      const param: RequestParam = this.generateRequestParam();
      const header = { "x-api-key": this.catalogApplyApiKey };
      axios
        .post(this.catalogApplyApiUrl, param, { headers: header })
        .then((res) => {
          // エラーがあった場合は表示
          if (res.data.internalCode[0] != "N1") {
            this.internalCodeList = res.data.internalCode;
            this.internalCodeList.forEach((result) => {
              this.validationMessages.push(
                CatalogMessages.generateMessage(result)
              );
            });
            this.validationResultFlg = false;
            this.setLoadingStatus(false);
            this.moveToTop();
            return;
          }
          // 資料請求IDをVuexに格納
          this.setCatalogId(res.data.catalogId);
          // バリデーション結果フラグに成功した旨を格納
          this.validationResultFlg = true;
          // Vuexに対して、資料請求完了後のステータスを反映
          this.setLoadingStatus(false);
          this.setIsCatalogCompleted(true);
          this.setIsMyPageApply(false);
          // 資料請求完了画面へ遷移
          this.$router.push("/catalog/complete");
        })
        .catch((err) => {
          // ローディング画面を非表示
          this.setLoadingStatus(false);
          if (!this.validationResultFlg) {
            this.validationResultFlg = true;
            this.validationMessages.push(
              CatalogMessages.generateMessage(
                CatalogInternalCodeEnum.InternalServerError
              )
            );
          }
        });
    },

    /**
     * 資料請求APIリクエストパラメータを作成します。
     *
     * @returns 資料請求APIリクエストパラメータ
     */
    generateRequestParam(): RequestParam {
      const svid: string = this.getSiteCatalystIdByCookie();

      // お役立ちメールのセレクトボックスが未チェックの場合、0を設定する。
      if (this.inputParam.wishEmail.length === 0) {
        this.inputParam.wishEmail[0] = "0";
      }
      // 就業状況のラジオボタンが未チェックの場合、99を設定する。
      if (this.inputParam.workingStyle === "") {
        this.inputParam.workingStyle = "99";
      }
      // 18歳未満のお子様のラジオボタンが未チェックの場合、99を設定する。
      if (this.inputParam.childNumber === "") {
        this.inputParam.childNumber = "99";
      }
      // 家族構成のラジオボタンが未チェックの場合、99を設定する。
      if (this.inputParam.maritalStatus === "") {
        this.inputParam.maritalStatus = "99";
      }

      return {
        familyName: this.inputParam.familyName,
        firstName: this.inputParam.firstName,
        familyNameKana: this.inputParam.familyNameKana,
        firstNameKana: this.inputParam.firstNameKana,
        insuredPersonGender: parseInt(this.inputParam.insuredPersonGender),
        birthYear: this.inputParam.birthArray.birthYear,
        birthMonth: this.inputParam.birthArray.birthMonth,
        birthDay: this.inputParam.birthArray.birthDay,
        zipcode: this.inputParam.zipcode,
        cityName: this.inputParam.cityName,
        blockNumber: this.inputParam.blockNumber,
        buildingName: this.inputParam.buildingName,
        primaryPhone: this.inputParam.primaryPhone,
        email: this.inputParam.email,
        maritalStatus: parseInt(this.inputParam.maritalStatus),
        childNumber: parseInt(this.inputParam.childNumber),
        workingStyle: parseInt(this.inputParam.workingStyle),
        channelSikibetusi: this.inputParam.channelSikibetusi,
        advertiseMedia: this.inputParam.advertiseMedia,
        advertiseMaterial: this.inputParam.advertiseMaterial,
        concern: this.inputParam.concern.sort(),
        mullTriggeredCue: this.inputParam.mullTriggeredCue.sort(),
        applicationPlanCue: this.inputParam.applicationPlanCue,
        wishEmail: this.inputParam.wishEmail[0],
        karteUserId: this.inputParam.karteUserId,
        siteCatalystId: svid,
      };
    },

    /**
     * CookieからサイトカタリストIDを取得する
     *
     * @returns サイトカタリストID
     */
    getSiteCatalystIdByCookie(): string {
      // Cookieを取得する
      const cookie = this.$cookies;
      // CookieからサイトカタリストIDを取得する。
      if (
        cookie.get("s_vid") != undefined &&
        cookie.get("s_vid").match(/^[a-zA-Z0-9+.()]+$/)
      ) {
        return cookie.get("s_vid").toString();
      }
      return "";
    },
    /**
     * タブを閉じる前の確認用のconfirmを出します。
     *
     * @param event
     */
    confirmSave(event: { returnValue: string }) {
      event.returnValue = "行った変更が保存されない可能性があります。";
    },
    /**
     * 誕生日付の設定を行います。
     *
     * @param birthYear 誕生年
     * @param birthMonth 誕生月
     * @param birthDay 誕生日
     */
    generateContractAgeText(
      birthYear: number,
      birthMonth: number,
      birthDay: number
    ): string {
      // 契約基準日を算出
      const currentDate = new Date();
      const contractDate: Date =
        ContractDateCalculator.calculationContactDate(currentDate);
      // 契約年齢を算出
      const contractAge: number = ContractAgeCalculator.calculationContactAge(
        birthYear,
        birthMonth,
        birthDay,
        contractDate
      );

      // 算出した契約年齢を文字列でVuexに格納する
      this.setContractAge(contractAge.toString());

      // 契約月より誕生月が小さい場合(誕生月を迎えている場合)、年齢を-1して返却する
      return " （契約年齢" + contractAge.toString() + "歳）";
    },
  },
  computed: {
    /**
     * 資料請求情報登録APIのURLを返却します。
     *
     * @returns 資料請求情報登録APIのURL
     */
    catalogApplyApiUrl(): string {
      return ApiUrlEnv.apiBaseUrl + "/catalog/apply";
    },

    /**
     * 資料請求情報登録APIのAPIKEYを返却します。
     *
     * @returns 資料請求情報登録APIのAPIKEY
     */
    catalogApplyApiKey(): string {
      return ApiUrlEnv.apiKey;
    },
  },
  mounted() {
    this.inputParam = this.getVuexInputPageParam();
    this.contactAgeText = this.generateContractAgeText(
      this.inputParam.birthArray.birthYear,
      this.inputParam.birthArray.birthMonth,
      this.inputParam.birthArray.birthDay
    );
  },
  created() {
    window.addEventListener("beforeunload", this.confirmSave);
  },
  unmounted() {
    window.removeEventListener("beforeunload", this.confirmSave);
  },
});
