/**
 * 内部コードを列挙します
 */
export enum ReserveInternalCodeEnum {
  // 正常終了
  Successful = "N1",
  // 保険相談予約日時ID未入力エラー
  ReserveDateIdEmptyError = "E1",
  // 保険相談予約日時ID形式エラー
  ReserveDateCountOverError = "E2",
  // お名前(セイ)未入力エラー
  FamilyNameKanaEmptyError = "E3",
  // お名前(セイ)文字数制限エラー
  FamilyNameKanaCountOverError = "E4",
  // お名前(セイ)形式エラー
  FamilyNameKanaFormatError = "E5",
  // お名前(メイ)未入力エラー
  FirstNameKanaEmptyError = "E6",
  // お名前(メイ)文字数制限エラー
  FirstNameKanaCountOverError = "E7",
  // お名前(メイ)形式エラー
  FirstNameKanaFormatError = "E8",
  // 性別不正エラー
  GenderIsInvalidError = "E9",
  // 電話番号未入力エラー
  PrimaryPhoneEmptyError = "E10",
  // 電話番号文字数制限エラー
  PrimaryPhoneCountOverError = "E11",
  // 電話番号形式エラー
  PrimaryPhoneFormatError = "E12",
  // 気になる項目不正エラー
  ConcernIsInvalidError = "E13",
  // 画面共有サポート利用フラグ不正エラー
  ScreenSharingFlgIsInvalidError = "E14",
  // KARTEユーザID文字数制限エラー
  KarteUserIdCountOverError = "E15",
  // エンティティ取得エラー
  GetEntityFailed = "E16",
  // 論理削除データ参照エラー
  DataIsLogicalDeleted = "E17",
  // 保険相談予約可能枠無しエラー
  ReservableSpotIsZero = "E18",
  // 予約被り
  NotReservableApplyCavering = "E19",
  // データベース接続エラー
  DynamoDbConnectionFailed = "E101",
  // サーバエラー
  InternalServerError = "E102",
  // SalesForceとの連携エラー
  SalesForceConnectionFailed = "E103",

  // FamilyNameEmptyError = "",
  // FamilyNameFormatError = "",
  // FamilyNameCountOverError = "",
  // FirstNameEmptyError = "",
}
