import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78c5bc04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_IntroductionCompleteComponent = _resolveComponent("IntroductionCompleteComponent")!
  const _component_ReserveCompleteOrganism = _resolveComponent("ReserveCompleteOrganism")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderComponent, { pageTitle: "電話＆オンラインでの保険相談（無料）予約完了" }),
    _createVNode(_component_IntroductionCompleteComponent, {
      titleHead1: "ご予約ありがとうございます",
      titleHead2: "保険相談のご予約を承りました。",
      textRow1: " このたびは、保険相談のご予約をいただき、誠にありがとうございました。",
      textRow2: "ご予約いただいた",
      textRow3: "に担当の保険プランナーよりお電話いたします。",
      textRow4: "当日の混雑状況によっては、お電話の時間が多少前後する可能性もございますのでご了承ください。",
      textRow5: "なおご予約内容の変更は、お手数をおかけしますがコンタクトセンターにてお電話でお申しつけください。",
      reserveDate: _ctx.reserveDateTimeMessage
    }, null, 8, ["reserveDate"]),
    _createVNode(_component_ReserveCompleteOrganism),
    _createVNode(_component_FooterComponent)
  ]))
}