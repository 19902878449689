export class ContractDateCalculator {
  /**
   * 資料請求機能における契約基準日を算出します
   *
   * @param currentDate 現在日付
   * @returns 契約基準日
   */
  public static calculationContactDate(currentDate: Date): Date {
    // 契約日に翌月の1日を設定
    const contractDate: Date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    // 現在月の月末日を取得
    const monthLastDate: Date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // 月末日だった場合、契約基準日を一か月進めて返却する。
    if (currentDate.getDate() === monthLastDate.getDate()) {
      contractDate.setMonth(contractDate.getMonth() + 1);
    }
    return contractDate;
  }
}
