<template>
  <div class="form-main">
    <span class="form-title">{{ formTitle }}</span>
    <section class="form-input-area">
      <span class="form-input">
        <FormComponentSimple
          :type="type"
          :formTitle="formTitle"
          v-model="localModelValue"
          :maxLength="maxLength"
          :required="true"
          :email="email"
        />
        <p>{{ sample }}</p>
      </span>
    </section>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import FormComponentSimple from "@/components/atoms/FormComponentSimple.vue";

export default defineComponent({
  name: "HelloWorld",
  components: {
    FormComponentSimple,
  },
  props: {
    // テキストボックスのタイプ
    type: { type: String, default: "" },
    // フォームのタイトル
    formTitle: { type: String, default: "" },
    // テキストボックスのプレースホルダ
    placeHolder: { type: String, default: "" },
    // テキストボックス内の入力値をコンポーネント間でバインディングするための変数
    modelValue: { type: String, default: "" },
    // テキストボックスの最大入力値
    maxLength: { type: Number, default: 1 },
    // 入力値のサンプル
    sample: { type: String, default: "" },
    // true : 必須入力 false : 必須でない入力
    required: { type: Boolean, default: false },
    // メールアドレス形式の入力
    email: { type: Boolean, default: false },
  },
  computed: {
    /**
     * 親コンポーネントに値を渡す為のメソッド
     */
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue: string) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-main .form-input-area .form-input input[type="text" i] {
  width: 320px;
}
@media screen and (min-width: 501px) {
  .form-main {
    display: flex;
    padding: 6px 0px;
    width: 700px;
    height: 100px;
  }
  .form-main .form-title {
    background-color: #f1f6cd;
    color: #505c01;
    width: 300px;
    font-size: 75%;
    border: 1px solid #505c01;
    display: flex;
    align-items: center;
    padding: 0.3em 6px 0.2em 6px;
    font-weight: bold;
  }
  .form-main .form-input-area {
    width: 400px;
    border: 1px solid #505c01;
    margin: 0 0 0 -1px;
    display: flex;
    align-items: center;
    padding: 0.3em 6px 0.2em 0px;
  }
  .form-main .form-input-area .form-input {
    margin-left: 10px;
    font-size: 75%;
  }
}
@media screen and (max-width: 500px) {
  .form-main {
    width: 100%;
    border: 1px solid #80b735;
    overflow: hidden;
  }
  .form-main .form-title {
    background-color: #f1f6cd;
    color: #505c01;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0.3em 6px 0.2em 15px;
    font-weight: 600;
  }
  .form-main .form-input-area {
    min-height: 52px;
    width: 100%;
    align-items: center;
    padding: 35px 20px 25px 20px;
  }
}
</style>
