import { ValidationResult } from "@/interface/common/CommonValidatersInterface";
import { Messages } from "../message/MaildeliveryMessages";
import { MaildeliveryInternalCodeEnum } from "../message/num/MaildeliveryInternalCodeEnum";
import { CommonValidater } from "./common/CommonValidaters";

export class Validater {
  private static internalErrorCodeType = "mailDelivery";
  private static MailDeliverydMessages = new Messages();
  /**
   * バリデーションチェックを行います。
   * ・バリデーションエラーになった場合falseを返却し、alertメッセージを表示する
   *
   * @return {boolean}
   */
  public static validateMaildelivery(email: string): ValidationResult {
    // 必須チェック
    if (!email) {
      const validationResult: ValidationResult = {
        inputName: "email",
        internalCode: MaildeliveryInternalCodeEnum.MailAddressEmptyError,
        isSuccess: false,
        message: this.MailDeliverydMessages.generateMessage(
          MaildeliveryInternalCodeEnum.MailAddressEmptyError
        ),
      };
      return validationResult;
    }
    // 文字数チェック
    const emailCheckResult = CommonValidater.validateEmail(
      email,
      this.MailDeliverydMessages,
      this.internalErrorCodeType
    );
    if (emailCheckResult.length != 0) return emailCheckResult[0];
    return {
      inputName: "email",
      internalCode: MaildeliveryInternalCodeEnum.Successful,
      isSuccess: true,
      message: "",
    };
  }

  /**
   * メールアドレスのフォーマットチェックを行います
   *
   * @param email
   * @returns
   */
  private static mailAddressFormatCheck(email: string): boolean {
    // メールアドレスチェック
    const pattern =
      /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    if (email && !pattern.test(email)) {
      return false;
    }
    return true;
  }
}
