import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b81ccfa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Form-tbl__th Form-tbl__th--col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableDataAtoms = _resolveComponent("TableDataAtoms")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("th", _hoisted_1, _toDisplayString(_ctx.tableHead), 1),
    _createVNode(_component_TableDataAtoms, { tableData: _ctx.tableData }, null, 8, ["tableData"])
  ], 64))
}