import ConcernCheckboxMolecules from "@/components/molecules/catalog/ConcernCheckboxMolecules.vue"; // @ is an alias to /srcPolicy
import ErrorMessageAreaMolecules from "@/components/molecules/catalog/ErrorMessageAreaMolecules.vue"; // @ is an alias to /src
import GenderMolecules from "@/components/molecules/catalog/GenderMolecules.vue"; // @ is an alias to /src
import LongInputMolecules from "@/components/molecules/catalog/LongInputMolecules.vue"; // @ is an alias to /src
import NameInputFormMolecules from "@/components/molecules/catalog/NameInputFormMolecules.vue"; // @ is an alias to /src
import PolicyProtectionMolecules from "@/components/molecules/catalog/PolicyProtectionMolecules.vue"; // @ is an alias to /srcPolicy
import ScreenSharingFlgCheckboxMolecules from "@/components/molecules/reserve/ScreenSharingFlgCheckboxMolecules.vue"; // @ is an alias to /srcPolicy
import { ApiUrlEnv } from "@/env/ApiUrlEnv";
import {
  DataType,
  RequestParam,
  ScheduleList,
  ScheduleResponseList,
} from "@/interface/reserve/ReserveInputInterFaces";
import { Messages } from "@/utils/message/ReserveMessages";
import { ReserveInternalCodeEnum } from "@/utils/message/num/ReserveInternalCodeEnum";
import { Validater } from "@/utils/validater/ReserveValidaters";
import axios from "axios";
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  name: "InputMolecules",
  components: {
    NameInputFormMolecules,
    LongInputMolecules,
    GenderMolecules,
    ConcernCheckboxMolecules,
    PolicyProtectionMolecules,
    ScreenSharingFlgCheckboxMolecules,
    ErrorMessageAreaMolecules,
  },

  data(): DataType {
    return {
      inputParam: {
        reserveDateId: "",
        familyNameKana: "",
        firstNameKana: "",
        insuredPersonGender: "",
        primaryPhone: "",
        concern: [],
        screenSharingFlg: [],
      },
      validationResultFlg: true,
      validationResult: [
        {
          // バリデーション項目
          inputName: "",
          // エラーコード
          internalCode: "",
          // バリデーション結果
          isSuccess: true,
          // バリデーションメッセージ
          message: "",
        },
      ],
      selectList: [],
      scheduleList: [],
      validationMessages: [],
      errorTagList: [],
      internalCodeList: [],
      karteUserId: "",
      nowSelected: "お選びください →",
    };
  },
  methods: {
    // VuexからMutationsを呼び出す
    ...mapMutations([
      "setInputPageParam",
      "setReEnterStatus",
      "setReserveDateTimeMessage",
      "setLoadingStatus",
      "setIsReserveCompleted",
      "setInsuredPersonGender",
    ]),
    ...mapGetters([
      "getInputPageParam",
      "getReEnterStatus",
      "getReserveDateTimeMessage",
    ]),

    /**
     * 選択された保険相談予約日をセットします。
     */
    nowSelectDataChange() {
      const nowSelectData: any = this.selectList.filter(
        (a) => a.id === this.inputParam.reserveDateId
      );

      if (nowSelectData != undefined || nowSelectData != null) {
        this.nowSelected =
          nowSelectData[0].reserveDateTime +
          "　" +
          nowSelectData[0].reserveMessage;
      } else {
        this.nowSelected = "お選びください →";
      }
    },
    /**
     * 確認画面へ遷移します。
     * ・バリデーションを行います
     */
    goToComplete() {
      const ReserveMessages = new Messages();
      // inputパラメータを生成する
      const params: RequestParam = this.generateInputParam();
      //バリデーションを行う
      if (!this.validate(params)) {
        return;
      }

      this.validationResultFlg = true;
      this.setLoadingStatus(true);
      const header = { "x-api-key": this.reserveApiKey };

      axios
        .post(this.reserveApiUrl, params, { headers: header })
        .then((res) => {
          if (res.data.internalCode[0] != "N1") {
            this.internalCodeList = res.data.internalCode;
            this.internalCodeList.forEach((result) => {
              this.validationMessages.push(
                ReserveMessages.generateMessage(result)
              );
            });
            this.validationResultFlg = false;
            this.setLoadingStatus(false);
            this.moveToTop();
            return;
          }
          this.validationResultFlg = true;

          // 予約確定日時を設定する
          this.setReserveDateTime(res.data.reserveDate, res.data.reserveTime);
          // 性別を設定する
          this.setInsuredPersonGender(this.inputParam.insuredPersonGender);

          // ローディング画面を非表示
          this.setLoadingStatus(false);
          this.setIsReserveCompleted(true);
          this.$router.push("/reserve/complete");
        })
        .catch((err) => {
          // ローディング画面を非表示
          this.setLoadingStatus(false);
          this.setIsReserveCompleted(false);
          if (!this.validationResultFlg) {
            this.validationResultFlg = true;
            this.validationMessages.push(
              ReserveMessages.generateMessage(
                ReserveInternalCodeEnum.InternalServerError
              )
            );
          }
        });
    },

    /**
     * バリデーションチェックを行います
     * ・バリデーション結果によって論理値を返却します
     * ・バリデーションに引っ掛かった画面項目を赤塗にします
     * ・エラーメッセージを表示します
     */
    validate(params: RequestParam): boolean {
      const ReserveMessages = new Messages();
      this.validationResult = Validater.validateReserve(params);
      // 送信前のバリデーションチェック
      this.errorTagList.forEach((e) => {
        document.getElementById(e)!.style.backgroundColor = "#fff";
      });
      this.validationMessages = [];
      this.errorTagList = [];
      if (this.validationResult.length != 0) {
        this.validationResult.forEach((result) => {
          document.getElementById(result.inputName)!.style.backgroundColor =
            "#ffd9d9";
          this.errorTagList.push(result.inputName);
          this.validationMessages.push(
            ReserveMessages.generateMessage(result.internalCode)
          );
        });
        this.validationResultFlg = false;
        this.moveToTop();
        return false;
      }
      return true;
    },

    /**
     * スクロールイベント
     */
    moveToTop() {
      // 移動速度（1秒で終了）
      const duration = 200;
      // 0.025秒ごとに移動
      const interval = 1;
      // 1回に移動する距離
      const step = -window.scrollY / Math.ceil(duration / interval);
      const timer = setInterval(() => {
        // スクロール位置を移動
        window.scrollBy(0, step);

        if (window.scrollY <= 0) {
          clearInterval(timer);
        }
      }, interval);
    },

    /**
     * 予約確定日時を設定します
     * ・返却されたデータから予約日と予約時間を取得し設定します
     *
     * @param reserveDate
     * @param reserveTime
     */
    setReserveDateTime(reserveDate: string, reserveTime: string) {
      const month: number = parseInt(reserveDate.slice(4, 6));
      const day: number = parseInt(reserveDate.slice(6, 8));
      const time: string = reserveTime.replace("-", "~");
      const reserveDateTimeMessage = month + "月" + day + "日" + " " + time;
      this.setReserveDateTimeMessage(reserveDateTimeMessage);
    },

    /**
     * 保険相談予約日時を取得、設定します
     * ・保険相談予約日時を取得します
     * ・表示用に形式を整え、プルダウン形式で表示します
     */
    setReserveDateIdList() {
      const ReserveMessages = new Messages();
      const header = { "x-api-key": this.reserveApiKey };
      axios
        .get(this.scheduleGetApiUrl, { headers: header })
        .then((res) => {
          const data = res.data as ScheduleResponseList;
          // 保険相談予約カレンダの取得に失敗した場合
          if (data.internalCode != "N1") {
            this.validationMessages.push(
              ReserveMessages.generateMessage(
                ReserveInternalCodeEnum.InternalServerError
              )
            );
            this.validationResultFlg = false;
            this.setLoadingStatus(false);
            this.moveToTop();
            return;
          }

          const agent = window.navigator.userAgent.toLowerCase();
          this.scheduleList = data.reserveInfo.sort((a, b) => {
            return a.timeZone < b.timeZone ? -1 : 1;
          });
          // firefox使用時にプルダウンが降順で表示されることの対応
          if (agent.indexOf("firefox") != -1) {
            this.scheduleList.sort((a, b) => {
              if (a.timeZone < b.timeZone) {
                return 1;
              } else if (a.timeZone > b.timeZone) {
                return -1;
              } else {
                return 0;
              }
            });
          }

          this.scheduleList = this.scheduleList.sort((a, b) => {
            return a.reserveDate < b.reserveDate ? -1 : 1;
          });
          // firefox使用時にプルダウンが降順で表示されることの対応
          if (agent.indexOf("firefox") != -1) {
            this.scheduleList.sort((a, b) => {
              if (a.reserveDate > b.reserveDate) {
                return 1;
              } else if (a.reserveDate < b.reserveDate) {
                return -1;
              } else {
                return 0;
              }
            });
          }

          this.scheduleList.forEach((element: ScheduleList) => {
            const month: string = element.reserveDate.slice(4, 6);
            const day: string = element.reserveDate.slice(6, 8);
            const date: Date = new Date(
              parseInt(element.reserveDate.slice(0, 4)),
              parseInt(element.reserveDate.slice(4, 6)) - 1,
              parseInt(element.reserveDate.slice(6, 8))
            );
            const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][
              date.getDay()
            ];
            const reserveDateTime: string =
              month +
              "月" +
              day +
              "日" +
              "（" +
              dayOfWeekStr +
              "）" +
              element.timeZone;
            const isReservableCount = element.spots - element.booked;
            const reservableSlot = isReservableCount / element.spots;
            let reserveMessage = "";
            if (reservableSlot <= 0.5 && isReservableCount != 0) {
              reserveMessage = "△（残小）";
            } else if (isReservableCount > 0) {
              reserveMessage = "〇";
            } else {
              reserveMessage = "× (予約不可)";
            }
            this.selectList.push({
              id: element.id,
              reserveDateTime: reserveDateTime,
              reserveMessage: reserveMessage,
            });
          });

          this.setLoadingStatus(false);
        })
        .catch((err) => {
          // ローディング画面を非表示
          this.setLoadingStatus(false);
        });
    },

    /**
     * inputパラメータを生成します。
     */
    generateInputParam(): RequestParam {
      const concern: number[] = [];
      const cookie = this.$cookies;
      let svid = "";

      this.inputParam.concern.sort().forEach((value) => {
        concern.push(parseInt(value));
      });

      // KARTEユーザーID取得
      const karteUserId = <HTMLInputElement>(
        document.getElementById("karte-user-id")
      );
      this.karteUserId = karteUserId.value;

      if (
        cookie.get("s_vid") != undefined &&
        cookie.get("s_vid").match(/^[a-zA-Z0-9+.()]+$/)
      ) {
        svid = cookie.get("s_vid");
      }
      return {
        // 保険相談予約日時ID
        reserveDateId: this.inputParam.reserveDateId,
        familyNameKana: this.inputParam.familyNameKana,
        firstNameKana: this.inputParam.firstNameKana,
        gender: parseInt(this.inputParam.insuredPersonGender),
        primaryPhone: this.inputParam.primaryPhone,
        concern: concern,
        screenSharingFlg: parseInt(this.inputParam.screenSharingFlg[0]),
        // KARTEユーザID
        karteUserId: this.karteUserId,
        // サイトカタリストID
        siteCatalystId: svid,
      };
    },
    setInputLocalParam() {
      this.inputParam.familyNameKana = this.getInputPageParam().familyNameKana;
      this.inputParam.firstNameKana = this.getInputPageParam().firstNameKana;
      this.inputParam.insuredPersonGender =
        this.getInputPageParam().insuredPersonGender;
      this.inputParam.primaryPhone = this.getInputPageParam().primaryPhone;
      this.inputParam.concern = this.getInputPageParam().concern;
      this.inputParam.screenSharingFlg =
        this.getInputPageParam().screenSharingFlg;
    },
    confirmSave(event: { returnValue: string }) {
      event.returnValue = "行った変更が保存されない可能性があります。";
    },
  },
  computed: {
    /**
     * 保険相談予約APIのURLを返却します。
     *
     * @returns 保険相談予約APIのURL
     */
    reserveApiUrl(): string {
      return ApiUrlEnv.apiBaseUrl + "/consultation/reserve";
    },
    /**
     * 保険相談予約日時取得APIのURLを返却します。
     *
     * @returns 保険相談予約日時取得APIのURL
     */
    scheduleGetApiUrl(): string {
      return ApiUrlEnv.apiBaseUrl + "/schedule/get";
    },

    /**
     * 保険相談予約APIのAPIKEYを返却します。
     *
     * @returns 保険相談予約APIのAPIKEY
     */
    reserveApiKey(): string {
      return ApiUrlEnv.apiKey;
    },
  },
  created() {
    window.addEventListener("beforeunload", this.confirmSave);
  },
  unmounted() {
    window.removeEventListener("beforeunload", this.confirmSave);
  },
  mounted() {
    if (this.getReEnterStatus()) {
      this.setInputLocalParam();
      this.setReEnterStatus();
    }
    this.setLoadingStatus(true);

    // 保険相談予約日時の取得と設定
    this.setReserveDateIdList();
  },
});
