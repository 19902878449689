import { AbstractMessages } from "./AbstractMessages";

export class Messages extends AbstractMessages {
  /**
   * 内部コードからメッセージを生成します。
   *
   * @param internalCode
   * @returns
   */
  generateMessage(internalCode: string): string {
    switch (internalCode) {
      case "N1":
        return "正常終了。";
      case "E1":
        return "メールアドレス形式が違います。";
      case "E2":
        return "メールアドレスが未入力です。";
      case "E3":
        return "メールアドレスの文字数は80文字以内です。";
      case "E4":
        return "保険検討のきっかけの入力が不正です。";
      case "E5":
        return "気になる項目の入力が不正です。";
      case "E6":
        return "生年月日(年)は1900～3000の範囲で指定してください。";
      case "E7":
        return "生年月日(年)が未入力です。";
      case "E8":
        return "生年月日(月)は1～12の範囲で指定してください。";
      case "E9":
        return "生年月日(月)が未入力です。";
      case "E10":
        return "生年月日(日)は1～31の範囲で指定してください。";
      case "E11":
        return "生年月日(日)が未入力です。";
      case "E12":
        return "日付の入力が不正です。";
      case "E13":
        return "お名前の漢字(名)に登録できない文字が含まれていますので変更してください。";
      case "E14":
        return "お名前の漢字(名)が未入力です。";
      case "E15":
        return "お名前の漢字(名)は9文字以内で入力してください。";
      case "E16":
        return "お名前のカタカナ(メイ)を全角のカタカナで入力してください。";
      case "E17":
        return "お名前のカタカナ(メイ)が未入力です。";
      case "E18":
        return "お名前のカタカナ(メイ)は20文字以内で入力してください。";
      case "E19":
        return "お名前の漢字(姓)に登録できない文字が含まれていますので変更してください。";
      case "E20":
        return "お名前の漢字(姓)が未入力です。";
      case "E21":
        return "お名前の漢字(姓)は14文字以内で入力してください。";
      case "E22":
        return "お名前のカタカナ(セイ)を全角のカタカナで入力してください。";
      case "E23":
        return "お名前のカタカナ(セイ)が未入力です。";
      case "E24":
        return "お名前のカタカナ(セイ)は30文字以内で入力してください。";
      case "E25":
        return "性別が未入力です。";
      case "E26":
        return "性別の入力が不正です。";
      case "E27":
        return "都道府県市区町村が未入力です。";
      case "E28":
        return "都道府県市区町村は50文字以内で入力してください。";
      case "E29":
        return "番地が未入力です。";
      case "E30":
        return "番地は50文字以内で入力してください。";
      case "E31":
        return "郵便番号が未入力です。";
      case "E32":
        return "郵便番号は7桁の数値で入力してください。";
      case "E33":
        return "マンション名・部屋番号は50文字以内で入力してください。";
      case "E34":
        return "電話番号が未入力です。";
      case "E35":
        return "電話番号は10~11文字で入力してください。";
      case "E36":
        return "18歳未満のお子さまの入力が不正です。";
      case "E37":
        return "就業状況の入力が不正です。";
      case "E38":
        return "お役立ち情報を受け取るの入力が不正です。";
      case "E39":
        return "家族構成の入力が不正です。";
      case "E40":
        return "契約年齢が18歳未満もしくは71歳以上の場合は、ライフネット生命の保険商品に申し込みできないため、資料請求していただけません。";
      case "E41":
        return "住所（番地）に登録できない文字が含まれていますので変更してください。";
      case "E42":
        return "住所（ビル・マンション名など）に登録できない文字が含まれていますので変更してください。";
      case "E43":
        return "郵便番号をご確認ください。";
      case "E44":
        return "郵便番号に該当する住所が見つかりません。";
      case "E45":
        return "電話番号をご確認ください。";
      case "E101":
        return "サーバー内部でエラーが発生しました、時間を空けて再度リクエストしてください。";
      case "E102":
        return "サーバー内部でエラーが発生しました、時間を空けて再度リクエストしてください。";
      default:
        return "";
    }
  }
}
