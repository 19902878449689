import { BirthArray, InputParam } from "@/interface/catalog/CatalogInterFaces";
import { ValidationResult } from "@/interface/common/CommonValidatersInterface";
import { ContractDateCalculator } from "@/services/catalog/ContractDateCalculator";
import { ContractAgeCalculator } from "@/services/global/ContractAgeCalculator";
import { Messages } from "../message/CatalogMessages";
import { CatalogInternalCodeEnum } from "../message/num/CatalogInternalCodeEnum";
import { CommonValidater } from "./common/CommonValidaters";

export class Validater {
  private static CatalogMessages = new Messages();
  // 禁止文字リスト
  private static forbiddenCharaList =
    /^[纊褜鍈銈蓜俉炻昱棈鋹曻彅丨仡仼伀伃伹佖侒侊侚侔俍偀倢俿倞偆偰偂傔僴僘兊兤冝冾凬刕劜劦勀勛匀匇匤卲厓厲叝﨎咜咊咩哿喆坙坥垬埈埇﨏塚增墲夋奓奛奝奣妤妺孖寀甯寘寬尞岦岺峵崧嵓﨑嵂嵭嶸嶹巐弡弴彧德忞恝悅悊惞惕愠惲愑愷愰憘戓抦揵摠撝擎敎昀昕昻昉昮昞昤晥晗晙晴晳暙暠暲暿曺朎朗杦枻桒柀栁桄棏﨓楨﨔榘槢樰橫橆橳橾櫢櫤毖氿汜沆汯泚洄涇浯涖涬淏淸淲淼渹湜渧渼溿澈澵濵瀅瀇瀨炅炫焏焄煜煆煇凞燁燾犱犾猤猪獷玽珉珖珣珒琇珵琦琪琩琮瑢璉璟甁畯皂皜皞皛皦益睆劯砡硎硤硺礰礼神祥禔福禛竑竧靖竫箞精絈絜綷綠緖繒罇羡羽茁荢荿菇菶葈蒴蕓蕙蕫﨟薰蘒﨡蠇裵訒訷詹誧誾諟諸諶譓譿賰賴贒赶﨣軏﨤逸遧郞都鄕鄧釚釗釞釭釮釤釥鈆鈐鈊鈺鉀鈼鉎鉙鉑鈹鉧銧鉷鉸鋧鋗鋙鋐﨧鋕鋠鋓錥錡鋻﨨錞鋿錝錂鍰鍗鎤鏆鏞鏸鐱鑅鑈閒隆﨩隝隯霳霻靃靍靏靑靕顗顥飯飼餧館馞驎髙髜魵魲鮏鮱鮻鰀鵰鵫鶴鸙黑]+$/;

  private static internalErrorCodeType = "catalog";
  /**
   * 禁止文字が含まれているかチェックします
   *
   * @param text
   * @returns
   */
  private static forbiddenCharaCheck(text: string) {
    // 文字列を1文字ずつに分割する
    const str = text.split("");
    // 文字数分ループを回す
    for (let i = 0; i < str.length; i++) {
      if (str[i].match(this.forbiddenCharaList)) {
        return false;
      }
    }
    return true;
  }
  /**
   * バリデーションチェックを行います。
   * ・バリデーションエラーになった場合falseを返却し、alertメッセージを表示する
   *
   * @return {boolean}
   */
  public static validateCatalog(
    catalogInput: InputParam,
    searchZipCode: string
  ): Array<ValidationResult> {
    return CommonValidater.validateKanjiName(
      catalogInput.familyName,
      catalogInput.firstName,
      this.CatalogMessages,
      this.internalErrorCodeType
    )
      .concat(
        CommonValidater.validateKanaName(
          catalogInput.familyNameKana,
          catalogInput.firstNameKana,
          this.CatalogMessages,
          this.internalErrorCodeType
        )
      )
      .concat(
        CommonValidater.validateSex(
          catalogInput.insuredPersonGender,
          this.CatalogMessages,
          this.internalErrorCodeType
        )
      )
      .concat(
        CommonValidater.validateBirthDay(
          catalogInput.birthArray,
          this.CatalogMessages,
          this.internalErrorCodeType
        )
      )
      .concat(this.validateContractAge(catalogInput.birthArray))
      .concat(
        this.validateZipcode(
          catalogInput.zipcode,
          catalogInput.cityName,
          searchZipCode
        )
      )
      .concat(
        this.validateAddress(
          catalogInput.cityName,
          catalogInput.blockNumber,
          catalogInput.buildingName
        )
      )
      .concat(
        CommonValidater.validatePhoneNumber(
          catalogInput.primaryPhone,
          this.CatalogMessages,
          this.internalErrorCodeType
        )
      )
      .concat(this.validateEmail(catalogInput.email));
  }

  /**
   *
   * @param birthArray 誕生日配列
   * @returns 契約年齢バリデーション結果
   */
  public static validateContractAge(
    birthArray: BirthArray
  ): Array<ValidationResult> {
    const validationResultList: Array<ValidationResult> =
      new Array<ValidationResult>();
    if (birthArray.birthYear && birthArray.birthMonth && birthArray.birthDay) {
      // 現在日を取得
      const nowDate = new Date();
      // 誕生日を取得
      const birthDate = new Date(
        birthArray.birthYear,
        birthArray.birthMonth - 1,
        birthArray.birthDay
      );
      // 契約日を算出
      const currentDate = new Date();
      const contractDate: Date =
        ContractDateCalculator.calculationContactDate(currentDate);
      // 契約年齢を算出します
      const contractAge: number = ContractAgeCalculator.calculationContactAge(
        birthArray.birthYear,
        birthArray.birthMonth,
        birthArray.birthDay,
        contractDate
      );

      // 未来日付チェック
      if (birthDate > nowDate) {
        validationResultList.push({
          inputName: "select_birth_date",
          internalCode: CatalogInternalCodeEnum.BirthDateInputError,
          isSuccess: false,
          message: this.CatalogMessages.generateMessage(
            CatalogInternalCodeEnum.BirthDateInputError
          ),
        });
      }
      if (contractAge < 18 || contractAge > 70) {
        validationResultList.push({
          inputName: "select_birth_date",
          internalCode: CatalogInternalCodeEnum.AgeRestrictedError,
          isSuccess: false,
          message: this.CatalogMessages.generateMessage(
            CatalogInternalCodeEnum.AgeRestrictedError
          ),
        });
      }
    }
    return validationResultList;
  }

  /**
   *
   * @param zipcode 郵便番号
   * @param cityName 市区町村名
   * @param searchZipCode 郵便番号検索結果
   * @returns 郵便番号バリデーション結果
   */
  public static validateZipcode(
    zipcode: string,
    cityName: string,
    searchZipCode: string
  ): Array<ValidationResult> {
    const validationResultList: Array<ValidationResult> =
      new Array<ValidationResult>();
    // 郵便番号
    if ((!zipcode && !searchZipCode) || !searchZipCode) {
      validationResultList.push({
        inputName: "addarea3",
        internalCode: CatalogInternalCodeEnum.ZipcodeEmptyError,
        isSuccess: false,
        message: this.CatalogMessages.generateMessage(
          CatalogInternalCodeEnum.ZipcodeEmptyError
        ),
      });
    } else if (
      zipcode != searchZipCode &&
      searchZipCode.length === 7 &&
      cityName
    ) {
      validationResultList.push({
        inputName: "addarea3",
        internalCode: CatalogInternalCodeEnum.CityNameCheckError,
        isSuccess: false,
        message: this.CatalogMessages.generateMessage(
          CatalogInternalCodeEnum.CityNameCheckError
        ),
      });
    } else if (searchZipCode.length != 7) {
      validationResultList.push({
        inputName: "addarea3",
        internalCode: CatalogInternalCodeEnum.ZipcodeCountError,
        isSuccess: false,
        message: this.CatalogMessages.generateMessage(
          CatalogInternalCodeEnum.ZipcodeCountError
        ),
      });
    }
    return validationResultList;
  }

  /**
   *
   * @param cityName 市区町村名
   * @param blockNumber 番地
   * @param buildingName 建物名
   * @returns 住所バリデーション結果
   */
  public static validateAddress(
    cityName: string,
    blockNumber: string,
    buildingName: string
  ): Array<ValidationResult> {
    const validationResultList: Array<ValidationResult> =
      new Array<ValidationResult>();
    // 都道府県市区町村
    if (!cityName) {
      validationResultList.push({
        inputName: "input_city_name",
        internalCode: CatalogInternalCodeEnum.CityNameEmptyError,
        isSuccess: false,
        message: this.CatalogMessages.generateMessage(
          CatalogInternalCodeEnum.CityNameEmptyError
        ),
      });
    } else {
      if (cityName.length > 50) {
        validationResultList.push({
          inputName: "input_city_name",
          internalCode: CatalogInternalCodeEnum.CityNameCountOverError,
          isSuccess: false,
          message: this.CatalogMessages.generateMessage(
            CatalogInternalCodeEnum.CityNameCountOverError
          ),
        });
      }
    }
    // 番地
    if (!blockNumber) {
      validationResultList.push({
        inputName: "input_block_number",
        internalCode: CatalogInternalCodeEnum.BlockNumberEmptyError,
        isSuccess: false,
        message: this.CatalogMessages.generateMessage(
          CatalogInternalCodeEnum.BlockNumberEmptyError
        ),
      });
    } else if (blockNumber.length > 29) {
      validationResultList.push({
        inputName: "input_block_number",
        internalCode: CatalogInternalCodeEnum.BlockNumberCountOverError,
        isSuccess: false,
        message: this.CatalogMessages.generateMessage(
          CatalogInternalCodeEnum.BlockNumberCountOverError
        ),
      });
    } else if (
      !blockNumber.match(
        /^[\u3000 ぁ-んーァ-ヶｦ-ﾟ一-龠々0-9０-９a-zA-Z・-ー―－-]+$/
      ) ||
      !this.forbiddenCharaCheck(blockNumber)
    ) {
      validationResultList.push({
        inputName: "input_block_number",
        internalCode: CatalogInternalCodeEnum.BlockNumberFormatError,
        isSuccess: false,
        message: this.CatalogMessages.generateMessage(
          CatalogInternalCodeEnum.BlockNumberFormatError
        ),
      });
    }
    // マンション名・部屋番号
    if (buildingName.length > 29 && buildingName != "") {
      validationResultList.push({
        inputName: "input_building_name",
        internalCode: CatalogInternalCodeEnum.BuildingNameCountOverError,
        isSuccess: false,
        message: this.CatalogMessages.generateMessage(
          CatalogInternalCodeEnum.BuildingNameCountOverError
        ),
      });
    } else if (
      (!buildingName.match(
        /^[\u3000 ぁ-んーァ-ヶｦ-ﾟ一-龠々0-9０-９a-zA-Z・-ー―－-]+$/
      ) ||
        !this.forbiddenCharaCheck(buildingName)) &&
      buildingName != ""
    ) {
      validationResultList.push({
        inputName: "input_building_name",
        internalCode: CatalogInternalCodeEnum.BuildingNameFormatError,
        isSuccess: false,
        message: this.CatalogMessages.generateMessage(
          CatalogInternalCodeEnum.BuildingNameFormatError
        ),
      });
    }
    return validationResultList;
  }

  /**
   *
   * @param email Emailアドレス
   * @returns Emailアドレスバリデーション結果
   */
  public static validateEmail(email: string): Array<ValidationResult> {
    const validationResultList: Array<ValidationResult> =
      new Array<ValidationResult>();
    // メールアドレス
    if (!email) {
      validationResultList.push({
        inputName: "input_email",
        internalCode: CatalogInternalCodeEnum.MailAddressEmptyError,
        isSuccess: false,
        message: this.CatalogMessages.generateMessage(
          CatalogInternalCodeEnum.MailAddressEmptyError
        ),
      });
    } else {
      if (email.length > 80) {
        validationResultList.push({
          inputName: "input_email",
          internalCode: CatalogInternalCodeEnum.MailAddressCountOverError,
          isSuccess: false,
          message: this.CatalogMessages.generateMessage(
            CatalogInternalCodeEnum.MailAddressCountOverError
          ),
        });
      }
      if (!this.mailAddressFormatCheck(email)) {
        validationResultList.push({
          inputName: "input_email",
          internalCode: CatalogInternalCodeEnum.MailAddressFormatError,
          isSuccess: false,
          message: this.CatalogMessages.generateMessage(
            CatalogInternalCodeEnum.MailAddressFormatError
          ),
        });
      }
    }
    return validationResultList;
  }

  /**
   * メールアドレスのフォーマットチェックを行います
   *
   * @param email
   * @returns
   */
  private static mailAddressFormatCheck(email: string): boolean {
    // メールアドレスチェック
    const pattern =
      /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    if (email && !pattern.test(email)) {
      return false;
    }
    return true;
  }
}
