<template>
  <ReserveCompleteTemplate />
</template>
<script lang="ts">
// templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用
import { defineComponent } from "vue";
import ReserveCompleteTemplate from "@/components/templates/reserve/ReserveCompleteTemplate.vue";
import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  name: "ReserveComplete",
  components: { ReserveCompleteTemplate },
  methods: {
    ...mapMutations(["setLoadingStatus", "setIsReserveCompleted"]),
    ...mapGetters(["getIsReserveCompleted"]),

    goToErrorPage() {
      this.$router.push("/:pathMatch(.*)*");
    },
  },
  computed: {
    ...mapGetters(["getLoadingStatus"]),
  },
  mounted() {
    this.setLoadingStatus(true);
    setTimeout(() => {
      this.setLoadingStatus(false);
    }, 1000);

    if (!this.getIsReserveCompleted()) {
      this.goToErrorPage();
    }
    this.setIsReserveCompleted(false);
  },
});
</script>
<style scoped></style>
