<template>
  <!-- 資料請求画面「保険選びが～」～「守られております」 -->
  <div class="l-base">
    <div class="Block-head">
      <p class="Block-head__lead">
        ご入力いただいた内容をご確認のうえ「この内容で資料請求する」ボタンを押してください。
      </p>
      <h2 class="Block-ttl">送付先・お客さま情報</h2>
      <!--error-block-->
      <!--/error-block-->
      <p class="Block-head__txt">
        <span class="Ico-ssl"
          >お客さまの入力情報は、暗号化通信により守られております。</span
        >
      </p>
    </div>
  </div>
</template>
<script lang="ts">
// templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用
import { defineComponent } from "vue";
export default defineComponent({
  name: "CatalogIntroductionConfirmOrganism",
});
</script>
<style scoped>
*,
::after,
::before {
  box-sizing: border-box;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd {
  margin: 0;
  font-size: 100%;
  font-weight: normal;
}
h2 {
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.Ico-ssl::before {
  display: inline-block;
  width: 17px;
  height: 19px;
  margin-right: 10px;
  background: url(../../../assets/icon_ssl.gif) no-repeat 0 0;
  vertical-align: text-top;
  content: "";
}
@media screen and (min-width: 1140px) {
  .l-base {
    width: 1040px;
    margin: 0 auto;
  }
  .Page-ttl,
  .Block-ttl {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
  }
  .Block-head__lead {
    margin-bottom: 50px;
  }
  .Block-ttl {
    padding-bottom: 32px;
    border-bottom: 1px solid #3b5e10;
    color: #3b5e10;
    font-size: 3.4rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.1;
    letter-spacing: normal;
  }

  .Block-head {
    margin: 32px 100px;
  }
  .Block-head__txt {
    margin-top: 32px;
    text-align: center;
  }
}
@media screen and (max-width: 1139px) {
  .l-base {
    width: 1040px;
    margin: 0 auto;
  }
  .Page-ttl,
  .Block-ttl {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
  }
  .Block-head__lead {
    margin-bottom: 50px;
  }
  .Block-ttl {
    padding-bottom: 32px;
    border-bottom: 1px solid #3b5e10;
    color: #3b5e10;
    font-size: 3.4rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.1;
    letter-spacing: normal;
  }
  .Block-head {
    margin: 32px 100px;
  }
  .Block-head__txt {
    margin: 20px 10px;
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  .l-base {
    width: 95%;
    margin: 0 auto;
  }
  .Block-head__lead {
    margin-bottom: 20px;
  }

  .Block-head {
    margin: 20px 0 20px 0;
  }
  .Page-ttl,
  .Block-ttl {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
      "Hiragino Sans", Meiryo, sans-serif;
  }
  .Block-ttl {
    padding-bottom: 20px;
    border-bottom: 1px solid #3b5e10;
    color: #3b5e10;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0;
    text-align: left;
  }
  .Block-head__txt {
    margin: 20px 10px;
    text-align: left;
  }
  .Ico-ssl {
    display: block;
    text-indent: -27px;
    padding-left: 27px;
  }
}
</style>
