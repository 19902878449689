export class ContractAgeCalculator {
  /**
   * 契約年齢の計算を行います。
   *
   * @param birthYear 誕生年
   * @param birthMonth 誕生月
   * @param birthDay 誕生日
   * @param contractDate 契約基準日
   * @returns 契約年齢
   */
  public static calculationContactAge(
    birthYear: number,
    birthMonth: number,
    birthDay: number,
    contractDate: Date
  ): number {
    // 誕生日を取得
    const birthDate = new Date(birthYear, birthMonth - 1, birthDay);

    // 年齢を追加 (契約年 - 誕生年)の計算式の為、誕生日を迎えた年齢が設定される
    const contractAge = contractDate.getFullYear() - birthDate.getFullYear();
    // 今年誕生日を迎えているかを判定する為の基準日を作成する
    const birthReferenceDate: Date = new Date(
      birthYear + contractAge,
      birthMonth - 1,
      birthDay
    );

    // 今年誕生日を迎えているかをチェックをする
    // 誕生日が契約基準日を過ぎていない場合、-1して返却する
    return contractDate < birthReferenceDate ? contractAge - 1 : contractAge;
  }
}
